import { useNavigate } from "react-router-dom";
import { useState } from "react";
import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { CacheKeys, postFilterOptions } from "../../core/constants/filter";
import CreateButton from "../../components/common/buttons/CreateButton";
import { useFetchPosts } from "../../core/services/react-query/post";
import PostComp from "../../components/main/post/PostComp";
import { Flex, IconWrapper } from "../../styles/components/common/common-styles";
import Tooltip from "../../components/common/utils/Tooltip";
import { InfoIcon } from "../../styles/components/dashboard";
import { useMainContext } from "../../core/contexts/main";
import PostStatusMap from "../../components/main/post/PostStatusMap";
import { routes } from "../../core/constants/routes";
import { statusMap } from "../../components/main/post/PostList";


const Posts = () => {

    const { main: { lang } } = useMainContext()

    const navigate = useNavigate()
    const navigateToPostCreation = () => {
        navigate(routes.createPost)
    }

    const [posts, setPosts] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setPosts(res)

    return (
        <>
            <CardLayout>
                <>
                    <Flex fw justify={'flex-end'} style={{ padding: '0 20px' }}>
                        <Tooltip
                            placement={lang === 'en' ? 'left' : 'right'}
                            content={<PostStatusMap statusMap={statusMap} />}
                        >
                            <IconWrapper>
                                <InfoIcon size={18} />
                            </IconWrapper>
                        </Tooltip>
                    </Flex>
                    <FilterLayout
                        query={useFetchPosts}
                        cache={CacheKeys.POST}
                        onQuerySuccess={onQuerySuccess}
                        options={postFilterOptions}
                    >
                        <PostComp data={posts} />
                    </FilterLayout>
                </>
            </CardLayout>
            <CreateButton
                onClick={navigateToPostCreation}
                tooltip='create-post'
            />
        </>
    )
}


export default Posts
