import TableLayout from "../../../layouts/main/TableLayout"
import MembersList from "./MembersList"



const MembersComp = ({ data }) => {

    const { data: members } = data

    return (
        <TableLayout
            data={data}
            headers={headers}
            cs={CS}
        >
            <MembersList
                members={members}
                cs={CS}
            />
        </TableLayout>
    )
}

const CS = '5% 15% 10% 10% 25% 12% 13% 10%'
const headers = [
    'member-username', 'mobile', 'member-name', 'member-categories', 'peer_id', 'access_hash', 'date'
]

export default MembersComp