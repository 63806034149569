import Text from "../../../core/utils/Text";
import styled from "styled-components";
import { ReactComponent as NoDataImage } from "../../../assets/illustrations/no-data.svg";
import {CFlex, CMargin, DText} from "../../../styles/components/common/common-styles";


const NoData = ({ desc }) => {

    return (
        <Wrapper fw fh>
            <NoDataImage
                width={'240px'}
                height={'240px'}
                style={{ opacity: '0.7' }}
            />
            <CMargin margin={'8px'} />
            <DText main>
                <Text tid={desc ? desc : 'no-data-found'} />
            </DText>
        </Wrapper>
    )
}

const Wrapper = styled(CFlex)`
  border-top: 1px solid ${props => props.theme.color}15;
  padding: 30px 0 20px 0;
`

export default NoData
