import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {ChatOption, MultiSelectOptionsWrapper} from "../../../styles/components/common/select-styles";


const SimSelectItems = ({
    options,
    selected,
    onOptionClicked
}) => {


    return (
        <MultiSelectOptionsWrapper>
            {options.map((sim, idx) => (
                <ChatOption
                    active={selected?.includes(sim.username)}
                    onClick={() => onOptionClicked(sim)}
                    type={'sim'}
                >
                    <CFlex fh align={'flex-start'} style={{ backgroundColor: 'transparent' }}>
                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'username'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {sim.username}
                            </DText>
                        </Flex>

                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'phone'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {sim.phone}
                            </DText>
                        </Flex>
                    </CFlex>

                    <CFlex fh align={'flex-end'} style={{ backgroundColor: 'transparent' }}>
                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'groups'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {sim.groups?.length}
                            </DText>
                        </Flex>

                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'channels'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {sim.channels?.length}
                            </DText>
                        </Flex>
                    </CFlex>
                </ChatOption>
            ))}
        </MultiSelectOptionsWrapper>
    )
}

export default SimSelectItems
