import { useEffect, useState } from "react";
import { Background, CFlex, CMargin, DText, Flex, LineBreak } from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import { FiAlertTriangle } from "react-icons/fi";
import Select from "../common/select/Select";
import Input from "../common/input/Input";
import { deepCopy } from "../../core/utils/common";
import ActionButton from "../common/buttons/ActionButton";
import LocalTimePicker from "../common/date/TimePicker";


const UpdateDelayModal = ({
    onClose,
    onSubmit,
    data
}) => {

    const delayTypes = ['period', 'slow-mode']
    const [delay, setDelay] = useState(data)
    const [valid, setValid] = useState(true)

    const onInputValueChange = (v, type) => {
        const newDelay = deepCopy(delay)
        newDelay[type] = v
        setDelay(newDelay)
    }

    useEffect(() => {
        let valid = false
        if (delay.mode === 'period') {
            valid = !!parseInt(delay.begin) && !!parseInt(delay.end) && parseInt(delay.end) >= parseInt(delay.begin)
        }
        if (delay.mode === 'slow-mode')
            valid = !!parseInt(delay.delay) &&
                !!parseInt(delay.begin) &&
                !!parseInt(delay.end) &&
                !!delay.min &&
                !!delay.max
        setValid(valid)
    }, [delay])

    return (
        <CFlex fw>
            <Flex fw justify='space-between'>
                <DText primary fontSize='sb'>
                    <Text tid={'update-delay'} />
                </DText>
                <FiAlertTriangle color='#ffc800' size={32} />
            </Flex>
            <CMargin margin='4px' />
            <LineBreak />
            <CMargin margin={'4px'} />
            <Select
                options={delayTypes}
                onValueChange={(idx) => onInputValueChange(delayTypes[idx], 'mode')}
                value={delay.mode}
                width={'100%'}
                noLabel
            />
            <CMargin margin={'12px'} />
            <Flex fw justify={'space-between'}>
                <Background bg={'mainBg'}>
                    <Input
                        value={delay.begin}
                        onInputChange={(v) => onInputValueChange(v, 'begin')}
                        label={'min'}
                        width={delay.mode === 'slow-mode' ? '30%' : '48%'}
                        size={'small'}
                        code
                    />
                    <Input
                        value={delay.end}
                        onInputChange={(v) => onInputValueChange(v, 'end')}
                        label={'max'}
                        width={delay.mode === 'slow-mode' ? '30%' : '48%'}
                        size={'small'}
                        code
                    />
                    {delay.mode === 'slow-mode' &&
                        <Input
                            value={delay.delay}
                            onInputChange={(v) => onInputValueChange(v, 'delay')}
                            label={'delay-time'}
                            width={'30%'}
                            size={'small'}
                            code
                        />
                    }
                </Background>
            </Flex>
            {delay.mode === 'slow-mode' &&
                <>
                    <Flex fw justify={'space-between'}>
                        <Background bg={'mainBg'}>
                            <CFlex align={'flex-start'}>
                                <DText main margin={'0 0 10px 0'}>
                                    <Text tid={'min-day'} /> :
                                </DText>
                                <LocalTimePicker
                                    time={delay.min}
                                    onChange={(v) => onInputValueChange(v, 'min')}
                                />
                            </CFlex>

                            <CFlex align={'flex-start'}>
                                <DText main margin={'0 0 10px 0'}>
                                    <Text tid={'max-day'} /> :
                                </DText>
                                <LocalTimePicker
                                    time={delay.max}
                                    onChange={(v) => onInputValueChange(v, 'max')}
                                />
                            </CFlex>
                        </Background>
                    </Flex>
                </>
            }
            <CMargin margin={'20px'} />
            <ActionButton
                active={valid}
                onClick={() => {
                    onSubmit(delay)
                    onClose()
                }}
            >
                <Text tid={'submit'} />
            </ActionButton>
        </CFlex>
    )
}


export default UpdateDelayModal
