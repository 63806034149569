import {useQueryContext} from "../../../core/contexts/query";
import {useFilterContext} from "../../../core/contexts/filter";
import {useEffect, useState} from "react";
import {CFlex, CMargin, Flex} from "../../../styles/components/common/common-styles";
import AddFilter from "./AddFilter";
import {DeleteAllFilters, FiltersOn, SearchBtn} from "../../../styles/layouts/filter";
import Text from "../../../core/utils/Text";
import DownloadBtn from "../../common/buttons/DownloadBtn";
import FilterItem from "./FilterItem";
import Tooltip from "../../common/utils/Tooltip";


const FilterView = (props) => {

    const {
        options,
        query,
        onQuerySuccess,
        cache,
        extra = {},
        hasDownload
    } = props

    const {setToast} = useQueryContext()
    const {
        filters: _filters,
        clearAllFilters,
        pagination: _pagination,
        setPagination,
        filtersInAction,
        setFiltersInAction
    } = useFilterContext()

    const [csv, setCsv] = useState(false)

    const filters = _filters[cache]
    const filterList = Object.keys(filters)
    const pagination = _pagination[cache]

    useEffect(() => {
        if (Object.keys(filters).length) {
            setFiltersInAction(true)
        }
    }, [])

    const { data, refetch, isFetching: loading, isSuccess, isError } = query(filters, pagination, extra, csv)


    useEffect(() => {
        const keys = Object.keys(filters)
        if (!keys.length && filtersInAction) {
            const newState = {...pagination}
            newState.page = 1
            setPagination(newState)
            setFiltersInAction(false)
        }
    }, [filters])

    useEffect(() => {
        if (csv) {
            refetch()
        }
    }, [csv])

    useEffect(() => {
        if ((isSuccess || isError) && csv) {
            setToast({
                isError: isError,
                show: true,
                message: isError ? 'add-download-fail' : 'add-download-success'
            })
            setCsv(false)
        }
    }, [isSuccess, isError, csv])

    useEffect(() => {
        if (!csv && !data?.data?.link) {
            onQuerySuccess({
                data: data, loading
            })
        }
    }, [data, loading])

    useEffect(() => {
        refetch()
    }, [pagination])

    const onSearchClicked = () => {
        setFiltersInAction(true)
        refetch()
    }

    const onDownloadCsvClicked = () => {
        setCsv(true)
    }

    return (
        <CFlex fw wrap align='flex-start'>
            <Flex fw justify={'space-between'}>
                <Flex>
                    <AddFilter
                        options={options}
                    />
                    {filterList?.length ?
                        <DeleteAllFilters
                            onClick={clearAllFilters}
                        >
                            <Text tid={'delete-all-filters'} />
                        </DeleteAllFilters>
                        : null}
                </Flex>
                {hasDownload &&
                    <DownloadBtn
                        loading={loading && csv}
                        onClick={onDownloadCsvClicked}
                    />
                }

            </Flex>

            <CMargin margin='5px' />
            <Flex fw wrap justify={'flex-start'}>
                {filterList?.map(key => {

                    const value = filters[key]
                    return (
                        <FilterItem
                            key={key}
                            options={options}
                            prefix={key}
                            suffix={value}
                        />
                    )
                })}
                {filterList?.length ?
                    <SearchBtn
                        onClick={onSearchClicked}
                    >
                        <Text tid={'search'} />
                    </SearchBtn>
                    :null}
            </Flex>
            {filtersInAction &&
                <Tooltip
                    content={'filters-on'}
                >
                    <FiltersOn />
                </Tooltip>
            }
        </CFlex>
    )
}

export default FilterView
