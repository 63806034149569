import { useEffect, useMemo, useState } from "react";
import Text from "../../../core/utils/Text";
import { useFreeSimCard } from "../../../core/services/react-query/sim";
import { ClipLoader } from "react-spinners";

const BanTimer = ({ date, id }) => {
  const { mutate: freeSimCard, isLoading } = useFreeSimCard();

  const onFreeSim = () => {
    if (isLoading) return;
    freeSimCard({ sim_id: id });
  };

  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval;
    if (date) {
      const now = new Date().getTime();
      const dt = new Date(date).getTime();
      const diff = parseInt((dt - now) / 1000);
      if (diff <= 0) return;
      setTimer(diff);

      interval = setInterval(() => {
        setTimer((state) => {
          if (state < 0) {
            clearInterval(interval);
            return 0;
          }
          return state - 1;
        });
      }, 1000);
    }

    return () => interval && clearInterval(interval);
  }, [date]);

  const onBoard = useMemo(() => {
    if (timer >= 0) {
      let mins = parseInt(timer / 60);
      if (mins < 10) mins = `0${mins}`;
      let secs = timer % 60;
      if (secs < 10) secs = `0${secs}`;
      return `${mins}:${secs}`;
    }
  }, [timer]);

  return (
    <div>
      {timer <= 0 ? (
        <div
          className={`rounded p-2 bg-opacity-80 hover:bg-opacity-100 
            bg-blue-500 flex items-center justify-center cursor-pointer
            w-[120px]
            `}
          onClick={onFreeSim}
        >
          {isLoading ? (
            <ClipLoader size={18} />
          ) : (
            <Text tid={"free-sim-card"} />
          )}
        </div>
      ) : (
        <span>{onBoard}</span>
      )}
    </div>
  );
};

export default BanTimer;
