import {Flex} from "../../../styles/components/common/common-styles";
import styled, {css} from "styled-components";
import {IoIosAdd} from "react-icons/io";
import {useRef} from "react";
import Text from "../../../core/utils/Text";



const CreateButton = (props) => {

    const {tooltip} = props
    const ref = useRef()


    return (
        <>
            <TCreateButton {...props} ref={ref}>
                <Flex fw fh>
                    <AddIcon size={18} />
                    <Text tid={tooltip} />
                </Flex>
            </TCreateButton>
        </>
    )
}


const TCreateButton = styled.div`
  outline: 1px solid #4a9fbc;
  border-radius: 4px;
  height: 30px;
  padding: 0 8px;
  color: ${props => props.theme.primary};
  font-size: 0.8rem;
  background-color: ${props => props.theme.active};
  position: absolute;
  top: 10px;
  transition: all 0.3s;
  cursor: pointer;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.1);

  ${props => props.theme.english && css`
    right: 10px;
  `};

  ${props => !props.theme.english && css`
    left: 10px;
  `};

  &:hover {
    background-color: ${props => props.theme.active};
    color: whitesmoke;
  }
`

const AddIcon = styled(IoIosAdd)`
  color: whitesmoke;
`

export default CreateButton
