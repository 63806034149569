import TableLayout from "../../layouts/main/TableLayout";
import NormalizerList from "./NormalizerList";


const NormalizerComp = ({ data, sender }) => {

    const { data: normalizers } = data

    return (
        <TableLayout
            data={data}
            headers={sender ? senderAttributes.headers : normAttributes.headers}
            cs={sender ? senderAttributes.cs : normAttributes.cs}
        >
            <NormalizerList
                normalizers={normalizers}
                cs={sender ? senderAttributes.cs : normAttributes.cs}
                sender={sender}
            />
        </TableLayout>
    )
}

const senderAttributes = {
    cs: '10% 15% 15% 20% 15% 15% 10%',
    headers: [
        'name', 'sim', 'messages_sent', 'date', 'status'
    ]
}

const normAttributes = {
    cs: '10% 20% 20% 20% 20% 10%',
    headers: [
        'name', 'sim', 'date', 'status'
    ]
}

export default NormalizerComp
