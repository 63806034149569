import TableLayout from "../../layouts/main/TableLayout";
import PostList from "./PostList";


const PostComp = ({
    data
}) => {

    const { data: posts } = data

    return (
        <TableLayout
            headers={headers}
            cs={CS}
            data={data}
        >
            <PostList
                posts={posts}
                cs={CS}
            />
        </TableLayout>
    )
}


const CS = '5% 10% 15% 25% 15% 10% 10% 10%'
const headers = [
    'message_id',
    'sim',
    'views',
    'messages_sent',
    'date',
    'status'
]

export default PostComp
