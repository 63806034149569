import DatePicker from "react-multi-date-picker"
import { useMainContext } from "../../../core/contexts/main"
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useState } from "react";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import { DText } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import { Tag } from "../../../styles/components/normalizer";
import styled from "styled-components";
import ActionButton from "../../common/buttons/ActionButton";
import ButtonLoader from "../../common/loading/ButtonLoader";
import { useUpdatePost } from "../../../core/services/react-query/post";

const UpdatePostModal = ({ post, onClose }) => {

    const { main: { lang, theme } } = useMainContext()
    const [date, setDate] = useState('')

    const { mutate, isLoading } = useUpdatePost(onClose)

    const onSubmit = () => {
        if (!date) return
        mutate({
            post_id: post._id, date
        })
    }

    return (
        <div className={"flex flex-col gap-5"}>
            <DText main>
                <Text tid={'post-change-date'} />
            </DText>
            <DatePicker
                calendar={lang === 'fa' && persian}
                locale={lang === 'fa' && persian_fa}
                value={date}
                onChange={setDate}
                plugins={[<TimePicker />]}
                render={<DateInput dark={false} label={'dates'} />}
                className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal rmdp-mobile`}
                calendarPosition={'top-center'}
                multiple={false}
            />
            <ActionButton active={!!date} onClick={onSubmit}>
                <ButtonLoader tid={'submit'} loading={isLoading} />
            </ActionButton>
        </div>
    )
}

const DateInput = ({ value, openCalendar, handleValueChange, label, dark = true }) => {
    return (
        <div onClick={openCalendar}>
            <Wrapper>
                {value ?
                    <Tag style={{ fontFamily: '0.8rem' }} dark={dark}>
                        {value}
                    </Tag>
                    :
                    <DText main>
                        <Text tid={'select-date'} />
                    </DText>
                }
            </Wrapper>
        </div>
    )
}


const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color}40;
  padding: 10px;
  border-radius: 4px;
  min-height: 48px;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export default UpdatePostModal