import {ChatOption, MultiSelectOptionsWrapper} from "../../../styles/components/common/select-styles";
import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";


const UserSelectItems = ({
    options,
    selected,
    onOptionClicked
}) => {

    return (
        <MultiSelectOptionsWrapper>
            {options.map((user, idx) => (
                <ChatOption
                    active={selected.includes(user.email)}
                    onClick={() => onOptionClicked(user)}
                    type={'category'}
                >
                    <CFlex fh align={'flex-start'} style={{ backgroundColor: 'transparent' }}>
                        <Flex>
                            <DText main>
                                <Text tid={'email'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {user.email}
                            </DText>
                        </Flex>
                    </CFlex>

                    <CFlex fh align={'flex-end'} style={{ backgroundColor: 'transparent' }}>
                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'username'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {user.username}
                            </DText>
                        </Flex>
                    </CFlex>
                </ChatOption>
            ))}
        </MultiSelectOptionsWrapper>
    )
}


export default UserSelectItems
