import OperationList from "./OperationList";
import TableLayout from "../../layouts/main/TableLayout";


const OperationComp = ({ data }) => {

    const { data: operations } = data

    return (
        <TableLayout
            headers={headers}
            cs={CS}
            data={data}
        >
            <OperationList
                operations={operations}
                cs={CS}
            />
        </TableLayout>
    )
}

const CS = '10% 20% 20% 20% 15% 15%'
const headers = [
    'name', 'sim', 'date', 'status'
]

export default OperationComp
