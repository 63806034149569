import { AnimatePresence, motion } from 'framer-motion'
import React, { useRef } from 'react'
import styled from 'styled-components'
import useClickOutside from '../../../core/hooks/common/useClickOutside'
import { fadeVariants } from "../../../core/utils/theme";
import ReactPortal from "./ReactPortal";
import { useMainContext } from "../../../core/contexts/main";


const ModalLayout = ({ open, portal = true, children, ...rest }) => {



    if (!open) return <></>

    if (portal) return (
        <ReactPortal>
            <ModalBody open={open} children={children} {...rest} />
        </ReactPortal>
    )

    return (
        <ModalBody open={open} children={children} {...rest} />
    )
}

const ModalBody = ({ onClose, open, width, children, isStatic = false }) => {

    const modalRef = useRef()
    useClickOutside(modalRef, () => {
        if (!isStatic) onClose()
    })
    const { main: { theme } } = useMainContext()

    return (
        <Body _theme={theme}>
            <AnimatePresence exitBeforeEnter>
                {open && (
                    <Wrapper
                        ref={modalRef}
                        variants={fadeVariants}
                        initial='out'
                        animate='in'
                        exit='out'
                        width={width}
                    >
                        {children}
                    </Wrapper>
                )}
            </AnimatePresence>
        </Body>
    )
}

const Body = styled.div`
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	width: 100%;
    height: 100%;
	background-color: ${props => props._theme === 'dark' ? '#00000080' : '#435a6fb3'};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99999999;
    direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
`

const Wrapper = styled(motion.div)`
	width: 100%;
    max-height: 100vh;
    overflow-y: auto;
	max-width: ${(props) => props.width};
	padding: 35px 20px;
	background-color: ${(props) => props.theme.mainBg};
	border-radius: 8px;
	box-shadow: 0 0 4px ${(props) => props.theme.shadow};

	@media screen and (max-width: 480px) {
		padding: 30px 20px;
		width: 90%;
	}
`

export default ModalLayout
