import SimList from "./SimList";
import TableLayout from "../../layouts/main/TableLayout";

const SimComp = ({ data }) => {
  const { data: sims } = data;

  return (
    <>
      <TableLayout headers={headers} cs={CS} data={data}>
        <SimList sims={sims} cs={CS} />
      </TableLayout>
    </>
  );
};

const CS = "5% 10% 12% 12% 12% 12% 17% 10% 10%";
const headers = [
  "name",
  "phone",
  "date",
  "publicGroups",
  "privateGroups",
  "status",
  "ready",
];

export default SimComp;
