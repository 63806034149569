import DetailBox from "../../../common/utils/DetailBox";
import {DText, Flex} from "../../../../styles/components/common/common-styles";
import Text from "../../../../core/utils/Text";
import DetailContent from "../../details/DetailContent";
import MessageContent from "./MessageContent";


const NormalizerDetailShow = ({
    sim,
    normalizer
}) => {


    return (
        <>
            <DetailBox>
                <Flex fw justify={'space-between'}>
                    <Flex>
                        <DText main>
                            <Text tid={'normalizer-name'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {normalizer?.name}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'sim'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {sim?.username}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'status'} /> :
                        </DText>
                        <DText color={normalizer?.active ? '#1ce087' : '#e9106c'} style={{ margin: '0 5px' }}>
                            <Text tid={normalizer?.active ? 'active' : 'disabled'} />
                        </DText>
                    </Flex>
                </Flex>
            </DetailBox>
            <Flex fw justify={'space-between'} align={'stretch'}>
                <DetailBox
                    width={'49%'}
                    title={'groups'}
                >
                    <DetailContent
                        data={normalizer?.groups}
                        photo={'address'}
                        title={'title'}
                        type={'groups'}
                    />
                </DetailBox>
                <DetailBox
                    width={'49%'}
                    title={'categories'}
                >
                    <DetailContent
                        data={normalizer?.categories}
                        title={'name'}
                        type={'categories'}
                        noLink
                    />
                </DetailBox>
            </Flex>
            <DetailBox
                title={'messages'}
            >
                <MessageContent
                    messages={normalizer?.messages}
                />
            </DetailBox>
        </>
    )
}


export default NormalizerDetailShow
