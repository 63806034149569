import { useMemo } from "react"
import styled from "styled-components"



const PcViewer = ({ total, filled }) => {

    const width = useMemo(() => {
        let ans = 0
        try {
            ans = Math.min(Math.max(filled / total * 100, 5), 100)
        } catch (_) { }
        return ans
    }, [filled, total])

    return (
        <div dir={'ltr'} className={"w-[80%] h-[16px] rounded-md dark:bg-gray-500 bg-gray-300 relative flex items-center justify-center"}>
            <PC
                width={width}
                className={"rounded-md z-[1]"}
            />
            <span className={'text-black text-sm font-bold relative z-[2] mt-1'}>
                <span>{filled}</span>
                <span> / </span>
                <span>{total}</span>
            </span>
        </div>
    )
}


const PC = styled.div`
    width: ${props => props.width}%;
    height: 16px;
    background-color: ${props => props.theme.mainGreen};
    position: absolute;
    left: 0;
    top: 0;
`


export default PcViewer