import { useEffect, useState } from "react";
import { CFlex, CloseIcon, DeleteIcon, DText, Flex } from "../../styles/components/common/common-styles";
import EChart from "./EChart";
import { useGetSubscriber } from "../../core/services/react-query/dashboard";
import { useMainContext } from "../../core/contexts/main";
import Text from "../../core/utils/Text";
import DateFilter from "../common/date/DateFilter";
import SingleSelect from "../common/select/SingleSelect";
import { useAdminChannels, useSimChannelsQuery } from "../../core/services/react-query/channel";
import { DeleteFilters } from "../../styles/components/dashboard";
import Tooltip from "../common/utils/Tooltip";
import { useGetAllSims } from "../../core/services/react-query/sim";
import { normalFetch } from "../../core/services/fetch-api/get";


const Subscriber = () => {

    const { main: { theme } } = useMainContext()

    const [channels, setChannels] = useState([])
    const { data: sims } = useGetAllSims()

    useEffect(() => {
        async function fn() {
            if (sims.length) {
                for (const sim of sims) {
                    if (sim.type === 'alpha') continue
                    const resp = await normalFetch(`/channel/admin/${sim._id}`)
                    if (resp?.data?.success) {
                        setChannels(state => ([...state, ...resp.data.data]))
                    }
                }
            }
        }
        fn()
    }, [sims])

    /**
     * chart states
     */
    const [dates, setDates] = useState([])
    const [bars, setBars] = useState([])

    /**
     * params
     */
    const [mode, setMode] = useState('monthly')
    const [calendarType, setCalendarType] = useState('shamsi')
    const [filterDate, setFilterDate] = useState(new Date().setUTCHours(20, 30, 0))
    const [channel, setChannel] = useState(null)

    const [params, setParams] = useState({})

    useEffect(() => {
        const newParams = {
            mode, date: new Date(filterDate),
            tz: calendarType === 'shamsi' ? 'Asia/Tehran' : 'UTC'
        }
        if (channel) newParams.source = channel._id
        else newParams.source = 'total'
        setParams(newParams)
    }, [mode, calendarType, filterDate, channel])

    const { data: subscriber } = useGetSubscriber(params)

    const onParamsChange = (v, type) => {
        switch (type) {
            case 'mode':
                setMode(v)
                break
            case 'date':
                const date = new Date(v)
                date.setUTCHours(24, 0, 0, 0)
                setFilterDate(date)
                break
            case 'calendarType':
                setCalendarType(v)
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (subscriber) {
            // setting dates
            const formattedDates = subscriber.dates.map(d => d)
            setDates(formattedDates)

            //setting bars
            const formattedBars = []
            subscriber.bars.forEach((item, idx) => {
                const today = new Date()
                // TODO: find today
                // const faToday = new Date(p2e(today.toLocaleDateString('fa-IR')))

                today.setUTCHours(0, 0, 0, 0)
                if (new Date(subscriber.dates[idx]).getTime() === today.getTime()) {
                    formattedBars.push({
                        value: item,
                        itemStyle: {
                            color: '#15A865'
                        }
                    })
                } else
                    formattedBars.push(item)
            })
            setBars(subscriber.bars)
        }
    }, [subscriber])

    const option = {
        xAxis: {
            data: dates
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '20%'],
            splitLine: {
                lineStyle: {
                    color: theme === 'dark' ? '#c3c5b720' : '#00000020',
                    type: 'dashed'
                }
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        dataZoom: [
            {
                type: 'inside',
                start: mode === 'yearly' ? 75 : 0,
                end: 100
            },
            {
                show: true,
                type: 'slider',
                top: '90%',
                start: mode === 'yearly' ? 75 : 0,
                end: 100
            }
        ],
        series: [
            {
                data: bars,
                type: 'line',
                areaStyle: {},
                showSymbol: false,
                color: [
                    '#3B6978'
                ]
            }
        ]
    };

    const deleteAllFilters = () => setChannel(null)

    return (
        <CFlex fw fh justify={'space-between'}>
            <Flex fw>
                <DText primary cFontSize={'15px'} style={{ fontWeight: 600 }}>
                    <Text tid={'subscriber-count'} />
                </DText>
                {(!!channel) &&
                    <DeleteFilters onClick={deleteAllFilters}>
                        <Tooltip
                            content={'delete-all-filters'}
                        >
                            <div>
                                <CloseIcon size={18} />
                            </div>
                        </Tooltip>
                    </DeleteFilters>
                }
            </Flex>
            <Flex fw justify={'space-between'}>
                <Flex width={'50%'}>
                    <DateFilter
                        mode={mode}
                        date={filterDate}
                        calendarType={calendarType}
                        onParamsChange={onParamsChange}
                        noLabel
                    />
                </Flex>
                <Flex width={'50%'} justify={'flex-end'}>
                    <SingleSelect
                        value={channel}
                        onValueChange={(g) => setChannel(g)}
                        placeHolder='filter-by-group'
                        options={channels || []}
                        type={'chat'}
                        param='address'
                        style={{ margin: '0 6px' }}
                        noLabel
                    />
                </Flex>
            </Flex>
            <Flex fw height={'calc(100% - 80px)'}>
                <EChart option={option} style={{ width: '100%', height: '100%' }} />
            </Flex>
        </CFlex>
    )
}

export default Subscriber
