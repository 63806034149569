import {useSocketContent} from "../../../core/contexts/socket-content";
import {DText, Flex, LineBreak} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {formatNumber} from "../../../core/utils/common";


const C2cStats = () => {

    const { c2cStats } = useSocketContent()

    return (
        <div style={{ margin: '60px 40px 0 40px' }}>
            {c2cStats &&
                Object.keys(c2cStats).map(key => {
                    const value = c2cStats[key]
                    return (
                        <>
                            <Flex width={'320px'} justify={'space-between'} margin={'8px 0'}>
                                <DText main>
                                    <Text tid={`c2c-stats-${key}`} />
                                </DText>
                                <DText primary>
                                    {formatNumber(value, true)}
                                </DText>
                            </Flex>
                            <LineBreak style={{ width: '340px' }} />
                        </>
                    )
                })
            }
        </div>
    )
}


export default C2cStats
