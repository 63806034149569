import Text from "../../core/utils/Text";
import {CgArrowLeft} from "react-icons/cg";


const Section6 = () => {

    return (
        <div className={'w-full bg-primeBg p-10 text-slate-500'}>
            <div className={'flex justify-between items-center max-w-[1144px] mx-auto gap-10'}>
                <Text tid={'text-demo-now'} className={'text-4xl font-semibold'}/>
                <div className={'flex justify-center gap-3 text-gray-900 cursor-pointer items-center py-2 px-12 rounded-md bg-mainBg w-max'}>
                    <Text tid={'start-now'} className={'text-xl'} />
                    <CgArrowLeft size={32} />
                </div>
            </div>
        </div>
    )
}

export default Section6
