import styled, {css} from "styled-components";
import { IoMoon } from 'react-icons/io5'
import { IoIosSunny } from 'react-icons/io'
import {useMainContext} from "../../../core/contexts/main";


const Toggle = (props) => {

    const { themeSwitcher, active, onChange } = props
    const { main: { theme, setTheme } } = useMainContext()

    const onClick = () => {
        if (themeSwitcher) setTheme(theme === "dark" ? "light" : "dark")
        else onChange && onChange()
    }

    return (
        <Slider onClick={onClick} active={active} {...props}>
            <IconWrapper active={themeSwitcher ? theme === 'dark' : active} themeSwitcher={themeSwitcher}>
                { themeSwitcher && (
                    theme === 'dark' ?
                        <IoMoon size={14} color={'#ffc800'} />
                        :
                        <IoIosSunny size={14} color={'#ffc800'} />
                )}
            </IconWrapper>
        </Slider>
    )
}

const Slider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${props => props.themeSwitcher ? '38px' : '30px'};
  height: ${props => props.themeSwitcher ? '20px' : '18px'};
  background-color: ${props => props.theme.color}80;
  ${props => props.active && css`
    background-color: ${props => props.theme.mainOrange};
  `};
  border-radius: 12px;
  cursor: pointer;
`

const IconWrapper = styled.div`
  position: absolute;
  transition: all 0.3s;
  top: ${props => props.themeSwitcher ? '1.5px' : '2px'};
  ${props => props.active && css`
    right: ${props => props.themeSwitcher ? '20px' : '14px'};
  `};
  ${props => !props.active && css`
    right: ${props => props.themeSwitcher ? '2px' : '2px'};
  `};
  width: ${props => props.themeSwitcher ? '16px' : '14px'};
  height: ${props => props.themeSwitcher ? '16px' : '14px'};
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`


export default Toggle
