import Text from "../../../core/utils/Text";
import styled from "styled-components";
import {BarLoader} from "react-spinners";
import {DText, Flex} from "../../../styles/components/common/common-styles";
import {DownloadIcon} from "../../../styles/layouts/filter";


const DownloadBtn = (props) => {

    return (
        <Wrapper
            {...props}
        >
            <Flex fw fh>
                {props.loading ?
                    <BarLoader
                        color={'#ffc800'}
                        width={'52px'}
                    />
                    :
                    <>
                        <DownloadIcon size={20}/>
                        <DText
                            style={{ margin: '0 5px'}}
                            fontSize={'ss'}
                            main
                        >
                            <Text tid={'add-to-download-list'} />
                        </DText>
                    </>
                }
            </Flex>
        </Wrapper>
    )
}

const Wrapper = styled.div`
  height: 38px;
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
  width: 152px;
  padding: 0 12px;
  background-color: ${props => props.theme.secondaryBg};
  border: 1px solid ${props => props.theme.color}10;
`

export default DownloadBtn
