import {useState} from "react";
import {useLocation} from "react-router-dom";


const useDetailsLayout = (resetData) => {

    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const type = search.get('type')

    const [mode, setMode] = useState(type ? type : 'show')
    const enterEditMode = () => {
        setMode('edit')
    }
    const exitEditMode = () => {
        setMode('show')
        resetData()
    }

    return {
        mode,
        enterEditMode,
        exitEditMode
    }
}


export default useDetailsLayout
