import {DashboardBox, ScrollBox} from "../../../styles/components/dashboard";
import {useSocketContent} from "../../../core/contexts/socket-content";
import {DText, Flex} from "../../../styles/components/common/common-styles";
import AnimatedTyping from "../../common/utils/AnimatedTyping";
import {useEffect, useState} from "react";
import {Column, Row} from "../../../styles/layouts/table-styles";
import Text from "../../../core/utils/Text";
import Tooltip from "../../common/utils/Tooltip";
import {formatDate} from "../../../core/utils/common";
import {useMainContext} from "../../../core/contexts/main";
import {CheckIcon} from "../../../styles/components/category/category-styles";
import {useRef} from "react";
import {BooleanIcon} from "../../common/utils/BoleanIcon";


const ForwardedMessagesShow = (props) => {

    const ref = useRef()
    const { main: { lang } } = useMainContext()
    const { forwardMessages } = useSocketContent()
    const [messages, setMessages] = useState([])
    const [onFocus, setOnFocus] = useState(false)

    useEffect(() => {
        let _messages = []
        forwardMessages?.forEach(message => {
            const title =
                `${message.sim} - ${message.name} : ${message.source?.title} => ${message.groups?.map(item => item.title)}`
            _messages.push(title)
            _messages.push(message.message.length ? message.message[0] : '')
            _messages.push(message.updatedAt)
        })
        setMessages(_messages)
    }, [forwardMessages])

    return (
        <DashboardBox
            onMouseEnter={() => setOnFocus(true)}
            onMouseLeave={() => setOnFocus(false)}
            {...props}
        >
            <ScrollBox ref={ref}>
                {onFocus ?
                    <>
                        <Row header cs={cs}>
                            {headers.map(item => (
                                <Column>
                                    <DText main fontSize={'ss'}>
                                        <Text tid={item} />
                                    </DText>
                                </Column>
                            ))}
                        </Row>
                        { forwardMessages?.map((item, idx) => {
                            const groups = `${item.groups?.map(g => g.title)}`
                            const message = item.message[0]
                            const now = (new Date()).getTime()

                            return (
                                <Row cs={cs} index={idx}>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {item.sim}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {item.name}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {item.source?.title}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <Tooltip
                                            content={groups}
                                        >
                                            <Flex>
                                                <DText main fontSize={'ss'}>
                                                    {groups.substring(0, 10)} ...
                                                </DText>
                                                <BooleanIcon bool={item.sendToTelegram} />
                                            </Flex>
                                        </Tooltip>
                                    </Column>
                                    <Column>
                                        <Tooltip
                                            content={message}
                                        >
                                            <DText main fontSize={'ss'}>
                                                {message.substring(0, 20)} ...
                                            </DText>
                                        </Tooltip>
                                    </Column>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {`${formatDate(item.updatedAt, 'date', lang)}-${formatDate(item.updatedAt, 'time', lang)}`}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <BooleanIcon bool={item.isDelete} />
                                    </Column>
                                </Row>
                            )
                        })}
                    </>
                    :
                    <Flex fw fh style={{ overflow: 'hidden' }}>
                        <AnimatedTyping ref={ref} messages={messages} />
                    </Flex>
                }
            </ScrollBox>
        </DashboardBox>
    )
}


const cs = '15% 15% 15% 20% 20% 10% 5%'
const headers = [
    'sim', 'operation', 'channel', 'groups', 'message', 'date'
]

export default ForwardedMessagesShow
