import GlobalStatsPieChart from "../../components/chart/GlobalStatsPieChart";
import {DashboardBox, DashboardWrapper, DashboardGrid} from "../../styles/components/dashboard";
import {useSocketContent} from "../../core/contexts/socket-content";
import OnlineChart from "../../components/chart/OnlineChart";
import DashboardOverallStats from "../../components/main/dashboard/DashboardOverallStats";
import FCounterChart from "../../components/chart/FCounterChart";
import ForwardedMessagesShow from "../../components/main/dashboard/ForwardedMessagesShow";

const Dashboard = () => {

    const { globalStats } = useSocketContent()

    return(
        <DashboardWrapper>
            <DashboardGrid>
                <DashboardOverallStats area={'a'}/>
                <ForwardedMessagesShow area={'b'} />
                <DashboardBox area={'g1'}>
                    <GlobalStatsPieChart data={globalStats?.sims} label={'Sims'} />
                </DashboardBox>
                <DashboardBox area={'g2'}>
                    <GlobalStatsPieChart data={globalStats?.operations} label={'Operations'} />
                </DashboardBox>
                <DashboardBox area={'g3'}>
                    <GlobalStatsPieChart data={globalStats?.groups} label={'Groups'} />
                </DashboardBox>
                <DashboardBox area={'g4'}>
                    <GlobalStatsPieChart data={globalStats?.channels} label={'Channels'} />
                </DashboardBox>
                <DashboardBox area={'c'}>
                    <OnlineChart />
                </DashboardBox>
                <DashboardBox area={'d'}>
                    <FCounterChart />
                </DashboardBox>
            </DashboardGrid>
        </DashboardWrapper>
    )
}

export default Dashboard;
