import {useFilterContext} from "../../../core/contexts/filter";
import {useEffect} from "react";
import FilterView from "./FilterView";


const FilterLayout = (props) => {

    const { setCache } = useFilterContext()
    useEffect(() => {
        setCache(props.cache)
    }, [props.cache])

    return (
        <>
            <FilterView
                {...props}
            />
            {props.children}
        </>
    )
}

export default FilterLayout
