

export const makeQueryFilters = (filters, pagination) => {
    if (!filters || !pagination) return
    const temp = {...pagination}
    const keys = Object.keys(filters)

    for (let i = 0; i < keys.length; i++) {

        let key = keys[i]
        let value = filters[key]

        /**
         * special keys
         */
        if (key === 'active') value = value === 'active'
        if (key === 'phone' && value.substring(0, 1) === '0') value = value.substring(1)


        temp[key] = value
    }

    return temp
}
