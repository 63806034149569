import { useDeleteAllJobs, useDeleteJob, useFetchJobs } from "../../core/services/react-query/log";
import TableLayout from "../../components/layouts/main/TableLayout";
import { Column, Row } from "../../styles/layouts/table-styles";
import { BooleanIcon } from "../../components/common/utils/BoleanIcon";
import CardLayout from "../../components/layouts/main/CardLayout";
import { DeleteIcon, Flex, IconWrapper } from "../../styles/components/common/common-styles";
import Tooltip from "../../components/common/utils/Tooltip";


const Jobs = () => {

    const { data: jobs, isLoading } = useFetchJobs()
    const { mutate: deleteJob } = useDeleteJob()
    const { mutate: deleteAllJobs } = useDeleteAllJobs()


    return (
        <CardLayout>
            <Flex fw justify={'flex-end'} style={{ padding: '0 20px' }}>
                <Tooltip content={'Delete time passed jobs'}>
                    <IconWrapper onClick={deleteAllJobs}>
                        <DeleteIcon size={18} />
                    </IconWrapper>
                </Tooltip>
            </Flex>
            <TableLayout
                headers={headers}
                cs={cs}
                data={{ data: jobs, loading: isLoading }}
                hasPagination={false}
            >
                {jobs?.data?.map((j, idx) => {

                    return (
                        <Row key={j._id} cs={cs} index={idx}>
                            <Column>
                                {idx + 1}
                            </Column>
                            <Column>
                                {j.name}
                            </Column>
                            <Column>
                                <span dir={'ltr'}>
                                    {(new Date(j.lastRunAt)).toLocaleString("fa-IR")}
                                </span>
                            </Column>
                            <Column>
                                <span dir={'ltr'}>
                                    {(new Date(j.nextRunAt)).toLocaleString("fa-IR")}
                                </span>
                            </Column>
                            <Column>
                                {j.repeatInterval}
                            </Column>
                            <Column>
                                {new Date(j.nextRunAt) < new Date() &&
                                    <BooleanIcon bool />
                                }
                            </Column>
                            <Column>
                                <IconWrapper onClick={() => deleteJob(j.name)}>
                                    <DeleteIcon size={18} />
                                </IconWrapper>
                            </Column>
                        </Row>
                    )
                })}
            </TableLayout>
        </CardLayout>

    )
}



const cs = '5% 20% 20% 15% 15% 15% 10%'
const headers = [
    'name', 'lastRunAt', 'nextRunAt', 'repeatInterval', 'shouldDelete'
]

export default Jobs
