import {CFlex, DText, Flex} from "../../../../styles/components/common/common-styles";
import {useEffect, useMemo, useRef, useState} from "react";
import {Row} from "../../../../styles/layouts/table-styles";
import ModalLayout from "../../../layouts/main/ModalLayout";
import Modification from "./Modification";
import {CREATE_C2C} from "./CreateC2c";


const Step3 = (props) => {

    const {
        inputData,
        onInputValueChange
    } = props

    const [src, setSrc] = useState([])
    const [modifyModal, setModifyModal] = useState({ open: false, item: null })

    useEffect(() => {
        const newSrc = []
        for (let i = 0; i < inputData.sources.length; i++) {
            newSrc.push({
                chat: inputData.sources[i],
                modifications: inputData.modifications[i]
            })
        }
        setSrc(newSrc)
    }, [inputData])

    const onOpenModifyModel = (idx, d) => setModifyModal({open: true, item: idx, type: d})
    const onCloseModifyModel = () => setModifyModal({ open: false, item: null })

    const onSaveModification = (modifications) => {
        const filtered = modifications.filter(m => m.active)
        const kinds = filtered.map(f => f.values)
        onInputValueChange({ idx: modifyModal.item, kinds, type: modifyModal.type }, CREATE_C2C.MODIFICATIONS)
        onCloseModifyModel()
    }

    const possibleModifications = useMemo(() => {
        let temp = []
        if (!!inputData.destination) temp.push('telegram')
        if (inputData.hasEitaa) temp.push('eitaa')
        return temp
    }, [inputData])


    return (
        <>
            <CFlex width={'550px'}>
                {src.map((item, idx) => (
                    <Row cs={'30% 55% 15%'} index={1}>
                        <Flex justify={'flex-start'}>
                            <DText primary>
                                {idx + 1}-
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {item.chat?.title}
                            </DText>
                        </Flex>
                        <Flex>
                            {possibleModifications.map(d => (
                                <img
                                    src={require(`../../../../assets/images/${d}.png`)}
                                    width={24}
                                    height={24}
                                    alt={d}
                                    style={{ margin: '0 5px', cursor: 'pointer' }}
                                    onClick={() => onOpenModifyModel(idx, d)}
                                />
                            ))}
                        </Flex>
                    </Row>
                ))}
            </CFlex>
            <ModalLayout
                open={modifyModal.open}
                onClose={onCloseModifyModel}
                width={'100%'}
            >
                <Modification
                    modification={modifyModal.type ? inputData.modifications[modifyModal.type][modifyModal.item] : []}
                    onSave={onSaveModification}
                    onClose={onCloseModifyModel}
                />
            </ModalLayout>
        </>
    )
}

export default Step3
