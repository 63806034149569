import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import {useGetANormalizer, useUpdateNormalizer} from "../../../../core/services/react-query/normalizer";
import {deepCopy} from "../../../../core/utils/common";
import useDetailsLayout from "../../../../core/hooks/layout/useDetailsLayout";
import NormalizerDetailEdit from "./NormalizerDetailEdit";
import NormalizerDetailShow from "./NormalizerDetailShow";
import DetailLayout from "../../../layouts/main/DetailLayout";
import {useQueryContext} from "../../../../core/contexts/query";


const NormalizerDetails = () => {

    const params = useParams()
    const { setToast } = useQueryContext()

    const { mutate: updateNormalizer, isLoading: updating, isSuccess: updateSuccess } = useUpdateNormalizer()

    const [sim, setSim] = useState(null)
    const initial = {
        name: '',
        active: false,
        groups: [],
        categories: [],
        messages: []
    }
    const [normalizerData, setNormalizerData] = useState(initial)
    const { data: normalizer, refetch: resetNormalizer } = useGetANormalizer(params.id)

    const resetData = () => {
        setSim(deepCopy(normalizer.sim_id))
        setNormalizerData(deepCopy(normalizer))
    }
    useEffect(() => {
        if (normalizer) resetData()
    }, [normalizer])

    const {
        mode,
        exitEditMode,
        enterEditMode
    } = useDetailsLayout(resetData)

    const Detail = mode === 'edit' ? NormalizerDetailEdit : NormalizerDetailShow

    const readyToUpdate = (before, after) => {
        return JSON.stringify(before) !== JSON.stringify(after)
    }

    const onSubmitChanges = () => {
        if (!normalizer) {
            setToast({
                isError: true, show: true,
                message: 'something-went-wrong'
            })
            return
        }
        let needUpdate = false
        let payload = {
            normalizer_id: normalizer._id
        }

        if (normalizer.name !== normalizerData.name) {
            payload.name = normalizerData.name
            needUpdate = true
        }

        if (sim?.username !== normalizer.sim_id?.username) {
            payload.sim_id = sim._id
            needUpdate = true
        }

        if (readyToUpdate(normalizer.groups, normalizerData.groups)) {
            payload.groups = normalizerData.groups.map(item => item._id)
            needUpdate = true
        }

        if (readyToUpdate(normalizer.categories, normalizerData.categories)) {
            payload.categories = normalizerData.categories.map(item => item._id)
            needUpdate = true
        }

        if (readyToUpdate(normalizer.messages, normalizerData.messages)) {
            payload.messages = normalizerData.messages
            needUpdate = true
        }

        if (normalizer.active !== normalizerData.active) {
            payload.active = normalizerData.active
            needUpdate = true
        }

        if (needUpdate) {
            updateNormalizer(payload)
        }else {
            setToast({
                isError: true, show: true,
                message: 'nothing-to-update'
            })
        }
    }

    const onNormalizerDetailChange = (v, type) => {
        if (type === 'sim_id') {
            setSim(v)
        }
        else {
            const newData = deepCopy(normalizerData)
            newData[type] = v
            setNormalizerData(newData)
        }
    }


    useEffect(() => {
        if (sim) {
            if (sim?.username !== normalizer.sim_id?.username) {
                const newData = deepCopy(normalizerData)
                newData.groups = []
                setNormalizerData(newData)
            }
        }
    }, [sim])

    useEffect(() => {
        if (updateSuccess) {
            exitEditMode()
            resetNormalizer()
        }
    }, [updateSuccess])

    return (
        <DetailLayout
            mode={mode}
            exitEditMode={exitEditMode}
            enterEditMode={enterEditMode}
            loading={updating}
            onSubmitChanges={onSubmitChanges}
        >
            <Detail
                sim={sim}
                normalizer={normalizerData}
                onNormalizerDetailChange={onNormalizerDetailChange}
            />
        </DetailLayout>
    )
}


export default NormalizerDetails
