import {
    AnimationWrapper, Background,
    CFlex,
    CMargin,
    Decoration,
    DText,
    Flex
} from "../../../../styles/components/common/common-styles";
import Text from "../../../../core/utils/Text";
import Select from "../../../common/select/Select";
import { AnimatePresence } from "framer-motion";
import { fadeVariants } from "../../../../core/utils/theme";
import MultiSelect from "../../../common/select/MultiSelect";
import { CREATE_C2C } from "./CreateC2c";
import CheckBox from "../../../common/input/CheckBox";
import Input from "../../../common/input/Input";
import SingleSelect from "../../../common/select/SingleSelect";


const Step1 = (props) => {

    const {
        inputData,
        onInputValueChange,
        simOptions,
        channels,
        adminChannels
    } = props

    return (
        <CFlex align={'flex-start'}>
            <DText primary>
                <Text tid={'name'} />
            </DText>
            <Flex width={'640px'} justify={'space-between'}>
                <Background bg={'primaryBg'}>
                    <Input
                        value={inputData.name}
                        onInputChange={(v) => onInputValueChange(v, CREATE_C2C.NAME)}
                        size={'small'}
                        height={'32px'}
                        padding={'0'}
                        width={'300px'}
                    />
                    <Flex width={'300px'} justify={'flex-start'}>
                        <CheckBox active={inputData.isDirect} onClick={() => onInputValueChange(null, CREATE_C2C.IS_DIRECT)} />
                        <DText primary style={{ margin: '0 5px' }}>
                            <Text tid={'is-direct'} />
                        </DText>
                    </Flex>
                </Background>
            </Flex>

            <Flex width={'640px'} justify={'space-between'}>
                <CFlex align={'flex-start'}>
                    <DText primary>
                        <Text tid={'sim-card'} />
                    </DText>
                    <CMargin margin={'3px'} />
                    <Select
                        width={'300px'}
                        height={'40px'}
                        value={inputData.sim_id}
                        placeHolder={'choose-sim'}
                        options={simOptions}
                        onValueChange={(idx) => onInputValueChange(idx, CREATE_C2C.SIM_ID)}
                        noLabel
                    />
                </CFlex>
                {(!!inputData.sim_id && !inputData.isDirect) &&
                    <CFlex align={'flex-start'}>
                        <DText primary>
                            <Text tid={'telegram-bridge'} />
                        </DText>
                        <CMargin margin={'3px'} />
                        <SingleSelect
                            value={inputData.telegramBridge}
                            options={adminChannels || []}
                            onValueChange={(g) => onInputValueChange(g, CREATE_C2C.TELEGRAM_BRIDGE)}
                            placeHolder='select-telegram-bridge'
                            type={'chat'}
                            param='address'
                            style={{ width: '300px', height: '38px', justifyContent: 'flex-start' }}
                            noLabel
                        />
                    </CFlex>
                }
            </Flex>

            <CMargin margin={'8px'} />
            <AnimatePresence exitBeforeEnter>
                {!!inputData.sim_id &&
                    <AnimationWrapper
                        initial={'out'}
                        animate={'in'}
                        exit={'out'}
                        variants={fadeVariants}
                    >
                        <Flex width={'640px'} justify={'space-between'} align={'flex-start'}>
                            <CFlex align={'flex-start'}>
                                <DText primary>
                                    <Text tid={'sources'} />
                                </DText>
                                <CMargin margin={'3px'} />
                                <MultiSelect
                                    values={inputData.sources || []}
                                    onValuesChange={(chats) => onInputValueChange(chats, CREATE_C2C.SOURCES)}
                                    placeHolder='select-sources'
                                    options={channels || []}
                                    type='chat'
                                    param='address'
                                    width={'300px'}
                                    height={'40px'}
                                    noLabel
                                />
                            </CFlex>

                            {/* TODO: admin channels */}
                            <CFlex align={'flex-start'}>
                                <DText primary>
                                    <Text tid={'destination'} />
                                </DText>
                                <CMargin margin={'3px'} />
                                {/*<MultiSelect*/}
                                {/*    values={inputData.destinations}*/}
                                {/*    onValuesChange={(chats) => onInputValueChange(chats, CREATE_C2C.DESTINATIONS)}*/}
                                {/*    placeHolder='select-destinations'*/}
                                {/*    options={adminChannels ? adminChannels : []}*/}
                                {/*    type='chat'*/}
                                {/*    param='address'*/}
                                {/*    width={'300px'}*/}
                                {/*    height={'40px'}*/}
                                {/*/>*/}
                                <SingleSelect
                                    value={inputData.destination}
                                    options={adminChannels || []}
                                    onValueChange={(chat) => onInputValueChange(chat, CREATE_C2C.DESTINATION)}
                                    placeHolder='select-destination'
                                    type={'chat'}
                                    param='address'
                                    style={{ width: '300px', height: '38px', justifyContent: 'flex-start' }}
                                    noLabel
                                />
                                <CMargin margin={'4px'} />
                                <Flex>
                                    <Decoration />
                                    <DText main>
                                        <Text tid={'destinations-note'} />
                                    </DText>
                                </Flex>
                            </CFlex>
                        </Flex>
                    </AnimationWrapper>
                }
            </AnimatePresence>

            <CMargin margin={'8px'} />
            <Flex fw justify={'space-between'} style={{ gap: '12px' }}>
                <Flex justify={'flex-start'}>
                    <CheckBox
                        active={inputData.repeat_finder?.isActive}
                        onClick={() => onInputValueChange({ key: 'isActive', value: !inputData.repeat_finder?.isActive }, CREATE_C2C.REPEAT_FINDER)}
                    />
                    <DText primary style={{ margin: '0 5px' }} className={'w-max'}>
                        <Text tid={'has-repeat-finder'} />
                    </DText>
                </Flex>
                {inputData.repeat_finder?.isActive ?
                    <>
                        <Background bg={'primaryBg'}>
                            <Input
                                value={inputData.repeat_finder?.similarity}
                                onInputChange={v => onInputValueChange({ key: 'similarity', value: v }, CREATE_C2C.REPEAT_FINDER)}
                                width={'144px'}
                                height={'32px'}
                                size={'small'}
                                label={'similarity'}
                            />
                        </Background>
                        <Background bg={'primaryBg'}>
                            <Input
                                value={inputData.repeat_finder?.count}
                                onInputChange={v => onInputValueChange({ key: 'count', value: v }, CREATE_C2C.REPEAT_FINDER)}
                                width={'144px'}
                                height={'32px'}
                                size={'small'}
                                label={'count'}
                            />
                        </Background>
                    </>
                    : null}
            </Flex>
            <CMargin margin={'8px'} />
        </CFlex>
    )
}

export default Step1
