import Input from "../../../common/input/Input";
import {useEffect, useMemo, useState} from "react";
import {Background, CFlex, CMargin, DText, Flex, Padding} from "../../../../styles/components/common/common-styles";
import ActionButton from "../../../common/buttons/ActionButton";
import Text from "../../../../core/utils/Text";
import {
    useCreateWorkspace,
    useGetAWorkspace,
    useGetPackages,
    useUpdateWorkspace
} from "../../../../core/services/react-query/setting";
import {packNames} from "../../../../core/constants/packages";
import Select from "../../../common/select/Select";
import {useQueryContext} from "../../../../core/contexts/query";
import ButtonLoader from "../../../common/loading/ButtonLoader";
import {COWrapper} from "../../../../styles/components/operation/operation-styles";
import CheckBox from "../../../common/input/CheckBox";
import {useLocation} from "react-router-dom";


const CreateWorkspace = () => {

    const { setToast } = useQueryContext()
    const search = new URLSearchParams(useLocation().search)
    const id = search.get('id')
    const { data: editableWorkspace } = useGetAWorkspace(id)

    const [workspaceName, setWorkspaceName] = useState('')
    const [selectedPack, setSelectedPack] = useState('')
    const [noLimit, setNoLimit] = useState(false)

    useEffect(() => {
        if (!!editableWorkspace) {
            setWorkspaceName(editableWorkspace?.name)
        }
    }, [editableWorkspace])

    const onInputChange = (v) => setWorkspaceName(v)

    const { mutate: createWorkspace, isLoading } = useCreateWorkspace()
    const { mutate: updateWorkspace, isLoading: updating } = useUpdateWorkspace()
    const { data: packages } = useGetPackages()

    const isValid = useMemo(() => {
        return !!workspaceName && (!!selectedPack || noLimit)
    }, [workspaceName, selectedPack, noLimit])

    const onCreateWorkspace = () => {
        if (isValid) {
            const query = !!editableWorkspace ? updateWorkspace : createWorkspace
            if (noLimit) query({name: workspaceName, noLimit: true})
            else {
                try {
                    const pack = packages.find(d => d.name === selectedPack)
                    delete pack.name
                    delete pack.updatedAt
                    delete pack.createdAt
                    delete pack._id
                    query({ name: workspaceName, ...pack })
                }
                catch (err) {
                    setToast({
                        isError: true, show: true,
                        message: 'pack-not-set'
                    })
                }
            }
        } else {
            setToast({
                isError: true, show: true,
                message: 'fill-inputs-error'
            })
        }
    }

    return (
        <COWrapper>
            <CFlex fw fh align={'flex-start'} justify={'flex-start'}>
                <Padding padding={'0 40px'}>
                    <Background bg={'primaryBg'}>
                        {!editableWorkspace &&
                            <>
                                <Input
                                    value={workspaceName}
                                    onInputChange={onInputChange}
                                    label={'name'}
                                    width={'420px'}
                                    height={'42px'}
                                />
                                <CMargin margin={'10px'} />
                            </>
                        }
                        <Flex style={{ padding: '0 8px' }}>
                            <CheckBox  active={noLimit} onClick={() => setNoLimit(state => !state)}/>
                            <DText primary margin={'0 8px'}>
                                <Text tid={'no-limit'} />
                            </DText>
                        </Flex>
                        {!noLimit &&
                            <>
                                <CMargin margin={'10px'} />
                                <Select
                                    value={selectedPack}
                                    onValueChange={(idx) => setSelectedPack(packNames[idx])}
                                    options={packNames}
                                    width={'420px'}
                                    height={'48px'}
                                    placeHolder={'select-pack'}
                                />
                            </>
                        }
                        <CMargin margin={'10px'} />
                        <ActionButton width={'420px'} height={'42px'} active={isValid} onClick={onCreateWorkspace}>
                            <ButtonLoader
                                tid={!!editableWorkspace ? 'edit-workspace' : 'create-workspace'}
                                loading={isLoading || updating}
                            />
                        </ActionButton>
                    </Background>
                </Padding>
            </CFlex>
        </COWrapper>
    )
}

export default CreateWorkspace
