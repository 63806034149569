import DatePicker from "react-multi-date-picker"
import { formatNumber } from "../../../../core/utils/common"
import Text from "../../../../core/utils/Text"
import { DText } from "../../../../styles/components/common/common-styles"
import { useMainContext } from "../../../../core/contexts/main"
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import "react-multi-date-picker/styles/colors/green.css"
import transition from "react-element-popper/animations/transition"
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import DateInput from "../../../common/input/DateInput"


const SendPvStage3 = ({
    input,
    onInputChange,
    totalMembers,
    categories
}) => {

    const { main: { theme, lang } } = useMainContext()
    const totalPosSendMessage = (60 / (input.delay_per_send || 1)) * 60 * input.operation_length * input.message_per_send * (input.sims?.length || 0)
    const operationProbLength = Math.ceil(totalMembers / (totalPosSendMessage / 60 / input.operation_length))

    return (
        <>
            <div className={'w-full grid grid-cols-6 gap-2 items-center mt-4'}>

                <DText main className={'text-xs col-span-6'}>
                    <Text tid={'message-col'} />
                </DText>

                <DText primary className={'col-span-6'}>
                    <Text tid={
                        input.activeTab === 'send-text' ?
                            input.body :
                            `${input.source_address}/${input.message_id}`
                    } />
                </DText>

                <div className={'h-[1px] col-span-6 bg-gray-500 bg-opacity-10'} />

                <DText main className={'text-xs col-span-2'}>
                    <Text tid={'message-type-col'} />
                </DText>

                <DText primary className={'col-span-4'}>
                    <Text tid={input.activeTab} />
                </DText>

                <DText main className={'text-xs col-span-2'}>
                    <Text tid={'categories-col'} />
                </DText>

                <DText primary className={'col-span-4'}>
                    <span className={'w-full truncate'}>{categories?.map(c => c.name).join(',')}</span>
                </DText>

                <DText main className={'text-xs col-span-2'}>
                    <Text tid={'sims-col'} />
                </DText>

                <DText primary className={'col-span-4'}>
                    <span className={'w-full truncate'}>{input.sims?.map(c => c.username).join(',')}</span>
                </DText>

                <div className={'h-[1px] col-span-6 bg-gray-500 bg-opacity-10'} />
            </div>

            <div className={'grid grid-cols-2 mt-3 gap-y-3 items-center'}>

                <DText main className={'text-xs'}>
                    <Text tid={'member-total'} />
                </DText>

                <div className={`text-xs text-white rounded-md flex justify-center
                        bg-blue-400 dark:bg-cyan-600 px-5 py-[2px] border-gray-500
                        border-[1px] w-max dark:bg-opacity-70 min-w-[100px]
                        `}>
                    {formatNumber(
                        Math.min(totalMembers, input.maximum_message || Infinity), true
                    )}
                </div>

                <DText main className={'text-xs'}>
                    <Text tid={'message-per-send-col'} />
                </DText>

                <div className={`text-xs text-white rounded-md flex justify-center 
                        bg-purple-400 dark:bg-purple-500 px-5 py-[2px] border-gray-400
                        border-[1px] w-max dark:bg-opacity-50  min-w-[100px]
                        `}>
                    {formatNumber(input.message_per_send, true)}
                </div>

                <DText main className={'text-xs'}>
                    <Text tid={'operation-length-prob-col'} />
                </DText>

                <div className={`text-xs text-white rounded-md  flex justify-center 
                        bg-orange-400 dark:bg-orange-500 px-5 py-[2px] border-gray-400
                        border-[1px] w-max dark:bg-opacity-70  min-w-[100px]
                        `}>
                    {formatNumber(operationProbLength, true)}
                </div>

                <DText main className={'text-xs'}>
                    <Text tid={'maximum-message-col'} />
                </DText>

                <div className={`text-xs text-white rounded-md flex justify-center 
                        bg-green-400 dark:bg-green-500 px-5 py-[2px] border-gray-400
                        border-[1px] w-max dark:bg-opacity-60 min-w-[100px]
                        `}>
                    {formatNumber(Math.min(input.maximum_message || Infinity, totalMembers, totalPosSendMessage), true)}
                </div>

                <div className={'h-[1px] col-span-2 bg-gray-500 bg-opacity-10'} />

                <div className={'col-span-2 grid grid-cols-2 items-center'}>
                    <DText main className={'text-xs'}>
                        <Text tid={'operation-date-col'} />
                    </DText>
                    <DatePicker
                        multiple={false}
                        value={input.date}
                        onChange={v => onInputChange(new Date(v), 'date')}
                        calendar={lang === 'fa' && persian}
                        locale={lang === 'fa' && persian_fa}
                        className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal rmdp-mobile`}
                        calendarPosition={'top'}
                        render={
                            <DateInput
                                date={new Date(input.date).toLocaleString(lang === 'fa' ? 'fa-IR' : 'en-US')}
                            />
                        }
                        animations={[transition()]}
                        plugins={[<TimePicker />]}
                    />
                </div>

            </div>
        </>
    )
}

export default SendPvStage3