import {useMainContext} from "../../../core/contexts/main";
import {CFlex, CMargin, DText, Flex, LineBreak, UpdateIcon} from "../../../styles/components/common/common-styles";
import ModalLayout from "../../layouts/main/ModalLayout";
import {useEffect, useState} from "react";
import Text from "../../../core/utils/Text";
import {deepCopy} from "../../../core/utils/common";
import ActionButton from "../buttons/ActionButton";
import {useQueryContext} from "../../../core/contexts/query";
import {PickerContainer, Tag} from "../../../styles/components/normalizer";


const WeekPicker = ({ days, onChange }) => {

    const { main: {lang} } = useMainContext()
    const { setToast } = useQueryContext()
    const [editMode, setEditMode] = useState(false)
    const exitEditMode = () => setEditMode(false)
    const enterEditMode = () => setEditMode(true)


    const allDays = [
        { en: 'saturday', fa: 'شنبه', active: false},
        { en: 'sunday', fa: 'یک شنبه', active: false},
        { en: 'monday', fa: 'دو شنبه', active: false},
        { en: 'tuesday', fa: 'سه شنبه', active: false},
        { en: 'wednesday', fa: 'چهار شنبه', active: false},
        { en: 'thursday', fa: 'پنج شنبه', active: false},
        { en: 'friday', fa: 'جمعه', active: false},
    ]

    const [weekDays, setWeekDays] = useState(allDays)

    useEffect(() => {
        if (days?.length) {
            const newWeekDays = deepCopy(weekDays)
            days.forEach(day => {
                const dayIndex = weekDays.findIndex(item => item.en === day)
                if (dayIndex > -1) {
                    newWeekDays[dayIndex].active = true
                }
            })
            setWeekDays(newWeekDays)
        }
    }, [days])

    const onWeekDaysStateChange = (idx) => {
        const newWeekDays = deepCopy(weekDays)
        newWeekDays[idx].active = !newWeekDays[idx].active
        setWeekDays(newWeekDays)
    }

    const onSubmitChanges = () => {
        let activeDays = weekDays.filter(item => item.active)
        activeDays = activeDays.map(item => item.en)
        if (activeDays.length) {
            onChange(activeDays)
            exitEditMode()
        }else{
            setToast({
                isError: true, show: true,
                message: 'weekdays-error'
            })
        }
    }

    return (
        <>
            <PickerContainer>
                <Flex fw justify={'space-between'}>
                    <Flex width={'80%'} wrap justify={'flex-start'}>
                        {days?.map(day => {
                            const item = allDays.find(d => d.en === day)
                            if (!item) return null

                            return (
                                <Tag>
                                    {item[lang]}
                                </Tag>
                            )
                        })}
                    </Flex>
                    <UpdateIcon
                        size={18}
                        onClick={enterEditMode}
                    />
                </Flex>
            </PickerContainer>
            <ModalLayout
                open={editMode}
                onClose={exitEditMode}
                width={'400px'}
                portal={false}
            >
                <CFlex fw>
                    <Flex fw justify='space-between'>
                        <DText primary>
                            <Text tid={'select-days'} />
                        </DText>
                    </Flex>
                    <CMargin margin='4px' />
                    <LineBreak />
                    <CMargin margin={'4px'} />
                    <Flex fw justify={'flex-start'} wrap>
                        {weekDays.map((day, idx) => (
                            <Tag
                                active={day.active}
                                onClick={() => onWeekDaysStateChange(idx)}
                            >
                                {day[lang]}
                            </Tag>
                        ))}
                    </Flex>
                    <CMargin margin={'10px'} />
                    <ActionButton
                        active
                        height={'38px'}
                        onClick={onSubmitChanges}
                    >
                        <Text tid={'submit'} />
                    </ActionButton>
                </CFlex>
            </ModalLayout>
        </>
    )
}

export default WeekPicker
