import HorizontalTabbar from "../../components/common/utils/HorizontalTabbar";
import {useRef, useState} from "react";
import {SettingBody, SettingContainer, SettingWrapper} from "../../styles/components/setting";
import {Flex} from "../../styles/components/common/common-styles";
import Workspace from "../../components/main/setting/workspaces/Workspace";
import Users from "../../components/main/setting/users/Users";
import Roles from "../../components/main/setting/roles/Roles";
import {useAccessLevels} from "../../core/services/acl/useAccessLevels";


const subpages = [
    Workspace,
    Users,
    Roles
]


const Setting = () => {

    const [activeTab, setActiveTab] = useState(1)
    const { permissions } = useAccessLevels()

    const tabs = [
        {name: 'workspace', ref: useRef(), perm: permissions?.workspace},
        {name: 'users', ref: useRef(), perm: true},
        {name: 'roles', ref: useRef(), perm: true},
    ]

    const onTabChange = (idx) => {
        setActiveTab(idx)
    }

    const SubPage = subpages[activeTab]

    return (
        <SettingWrapper>
            <SettingBody>
                <SettingContainer>
                    <HorizontalTabbar
                        tabs={tabs}
                        activeTab={activeTab > -1 ? activeTab : 0}
                        onTabChange={onTabChange}
                        controlRef={useRef()}
                    />
                    <SubPage />
                </SettingContainer>
            </SettingBody>
        </SettingWrapper>
    )
}

export default Setting
