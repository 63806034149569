import { DText, CloseIcon as DeleteOption } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {
    MultiOptions,
    OpenIcon,
    CloseIcon,
    OptionChip,
    SelectWrapper,
    Label
} from "../../../styles/components/common/select-styles"
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";



const TSelect = (props) => {

    const {
        open,
        width,
        height,
        param,
        size,
        values,
        placeHolder,
        onClick,
        onDeleteItem,
        noLabel,
        ...rest
    } = props

    const wrapperRef = useRef()
    const chipsRef = useRef([])

    const handleClick = (e) => {
        if (wrapperRef.current?.contains(e.target)) {
            let valid = true
            for (let i = 0; i < chipsRef?.current?.length; i++) {
                const current = chipsRef.current[i]
                if (current?.contains(e.target)) {
                    valid = false
                    break
                }
            }

            if (valid) {
                onClick()
            }
        }
    }


    useEffect(() => {
        document.addEventListener('click', handleClick, true)

        return () => {
            document.removeEventListener('click', handleClick, true)
        }
    }, [])


    return (
        <SelectWrapper
            ref={wrapperRef}
            width={width}
            height={height}
            size={size}
            {...rest}
        >
            {(values.length && !noLabel) ?
                <Label
                    size={size}
                    animate={true}
                    normal={true}
                >
                    <Text tid={placeHolder} />
                </Label>
                : null}
            <MultiOptions>
                {values.length ?
                    values.map((value, idx) => (
                        <OptionChip
                            ref={el => chipsRef.current[idx] = el}
                            className={'dark:bg-gray-800 bg-gray-200'}
                        >
                            <DText main>
                                {value[param]}
                            </DText>
                            <DeleteOption
                                size={18}
                                onClick={() => onDeleteItem(idx)}
                            />
                        </OptionChip>
                    ))
                    :
                    <DText main>
                        <Text tid={placeHolder} />
                    </DText>
                }
            </MultiOptions>

            {open ?
                <CloseIcon size={20} />
                :
                <OpenIcon size={20} />
            }
        </SelectWrapper>
    )
}



export default TSelect
