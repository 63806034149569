import { useMainContext } from "../../../contexts/main";
import { useQueryContext } from "../../../contexts/query";
import { useEffect, useState } from "react";
import { INPUT_TYPES } from "../../../constants/constants";
import { useNavigate } from "react-router-dom";
import { useSigninMutation, useVerifyOtp } from "../../../services/react-query/auth";
import { onInputValidation } from "../../../utils/validation";


export const useAuth = () => {

    const { profile: { token } } = useMainContext()
    const { setToast } = useQueryContext()

    const [step, setStep] = useState(1)
    const [otp, setOtp] = useState('')

    useEffect(() => {
        if (!!token) {
            navigate('/')
        }
    }, [])

    const {
        EMAIL,
        PASSWORD,
        OTP
    } = INPUT_TYPES

    const navigate = useNavigate()

    const onLoginSuccess = (res) => {
        setStep(2)
        setToast({
            message: res?.data?.message,
            show: true
        })
    }
    const { mutate: login, isLoading } = useSigninMutation(onLoginSuccess)
    const { mutate: verifyOtp, isLoading: verifyingOtp } = useVerifyOtp()

    const [valid, setValid] = useState(false)

    const [inputData, setInputData] = useState({
        email: "",
        password: ""
    })

    const [inputStatus, setInputStatus] = useState({
        email: null,
        password: null
    })

    useEffect(() => {
        const valid =
            inputStatus.email &&
            inputStatus.password
        setValid(valid)
    }, [inputData])


    const onInputValueChange = (val, type) => {
        let validation
        switch (type) {
            case EMAIL:
                validation = onInputValidation(val, EMAIL)
                setInputData(state => ({ ...state, email: val }))
                setInputStatus(state => ({ ...state, email: validation.valid }))
                break
            case PASSWORD:
                validation = onInputValidation(val, PASSWORD)
                setInputData(state => ({ ...state, password: val }))
                setInputStatus(state => ({ ...state, password: validation.valid, passwordConfirmation: validation.valid }))
                break
            case OTP:
                setOtp(val)
            default:
                break
        }
    }

    const onSubmitClicked = () => {
        if (step === 1 && valid) {
            let payload = {
                email: inputData.email,
                password: inputData.password,
            }
            payload = { ...payload }
            login(payload)
        }
        if (step === 2 && otp.length === 6) {
            let payload = {
                email: inputData.email,
                otp
            }
            verifyOtp(payload)
        }
    }

    return {
        onInputValueChange, EMAIL,
        inputData, inputStatus,
        PASSWORD, OTP, isLoading,
        step, otp, verifyingOtp,
        onSubmitClicked, valid,
    }
}
