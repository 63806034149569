import styled from "styled-components";
import {Flex} from "../common/common-styles";
import {motion} from "framer-motion";
import {ReactComponent as OperationBackgroundSvg} from "../../../assets/illustrations/create-operation.svg";


const COWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  margin-top: 80px;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    height: 100%;
    margin-top: 40px;
    justify-content: unset;
  };
`

const COBody = styled(motion.div)`
  width: 600px;
  //box-shadow: 0 0 4px #000022;
  border-radius: 12px;
  //border: 1px solid ${props => props.theme.color}10;
  background-color: ${props => props.theme.primaryBg};
  padding: 15px 20px;
  position: relative;
  z-index: 10;
  
  @media screen and (max-width: 768px) {
    width: 90%;
  };
`



const OperationSVG = styled(OperationBackgroundSvg)`
  opacity: 0.5;
  width: 600px;

  @media screen and (max-width: 768px) {
    width: 220px;
  };
`


const DelayWrapper = styled.div`
  min-height: 48px;
  width: calc(100% - 8px);
  border-radius: 4px;
  margin: 0 4px;
  border: 1px solid ${props => props.theme.color}80;
  padding: 8px;
  cursor: pointer;
`

const DelayTag = styled.div`
  border-radius: 4px;
  padding: 8px;
  margin: 0 8px;
  background-color: ${props => props.theme.mainBg};
`



export {
    COWrapper,
    COBody,
    OperationSVG,
    DelayWrapper,
    DelayTag
}
