import {CFlex, CMargin, DText, Flex} from "../../../../styles/components/common/common-styles";
import {MessageContentRow, MessageItem, Tag} from "../../../../styles/components/normalizer";
import Text from "../../../../core/utils/Text";
import {formatDate} from "../../../../core/utils/common";
import {useMainContext} from "../../../../core/contexts/main";


const MessageContent = ({ messages }) => {

    const { main: { lang } } = useMainContext()

    return (
        <>
            {messages?.map((message, idx) => (
                <MessageContentRow>
                    <CFlex fw align={'flex-start'}>
                        <DText primary fontSize={'s'}>
                            {idx + 1}-
                        </DText>
                        <CMargin margin={'4px'} />
                        <MessageItem>
                            <DText main fontSize={'s'}>
                                <Text tid={'message-body'} /> :
                            </DText>
                            <DText primary fontSize={'s'} margin={'5px 0'}>
                                {message.body}
                            </DText>
                        </MessageItem>
                        <Flex fw justify={'flex-start'} wrap>
                            <Tag dark>
                                {`${message.type}`}
                            </Tag>
                            {message.type !== 'static' &&
                                <Tag dark>
                                    {`${message.begin}-${message.end}`}
                                </Tag>
                            }
                            {message.type === 'weekly' &&
                                <>
                                    {message.weekDays.map(day => (
                                        <Tag dark>
                                            {day}
                                        </Tag>
                                    ))}
                                </>
                            }
                            {message.type === 'monthly' &&
                                <>
                                    {message.monthDays.map(day => (
                                        <Tag dark>
                                            {day}
                                        </Tag>
                                    ))}
                                </>
                            }
                            {message.type === 'static' &&
                                <>
                                    {message.static.map(item => (
                                        <Tag dark>
                                            {`${formatDate(item, 'date', lang)}-${formatDate(item, 'time', lang)}`}
                                        </Tag>
                                    ))}
                                </>
                            }
                        </Flex>
                    </CFlex>
                </MessageContentRow>
            ))}
        </>
    )
}


export default MessageContent
