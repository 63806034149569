import { useMainContext } from "../../../../core/contexts/main"
import { formatDate } from "../../../../core/utils/common"
import { CFlex, DText } from "../../../../styles/components/common/common-styles"
import { Column, DateBadge, Row } from "../../../../styles/layouts/table-styles"



const MembersList = ({ members, cs }) => {

    const { main: { lang } } = useMainContext()

    return (
        <>
            {members?.data?.map((member, idx) => {

                return (
                    <Row cs={cs} key={member._id} index={idx}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            {member.username || '--'}
                        </Column>
                        <Column>
                            {member.mobile || '--'}
                        </Column>
                        <Column>
                            {`${member.firstName} ${member.lastName}` || '--'}
                        </Column>
                        <Column>
                            {member.categories.map(x => x.name).join(',')}
                        </Column>
                        <Column>
                            {member.peer_id}
                        </Column>
                        <Column>
                            {member.access_hash}
                        </Column>
                        <Column>
                            <DateBadge>
                                <CFlex>
                                    <DText primary>
                                        {formatDate(member.createdAt, 'date', lang)}
                                    </DText>
                                    <DText primary>
                                        {formatDate(member.createdAt, 'time', lang)}
                                    </DText>
                                </CFlex>
                            </DateBadge>
                        </Column>
                    </Row>
                )
            })}
        </>
    )
}

export default MembersList