import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {MultiSelectOptionsWrapper, ChatOption} from "../../../styles/components/common/select-styles";


const CategorySelectItems = ({
    options,
    selected,
    onOptionClicked
}) => {


    return (
        <MultiSelectOptionsWrapper>
            {options.map((category, idx) => (
                <ChatOption
                    active={selected.includes(category.name)}
                    onClick={() => onOptionClicked(category)}
                    type={'category'}
                >
                    <CFlex fh align={'flex-start'} style={{ backgroundColor: 'transparent' }}>
                        <Flex>
                            <DText main>
                                <Text tid={'name'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {category.name}
                            </DText>
                        </Flex>
                    </CFlex>

                    <CFlex fh align={'flex-end'} style={{ backgroundColor: 'transparent' }}>
                        <Flex style={{ margin: '4px 0' }}>
                            <DText main>
                                <Text tid={'list'} />:
                            </DText>
                            <DText primary style={{ margin: '0 4px' }}>
                                {category.list?.length}
                            </DText>
                        </Flex>
                    </CFlex>
                </ChatOption>
            ))}
        </MultiSelectOptionsWrapper>
    )

}


export default CategorySelectItems
