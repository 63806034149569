import axios from "axios"
import { BASE_URL } from "../../constants/constants"


/**
 * Put api with token
 * @param {Object} data
 * @param {string} path
 * @returns {Promise}
 */
const putApi = (data, path) => {
    return axios.put(
        BASE_URL + path, data
    )
}


export {
    putApi
}
