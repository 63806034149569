import {
    CloseIcon, NavItem,
    OpenCloseBtn,
    OpenIcon,
    SidebarWrapper,
    NavText,
    ExitIcon
} from "../../../styles/layouts/Sidebar";
import {CFlex, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {useMainContext} from "../../../core/contexts/main";
import {AnimatePresence} from "framer-motion";
import {Fragment, useEffect, useState} from "react";
import {useLogout} from "../../../core/services/react-query/auth";
import useSidebar from "../../../core/hooks/layout/useSidebar";
import {useWindowSize} from "../../../core/hooks/common/useWindowSize";
import Tooltip from "../../common/utils/Tooltip";
import {GoInfo} from "react-icons/go";
import {Link} from "react-router-dom";


const Sidebar = () => {

    const { width } = useWindowSize()
    const activeNav = window.location.pathname
    const {main: {sidebar, setSidebar, theme, lang} } = useMainContext()

    const { mutate: logout } = useLogout()

    const [navAnimation, setNavAnimation] = useState(false)

    const {routes} = useSidebar()


    useEffect(() => {
        setNavAnimation(sidebar)
    }, [sidebar])


    const renderProperBtn = () => {

        if (lang === 'en') {
            if (sidebar) return <CloseIcon />
            else return <OpenIcon />
        }else {
            if (sidebar) return <OpenIcon />
            else return <CloseIcon />
        }
    }

    if (width < 768 && activeNav.includes('moderator')) {
        return <></>
    }

    return(
        <div style={{ position: 'relative' }}>
            <SidebarWrapper open={sidebar}>
                <CFlex fw>
                    {Object.keys(routes || {})?.map((key, idx) => {
                        // if (!nav.show) return <></>
                        const navigations = routes[key]
                        return (
                            <Fragment key={key}>
                                <div className={'w-full flex flex-col gap-1'}>
                                    <Text tid={key} className={'text-xs text-blue-300'} />
                                    <div className={'w-full h-[1px] bg-gray-300 opacity-40'} />
                                </div>
                                {navigations.map(nav => {

                                    return (
                                        <Link to={nav.path} className={'w-full'}>
                                            <NavItem
                                                key={nav.title}
                                                active={activeNav === nav.path}
                                                open={sidebar}
                                            >
                                                <Flex fw justify='flex-start'>
                                                    <nav.icon color={theme === 'dark' ? '#F0F5F9' : '#191c20'} size={18} />
                                                    <AnimatePresence exitBeforeEnter>
                                                        {sidebar &&
                                                            <NavText variants={variants} animate='in' initial={navAnimation ? 'out' : 'in'} exit='out'>
                                                                <DText fontSize='m' main>
                                                                    <Text tid={nav.title} />
                                                                </DText>
                                                            </NavText>
                                                        }
                                                    </AnimatePresence>
                                                    {!nav.show ?
                                                        <Tooltip content={'no-access'}>
                                                            <div className={'w-max'}>
                                                                <GoInfo size={18} color={'#e9106c'} />
                                                            </div>
                                                        </Tooltip>
                                                        : null}
                                                </Flex>
                                            </NavItem>
                                        </Link>
                                    )
                                })}
                            </Fragment>
                        )
                    })}
                </CFlex>
                <NavItem
                    open={sidebar}
                    onClick={() => logout()}
                    exit
                >
                    <Flex fw>
                        <ExitIcon size={20} />
                        <AnimatePresence exitBeforeEnter>
                            {sidebar &&
                                <NavText variants={variants} animate='in' initial={navAnimation ? 'out' : 'in'} exit='out'>
                                    <DText fontSize='m' color={'#e9106c'}>
                                        <Text tid={'exit'} />
                                    </DText>
                                </NavText>
                            }
                        </AnimatePresence>
                    </Flex>
                </NavItem>
            </SidebarWrapper>
            <OpenCloseBtn onClick={() => setSidebar(state => !state)} open={sidebar}>
                <Flex fw fh>
                    {renderProperBtn()}
                </Flex>
            </OpenCloseBtn>

        </div>
    )
}

const variants = {
    in: {
        transition: {duration: 0.3, delay: 0.3},
        opacity: 1
    },
    out: {
        transition: {duration: 0},
        opacity: 0
    }
}

export default Sidebar
