import Text from "../../core/utils/Text";
import { FcAdvertising } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import { FcGenealogy } from "react-icons/fc";
import { FcMindMap } from "react-icons/fc";
import { FcWorkflow } from "react-icons/fc";
import { FcCollect } from "react-icons/fc";



const Section3 = () => {

    const icons = [
        FcAdvertising,
        FcBullish,
        FcCollect,
        FcGenealogy,
        FcWorkflow,
        FcMindMap,

    ]

    return (
        <div className={'w-full bg-primeBg p-20 text-slate-500'}>
            <div className={'grid grid-cols-2 max-w-[1144px] mx-auto gap-10'}>
                <div className={'flex items-center justify-center col-span-2 '}>
                    <Text tid={'features-head'} className={'text-3xl text-white font-semibold'} />
                </div>
                {Array.from(Array(6).keys()).map(i => {
                    const Icon = icons[i]
                    return (
                        <div className={'rounded-md bg-mainBg p-8 min-h-[200px] flex flex-col justify-center items-center gap-3 shadow'}>
                            <Icon size={64} />
                            <Text tid={`feature-${i}-head`} className={'text-2xl text-gray-900 font-semibold'}/>
                            <Text tid={`feature-${i}-desc`} className={'text-center text-lg'} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Section3
