import {Absolute, CFlex, CloseIcon, CMargin, DText} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import Input from "../common/input/Input";
import ActionButton from "../common/buttons/ActionButton";
import {useActivateSim} from "../../core/services/react-query/sim";
import {useState} from "react";
import {useQueryContext} from "../../core/contexts/query";
import ButtonLoader from "../common/loading/ButtonLoader";


const ActivateSimModal = ({
    activateData,
    onClose,
    editMode
}) => {

    const { mutate: activateSim, isLoading } = useActivateSim(onClose)
    const { setToast } = useQueryContext()
    const [code, setCode] = useState('')

    const onActivateSimClicked = () => {
        if (code.length === 5) {
            const payload = {...activateData, code}
            if (editMode) payload.change = true
            activateSim(payload)
        }
        else setToast({
            isError: true, show: true,
            message: 'sim-activate-code-error'
        })
    }

    return (
        <CFlex fw>
            <Absolute left={0} top={0}>
                <CloseIcon onClick={onClose} />
            </Absolute>
            <DText fontSize='b' primary>
                <Text tid='get-sim-code' />
            </DText>
            <CMargin margin='10px' />
            <Input
                value={code}
                label='code'
                onInputChange={v => setCode(v)}
                valid={code.length === 5}
                error='sim-code-error'
                code
            />
            <CMargin margin='8px' />
            <ActionButton
                active={code.length === 5}
                onClick={onActivateSimClicked}
            >
                <ButtonLoader
                    loading={isLoading}
                    tid={'submit'}
                />
            </ActionButton>
        </CFlex>
    )
}

export default ActivateSimModal
