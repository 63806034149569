import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDeleteGroupMutation, useUpdateGroupMutation} from "../../services/react-query/group";
import {useDeleteChannelMutation, useUpdateChannelMutation} from "../../services/react-query/channel";


const useChatListOperations = (
    type
) => {

    const navigate = useNavigate()
    const [selectedChat, setSelectedChat] = useState(null)
    const [updateModalOpen, setUpdateModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const onUpdateSuccess = () => {
        closeUpdateModal()
        setSelectedChat(null)
    }

    const updateQuery = type === 'group' ? useUpdateGroupMutation : useUpdateChannelMutation
    const { mutate: updateChat, isLoading: chatIsUpdating } = updateQuery(onUpdateSuccess)

    const deleteQuery = type === 'group' ? useDeleteGroupMutation : useDeleteChannelMutation
    const { mutate: deleteChat, isLoading: chatIsDeleting } = deleteQuery()

    const onDetailsClicked = (chat) => {
        navigate(`/${type}s/${chat._id}?type=${type}`)
    }


    /**
     * update group functions
     */
    const onUpdateClicked = (chat) => {
        setUpdateModalOpen(true)
        setSelectedChat(chat)
    }

    const closeUpdateModal = () => setUpdateModalOpen(false)

    /**
     * @param data - { sims, categories }
     */
    const onUpdateSubmitted = (data) => {
        let sims = data.sims
        const isExchange = selectedChat?.sims?.find(item => item.username === 'Alpha')
        if (!!isExchange) {
            sims = [...sims, isExchange]
        }
        const payload = {
            ...data,
            sims,
            [`${type}_id`]: selectedChat._id,
            type: selectedChat.type
        }
        updateChat(payload)
    }


    /**
     * delete group functions
     */
    const onDeleteClicked = (chat) => {
        setDeleteModalOpen(true)
        setSelectedChat(chat)
    }

    const closeDeleteModal = () => setDeleteModalOpen(false)
    const onDeleteSubmitted = () => {
        deleteChat(selectedChat._id)
        closeDeleteModal()
        setSelectedChat(null)
    }

    return {
        onUpdateClicked,
        onDeleteClicked,
        onDetailsClicked,
        updateModalOpen,
        deleteModalOpen,
        closeUpdateModal,
        selectedChat,
        onUpdateSubmitted,
        closeDeleteModal,
        onDeleteSubmitted,
        chatIsUpdating,
        chatIsDeleting
    }

}


export default useChatListOperations
