import { useTranslation } from "react-i18next"
import { DText, Padding, TextArea } from "../../../../styles/components/common/common-styles"
import Tabs from "../../../common/select/Tabs"
import Input from "../../../common/input/Input"
import MultiSelect from "../../../common/select/MultiSelect"
import Text from "../../../../core/utils/Text"
import { formatNumber } from "../../../../core/utils/common"



const SendPvStage1 = ({
    categories,
    setCategories,
    input,
    onInputChange,
    categoryOptions,
    totalMembers
}) => {

    const { t } = useTranslation()
    const tabs = ['send-text', 'post-as-message', 'forward-post']

    return (
        <>
            <Tabs
                tabs={tabs}
                active={input.activeTab}
                onChange={v => onInputChange(v, 'activeTab')}
            />

            <div className={"flex flex-col pt-2"}>
                {input.activeTab === 'send-text' ?
                    <TextArea
                        value={input.body}
                        onChange={(e) => onInputChange(e?.target?.value, 'body')}
                        placeholder={`${t('message')}...`}
                        className={'mb-5'}
                    />
                    :
                    <>
                        <Input
                            label={'source_address'}
                            value={input.source_address}
                            onInputChange={v => onInputChange(v, 'source_address')}
                        />
                        <Input
                            label={'message_id'}
                            value={input.message_id}
                            onInputChange={v => onInputChange(v, 'message_id')}
                            code
                        />
                    </>
                }

                <Padding padding={input.activeTab === 'send-text' ? '0' : '4px'}>
                    <MultiSelect
                        values={categories}
                        onValuesChange={setCategories}
                        placeHolder='select-categories'
                        options={categoryOptions || []}
                        type='category'
                        param='name'
                        size={'big'}
                    />
                </Padding>

                <div className={'bg-gray-500 bg-opacity-10 h-[1px] w-full mt-8'} />
                <div className={'flex items-center gap-5 mt-4'}>
                    <DText main className={'text-xs'}>
                        <Text tid={'total-members-categories'} />
                    </DText>

                    <span className={`text-sm text-white rounded-md 
                        bg-blue-400 dark:bg-cyan-600 px-5 py-[2px] border-opacity-10 
                        border-[1px]
                        `}>
                        {formatNumber(totalMembers, true)}
                    </span>
                </div>
            </div>
        </>
    )
}

export default SendPvStage1