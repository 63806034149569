import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../../fetch-api/get";
import { makeQueryFilters } from "../../../utils/query-maker";
import { postApi } from "../../fetch-api/post";
import { useQueryContext } from "../../../contexts/query";
import { useNavigate } from "react-router-dom";
import { deleteApi } from "../../fetch-api/delete";
import { putApi } from "../../fetch-api/put";
import { routes } from "../../../constants/routes";


const C2C_KEYS = {
    C2C_LIST: 'c2c-list',
    SEND_MESSAGE: 'send-message',
    GET_MODERATOR: 'get-moderator',
    CREATE_C2C: 'create-c2c',
    DELETE_C2C: 'delete-c2c',
    UPDATE_C2C: 'update-c2c',
    GET_A_C2C: 'get-a-c2c',
    CHANGE_STATUS: 'change-status'
}

const useGetC2cList = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        C2C_KEYS.C2C_LIST, () => filterFetch(params, `/c2c`)
    )
}


const useGetModerator = ({ page, id }) => {


    return useQuery(
        [C2C_KEYS.GET_MODERATOR, id, page], () => filterFetch({ page, _date: 1 }, `/c2c/get/moderator/${id}`),
        {
            select: res => res?.data,
            refetchInterval: 60 * 1000,
            enabled: !!id
        }
    )
}


const useSendModeratorMessage = (onSuccess) => {

    const { queryClient } = useQueryContext()

    return useMutation(
        C2C_KEYS.SEND_MESSAGE, (data) => postApi(data, '/c2c/sendMessage'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(C2C_KEYS.GET_MODERATOR)
                onSuccess()
            }
        }
    )
}

const useCreateC2c = () => {

    const navigate = useNavigate()
    const { setToast } = useQueryContext()

    return useMutation(
        C2C_KEYS.CREATE_C2C, (data) => postApi(data, '/c2c'),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                navigate(routes.c2c)
            }
        }
    )
}


const useDeleteC2c = () => {
    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        C2C_KEYS.DELETE_C2C, (id) => deleteApi(`/c2c/${id}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
                queryClient.invalidateQueries(C2C_KEYS.C2C_LIST)
            }
        }
    )
}

const useGetAC2c = (id) => {

    return useQuery(
        C2C_KEYS.GET_A_C2C, () => normalFetch(`/c2c/${id}`),
        {
            enabled: false,
            select: res => res?.data?.data
        }
    )
}

const useUpdateC2c = () => {

    const { setToast } = useQueryContext()

    return useMutation(
        C2C_KEYS.UPDATE_C2C, (data) => putApi(data, `/c2c/${data?._id}`),
        {
            onSuccess: () => {
                setToast({
                    message: 'op-success', show: true
                })
            }
        }
    )
}

const useChangeC2cStatus = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        C2C_KEYS.CHANGE_STATUS, (data) => postApi(data, '/c2c/changeStatus'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([C2C_KEYS.C2C_LIST])
                setToast({
                    message: 'op-success', show: true
                })
            }
        }
    )
}


export {
    useGetC2cList,
    useGetModerator,
    useSendModeratorMessage,
    useCreateC2c,
    useDeleteC2c,
    useGetAC2c,
    useUpdateC2c,
    useChangeC2cStatus
}
