import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { STATIC_URL } from "../constants/constants";
import { useMainContext } from "./main";
import { useProfileQuery } from "../services/react-query/auth";



const SocketContext = createContext()

export const useSocket = () => {
    return useContext(SocketContext)
}

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState()

    const { profile: { token } } = useMainContext()
    const { data: profile, refetch: getProfile } = useProfileQuery()

    useEffect(() => {
        if (!profile && !!token) {
            getProfile()
        }
    }, [token, profile, getProfile])

    useEffect(() => {
        let newSocket;
        if (profile && !socket) {
            newSocket = io(STATIC_URL, { transports: ['websocket'], query: `workspace=${profile.workspace}` })
            setSocket(newSocket)
        }

        // return () => newSocket?.close()
    }, [profile, socket])

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}
