import {AnimatePresence} from "framer-motion";
import {SubmitModalWrapper} from "../../styles/layouts/main-styles";
import {Decoration, DText, Flex} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import BasicModalActionButton from "../common/buttons/BasicModalActionButton";



const SubmitSettingModal = ({
    onSubmit,
    onCancel,
    loading,
    editMode
}) => {

    return (
        <AnimatePresence exitBeforeEnter>
            {editMode &&
                <SubmitModalWrapper
                    variants={variants}
                    initial={'out'}
                    animate={'in'}
                    exit={'out'}
                >
                    <Flex fw justify='flex-start'>
                        <Decoration />
                        <DText main>
                            <Text tid={'submit-note'} />
                        </DText>
                    </Flex>
                    <Flex fw style={{marginTop: '10px'}}>
                        <BasicModalActionButton
                            onSubmit={onSubmit}
                            onCancel={onCancel}
                            loading={loading}
                        />
                    </Flex>
                </SubmitModalWrapper>
            }
        </AnimatePresence>
    )
}



const variants = {
    in: {
        y: 0, transition: {duration: 0.3}
    },
    out: {
        y: '-100%', transition: {duration: 0.3}
    }
}

export default SubmitSettingModal
