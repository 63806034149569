import styled, { css } from "styled-components";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const SelectWrapper = styled.div`
  width: ${props => props.width || '100%'};
  min-height: ${props => props.height || '50px'};
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props => props.theme.color}30;
  padding: ${props => props.size === 'big' ? '0 16px' : '0 5px'};
  position: relative;
  cursor: pointer;
  background-color: ${props => props.theme.inputBg};
  display: flex;
  
  ${props => props.open && css`
    border: 1px solid ${props => props.theme.active};
  `};
`

const Label = styled.div`
  position: absolute;
  font-size: 0.7rem;
  top: calc(50% - 0.6rem);
  background-color: ${props => props.theme.inputBg};
  padding: 1px 8px;  
  letter-spacing: 1.2px;
  border-radius: 4px;
  
  ${props => props.theme.english && css`
      left: 10px;
  `};

  ${props => !props.theme.english && css`
      right: 10px;
  `};

  color: ${props => props.theme.color};

  ${props => props.animate && css`
    color: ${props => props.theme.active};
  `};
  
  ${props => (props.value || props.value === 0 || props.animate) && css`
    top: -22%;
    z-index: 10;
  `};

  ${props => props.status === 'valid' && css`
    color: ${props => props.theme.mainGreen};
  `};

  ${props => props.status === 'error' && css`
    color: ${props => props.theme.mainRed}90;
  `};

  ${props => props.normal && css`
    color: ${props => props.theme.color};
  `};
  
  transition: all 0.3s;
  z-index: 1;

  ${props => props.size === 'small' && css`
    font-size: 0.7rem;
  `};
`


const MultiOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const OptionChip = styled.div`
  padding: 6px 8px;
  border-radius: 8px;
  margin: 5px;
  border: 1px solid ${props => props.theme.color}20;
  display: flex;
  justify-content: center;
  align-items: center;
`


const OpenIcon = styled(RiArrowDropDownLine)`
  color: ${props => props.theme.color};
  margin-top: 2px;
`


const CloseIcon = styled(RiArrowDropUpLine)`
  color: ${props => props.theme.color};
  margin-top: 2px;
`


const MultiSelectOptionsWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px dashed ${props => props.theme.active}25;
  margin: 10px 0;
  overflow-y: auto;
`

const SelectBtn = styled.div`
  width: 120px;
  height: 32px;
  border-radius: 26px;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
  background-color: ${props => props.active ? props.theme.active : props.theme.color}90;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0 10px;
  font-size: 0.8rem;
  font-weight: 600;
  
  &:hover{
    background-color: ${props => props.active ? props.theme.active : props.theme.color};
  }
`

const ChatOption = styled.div`
  padding: 8px 12px;
  display: grid;
  background-color: ${props => props.active ? props.theme.active : props.theme.dropdown}80;
  border-radius: 6px;
  border: 2px solid ${props => props.theme.color}20;
  margin: 15px 10px;
  cursor: pointer;
  transition: all 0.3s;
  ${props => (props.type === 'category' || props.type === 'sim') && css`
    grid-template-columns: 50% 50%;
  `};
  ${props => props.type === 'chat' && css`
    grid-template-columns: 15% 60% 25%;
  `};
  row-gap: 4px;
  
  &:hover{
    background-color: ${props => props.active ? props.theme.active : props.theme.dropdown};
  }
  
  ${props => props.active && css`
    border: 2px solid ${props => props.theme.primary}60;
  `};
`

const SearchInput = styled.input`
    border: none;
    outline: none;
    font-size: 0.8rem;
    color: ${props => props.theme.color};
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    &:focus {
        background-color: ${props => props.theme.mainBg};
    };
`

export {
  MultiOptions,
  OpenIcon,
  CloseIcon,
  OptionChip,
  SelectWrapper,
  MultiSelectOptionsWrapper,
  SelectBtn,
  ChatOption,
  SearchInput,
  Label
}
