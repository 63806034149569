import DetailBox from "../../../common/utils/DetailBox";
import {
    DText,
    Flex,
    Padding
} from "../../../../styles/components/common/common-styles";
import Text from "../../../../core/utils/Text";
import DetailMiniView from "../../details/DetailMiniView";
import DetailContent from "../../details/DetailContent";


const OperationDetailsShow = ({
    sim,
    operation
}) => {

    return (
        <>
            <DetailBox>
                <Flex fw justify={'space-between'}>
                    <Flex>
                        <DText main>
                            <Text tid={'operation-name'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {operation?.name}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'sim'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {sim?.username}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'status'} /> :
                        </DText>
                        <DText color={operation?.active ? '#1ce087' : '#e9106c'} style={{ margin: '0 5px' }}>
                            <Text tid={operation?.active ? 'active' : 'disabled'} />
                        </DText>
                    </Flex>
                </Flex>
            </DetailBox>
            <DetailBox>
                <Flex fw justify={'space-between'}>
                    <Flex>
                        <DText main>
                            <Text tid={'delay-type'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px' }}>
                            {operation?.delay?.mode}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'delay-time'} /> :
                        </DText>
                        <DText primary style={{ margin: '0 5px', direction: 'ltr' }} >
                            {operation?.delay.mode === 'period' ?
                                `${operation?.delay.begin}-${operation?.delay.end} mins`
                                :
                                `${operation?.delay.delay} mins`
                            }
                        </DText>
                    </Flex>
                    <Flex>
                        {operation?.delay.mode === 'keep-send' &&
                            <>
                                <DText main>
                                    <Text tid={'delay-limit'} /> :
                                </DText>
                                <DText primary style={{ margin: '0 5px', direction: 'ltr' }} >
                                    {operation?.delay.limit}
                                </DText>
                            </>
                        }
                    </Flex>
                </Flex>
            </DetailBox>
            <Flex fw justify={'space-between'} wrap align={'stretch'}>
                <DetailBox
                    width={'49%'}
                    title={'channels'}
                >
                    <DetailContent
                        data={operation?.channels}
                        photo={'address'}
                        title={'title'}
                        type={'channels'}
                    />
                </DetailBox>
                <DetailBox
                    width={'49%'}
                    title={'groups'}
                >
                    <DetailContent
                        data={operation?.groups}
                        photo={'address'}
                        title={'title'}
                        type={'groups'}
                    />
                </DetailBox>
            </Flex>
        </>
    )
}

export default OperationDetailsShow
