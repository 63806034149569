import Tooltip from "./Tooltip";
import styled from "styled-components";


const GroupError = ({ message }) => {

    return (
        <Tooltip
            content={message}
        >
            <Circle />
        </Tooltip>
    )
}

const Circle = styled.div`
  background-color: ${props => props.theme.mainRed};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 3px;
  cursor: pointer;
`

export default GroupError
