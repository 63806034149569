import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { MdClose, MdDeleteOutline, MdAdd, MdRefresh } from 'react-icons/md'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { FaRegEdit } from 'react-icons/fa'
import { BiCheck, BiSend } from 'react-icons/bi'
import { IoIosClose } from 'react-icons/io'


const fontSizes = {
  sb: { d: "1.6rem", t: "1.4rem", m: "1rem" },
  b: { d: "1.1rem", t: "1rem", m: "0.9rem" },
  m: { d: "0.8rem", t: "0.7rem", m: "0.65rem" },
  s: { d: "0.7rem", t: "0.6rem", m: "0.55rem" },
  ss: { d: '0.6rem', t: '0.6rem', m: '0.6rem' }
}

const Flex = styled.div`
  display: flex;
  width: ${props => props.width ? props.width : props.fw ? "100%" : "unset"};
  height: ${props => props.height ? props.height : props.fh ? "100%" : "unset"};
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.align || "center"};
  flex-wrap: ${props => props.wrap && "wrap"};
  position: relative;
  ${props => props.bg && css`
    background-color: ${props => props.theme[props.bg]};
  `};
  margin: ${props => props.margin && props.margin};
`

const CFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width ? props.width : props.fw ? "100%" : "unset"};
  height: ${props => props.height ? props.height : props.fh ? "100%" : "unset"};
  justify-content: ${props => props.justify || "center"};
  align-items: ${props => props.align || "center"};
  flex-wrap: ${props => props.wrap && "wrap"};
  background-color: inherit;
  position: relative;
  
  ${props => props.transparent && css`
    background-color: transparent;
  `};
`

const Grid = styled.div`
  display: grid;
  width: ${props => props.width ? props.width : props.fw ? "100%" : "unset"};
  height: ${props => props.height ? props.height : props.fh ? "100%" : "unset"};
  grid-template-rows: ${props => props.gr || "unset"};
  grid-template-columns: ${props => `repeat(${props.gc}, 1fr)`};
  
  @media screen and (max-width: 1050px) {
    grid-template-columns: ${props => `repeat(${props.gc / 2}, 1fr)`};
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: ${props => props.gcR ? `repeat(${props.gcR}, 1fr)` : `repeat(${props.gc / 4 > 1 ? (props.gc / 4) : 1}, 1fr)`};
  }
  
  //@media screen and (max-width: 480px) {
  //  grid-template-columns: repeat(1, 1fr);
  //}
`

const Background = styled.div`
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  flex-wrap: inherit;
  align-items: inherit;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme[props.bg]};
`

const Padding = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: inherit;
  flex-wrap: inherit;
  padding: ${props => props.padding};
`

const RMargin = styled.div`
  margin: 0 ${props => props.margin};
`

const CMargin = styled.div`
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: inherit;
  margin: ${props => props.margin} 0;
`

const Margin = styled.div`
  width: inherit;
  height: inherit;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: inherit;
  margin: ${props => props.margin};
`

const DText = styled.div`
  font-size: ${({ cFontSize, fontSize }) => cFontSize ? cFontSize : (fontSize ? fontSizes[fontSize].d : fontSizes.m.d)};
  color: ${props => props.color && props.color};
  ${props => props.main && css`
    color: ${props => props.theme.color};
  `};
  ${props => props.primary && css`
    color: ${props => props.theme.primary};
  `};
  ${props => props.active && css`
    color: ${props => props.theme.mainOrange};
  `};
  direction: ${props => props._type === "number" && "ltr"};
  font-family: ${props => props.type === "number" && "monospace"};
  margin: ${props => props.margin && props.margin};
  
  @media screen and (max-width: 1050px){
    font-size: ${({ cFontSize, fontSize }) => cFontSize ? cFontSize : (fontSize ? fontSizes[fontSize].d : fontSizes.m.t)};
  }
  
  @media screen and (max-width: 768px) {
    font-size: ${({ cFontSize, fontSize }) => cFontSize ? cFontSize : (fontSize ? fontSizes[fontSize].d : fontSizes.m.m)};
  }
`


const Decoration = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: ${props => props.theme.mainOrange};
  border-radius: 2px;
  transform: rotate(45deg);
`

const Absolute = styled.div`
  position: absolute;
  top: ${props => props.top && props.top};
  bottom: ${props => props.bottom && props.bottom};
  left: ${props => props.left && props.left};
  right: ${props => props.right && props.right};
`


const NoDataWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${props => props.theme.color}20;
`


const DeleteIcon = styled(MdDeleteOutline)`
  color: ${props => props.theme.color};
  cursor: pointer;
`

const UpdateIcon = styled(FaRegEdit)`
  color: ${props => props.theme.color};
  cursor: pointer;
`


const CloseChevron = styled(FiChevronUp)`
  color: ${props => props.theme.primary};
  cursor: pointer;
  margin: 0 10px;
`

const OpenChevron = styled(FiChevronDown)`
  color: ${props => props.theme.primary};
  cursor: pointer;
  margin: 0 10px;
`

const CloseIcon = styled(MdClose)`
  color: ${props => props.theme.color};
  cursor: pointer;
  margin: 0 5px;
`


const AnimationWrapper = styled(motion.div)`
  width: 100%;
`


const NormalBtn = styled.div`
  width: 120px;
  height: 32px;
  border-radius: 26px;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
  background-color: ${props => props.active ? props.theme.active : props.theme.color}90;
  cursor: pointer;
  transition: all 0.3s;
  margin: 0 10px;

  &:hover{
    background-color: ${props => props.active ? props.theme.active : props.theme.color};
  }
`

const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color}20;
  border-radius: 12px;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
  margin: 8px 0;
`


const IconWrapper = styled(Flex)`
  width: 32px;
  cursor: pointer;
`


const DetailBoxWrapper = styled.div`
  width: ${props => props.width || "100%"};
  padding: 20px 16px;
  min-width: 300px;
  margin: 10px 0;
  border-radius: 4px;
  background-color: ${props => props.theme.mainBg};
  border: 1px solid ${props => props.theme.color}10;
  
  @media screen and (max-width: 768px) {
    width: 100%;
  };
`

const DetailBoxTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color}20;
  padding-bottom: 10px;
`

const ActiveIcon = styled(BiCheck)`
  color: ${props => props.theme.mainGreen};  
`

const InActiveIcon = styled(IoIosClose)`
  color: ${props => props.theme.mainRed};
`

const ChatMiniWrapper = styled.div`
  min-width: 180px;
  height: 52px;
  border-radius: 4px;
  background-color: ${props => props.theme.primaryBg};
  border: 1px solid ${props => props.theme.color}10;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
  margin: ${props => props.margin || '6px 8px'};
  position: relative;
`

const AddIcon = styled(MdAdd)`
  color: ${props => props.theme.color};
`

const TextArea = styled.textarea`
  background-color: ${props => props.theme.inputBg};
  border: 1px solid ${props => props.theme.color}20;
  color: ${props => props.theme.color};
  font-size: 0.9rem;
  width: ${props => props.width || '100%'};
  min-height: ${props => props.minHeight || '100px'};
  padding: 10px;
  font-family: ${props => props.theme.english ? "Jetbrain" : "Vazir"};
  
  @media screen and (max-width: 768px) {
    font-size: 0.75rem;
  };
`

const RefreshIcon = styled(MdRefresh)`
  color: ${props => props.theme.mainOrange};
`

const CustomInputBorder = styled.div`
  position: relative;
  padding: 0 8px;
  height: 32px;
  background-color: ${props => props.theme.inputBg};
  border: 1px solid ${props => props.theme.color}80;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: ${props => props.theme.color};
  cursor: pointer;
`

const HLineBreak = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${props => props.theme.color}20;
`

const MiniInput = styled.input`
  width: 118px;
  height: 28px;
  font-size: 0.775rem;
  padding: 0 8px;
  background-color: ${props => props.theme.mainBg};
  border: 1px solid ${props => props.theme.primary}20;
  outline: none;
  color: ${props => props.theme.primary};
  font-family: Jetbrain;
`

const ResendIcon = styled(BiSend)`
  color: ${props => props.theme.color};
  transform: rotate(0deg);
  ${props => !props.theme.english && css`
    transform: rotate(180deg);
  `};
`

export {
  Flex,
  CFlex,
  Grid,
  DText,
  Background,
  Padding,
  Margin,
  CMargin,
  RMargin,
  Decoration,
  Absolute,
  NoDataWrapper,
  DeleteIcon,
  OpenChevron,
  CloseChevron,
  CloseIcon,
  AnimationWrapper,
  NormalBtn,
  LineBreak,
  UpdateIcon,
  IconWrapper,
  DetailBoxWrapper,
  DetailBoxTitle,
  ActiveIcon,
  InActiveIcon,
  ChatMiniWrapper,
  AddIcon,
  TextArea,
  RefreshIcon,
  CustomInputBorder,
  HLineBreak,
  MiniInput,
  ResendIcon
}
