import {
    Background,
    CFlex, CloseIcon,
    DText,
    Flex, HLineBreak, MiniInput,
    Padding,
    TextArea
} from "../../../../styles/components/common/common-styles";
import CheckBox from "../../../common/input/CheckBox";
import Text from "../../../../core/utils/Text";
import { deepCopy } from "../../../../core/utils/common";
import Input from "../../../common/input/Input";
import TextAreaInput from "../../../common/input/TextAreaInput";
import styled from "styled-components";
import { useRef, useState } from "react";
import { TbArrowsLeftRight } from "react-icons/tb";
import { useMainContext } from "../../../../core/contexts/main";

const CheckItem = ({ name, active, onChange, disable, ...rest }) => {

    const _onChange = () => !disable && onChange()
    return (
        <Flex {...rest}>
            <CheckBox active={active} onClick={_onChange} />
            <DText primary style={{ margin: '0 8px' }}>
                <Text tid={name} />
            </DText>
        </Flex>
    )
}

const MTemplate = ({ item, onChange, disable }) => {

    const onItemChange = (type) => {
        const newValues = deepCopy(item)
        newValues[type] = !newValues[type]
        onChange('template', newValues)
    }

    return (
        <CFlex width={'180px'} style={{ margin: '8px 0' }}  >
            <Flex fw justify={'flex-start'}>
                {['text', 'file', 'pic'].map(type => (
                    <CheckItem
                        name={type}
                        active={item[type]}
                        onChange={() => onItemChange(type)}
                        disable={disable}
                    />
                ))}
            </Flex>
            <Flex fw justify={'flex-start'} style={{ margin: '12px 0' }}>
                {['audio', 'video'].map(type => (
                    <CheckItem
                        name={type}
                        active={item[type]}
                        onChange={() => onItemChange(type)}
                        disable={disable}
                    />
                ))}
            </Flex>
        </CFlex>
    )
}

const MLink = ({ item, onChange, disable }) => {

    const onItemChange = (type, payload) => {
        const newValues = deepCopy(item)
        if (type === 'link') newValues.link = payload
        else if (type === 'mentionCount') newValues.mentionCount = payload
        else newValues.options[payload] = !newValues.options[payload]
        onChange('link', newValues)
    }

    return (
        <CFlex fw align={'flex-start'}>
            <Background bg={'secondaryBg'} className={'gap-2'}>
                <Input
                    size={'small'}
                    height={'32px'}
                    width={'220px'}
                    label={'address'}
                    value={item.link}
                    onInputChange={(v) => onItemChange('link', v)}
                    disabled={disable}
                />
                <CheckItem name={'withoutLink'} disable={disable} active={item.options.withoutLink} onChange={() => onItemChange('option', 'withoutLink')} />
                <CheckItem name={'haveOtherLinks'} disable={disable} active={item.options.haveOtherLinks} onChange={() => onItemChange('option', 'haveOtherLinks')} />
            </Background>
            <div className={'w-full h-[1px] px-3 bg-slate-300 opacity-20 my-2'} />
            <Background bg={'secondaryBg'} className={'gap-2'}>
                <Input
                    size={'small'}
                    height={'32px'}
                    width={'220px'}
                    label={'mentionCount'}
                    value={item.mentionCount}
                    onInputChange={(v) => onItemChange('mentionCount', v)}
                    disabled={disable}
                />
                <CheckItem name={'manyMentions'} disable={disable} active={item.options.manyMentions} onChange={() => onItemChange('option', 'manyMentions')} />
            </Background>

            <div className={'w-full h-[1px] px-3 bg-slate-300 opacity-20 my-2'} />

            <Background bg={'secondaryBg'} className={'gap-2'}>
                <CheckItem name={'noUrl'} disable={disable} active={item.options.noUrl} onChange={() => onItemChange('option', 'noUrl')} />
                <CheckItem name={'boycottUrl'} disable={disable} active={item.options.boycottUrl} onChange={() => onItemChange('option', 'boycottUrl')} />
            </Background>


        </CFlex>
    )
}


const MAddWord = ({ item, onChange, disable }) => {

    const onItemChange = (idx, e) => {
        const newValues = deepCopy(item)
        newValues.words[idx].word = e?.target?.value
        onChange('add-word', newValues)
    }

    return (
        <CFlex align={'flex-start'}>
            <DText primary style={{ marginBottom: '6px' }}>
                <Text tid={'add-word-start'} />
            </DText>
            <TextArea
                minHeight={'92px'}
                value={item.words[0].word}
                onChange={(e) => onItemChange(0, e)}
                disabled={disable}
            />
            <DText primary style={{ margin: '6px 0' }}>
                <Text tid={'add-word-end'} />
            </DText>
            <TextArea
                minHeight={'92px'}
                value={item.words[1].word}
                onChange={(e) => onItemChange(1, e)}
                disabled={disable}
            />
        </CFlex>
    )
}

const MBoycott = ({ item, onChange, disable }) => {

    const onItemChange = (v, type) => {
        const newValues = deepCopy(item)
        if (type === 'add') newValues.words.push(v)
        if (type === 'delete') newValues.words.splice(v, 1)
        onChange('boycott', newValues)
    }

    return (
        <>
            <TextAreaInput
                values={item.words}
                onInputValueChange={onItemChange}
                dark={false}
                disabled={disable}
                style={{
                    overflowY: 'auto',
                    minHeight: '144px'
                }}
            />
        </>
    )
}

const MDeleteWord = ({ item, onChange, disable }) => {

    const onItemChange = (v, type) => {
        const newValues = deepCopy(item)
        if (type === 'add') newValues.search.push(v)
        if (type === 'delete') newValues.search.splice(v, 1)
        onChange('delete-word', newValues)
    }
    return (
        <>
            <TextAreaInput
                values={item.search}
                onInputValueChange={onItemChange}
                dark={false}
                disabled={disable}
                style={{
                    minHeight: '144px',
                    overflowY: 'auto',
                }}
            />
        </>
    )
}

const MAddressReplace = ({ item, onChange, disable }) => {

    const onItemChange = (type, value) => {
        const newValues = deepCopy(item)
        newValues[type] = value
        onChange('address-replace', newValues)
    }

    return (
        <CFlex fw align={'flex-start'}>
            <Background bg={'mainBg'}>
                <Input
                    size={'small'}
                    height={'32px'}
                    width={'220px'}
                    label={'search'}
                    value={item.search}
                    onInputChange={(v) => onItemChange('search', v)}
                    disabled={disable}
                />
            </Background>
            <Background bg={'mainBg'}>
                <Input
                    size={'small'}
                    height={'32px'}
                    width={'220px'}
                    label={'replace'}
                    value={item.replace}
                    onInputChange={(v) => onItemChange('replace', v)}
                    disabled={disable}
                />
            </Background>
        </CFlex>
    )
}

const MWhiteList = ({ item, onChange, disable }) => {

    const onItemChange = (v, type) => {
        const newValues = deepCopy(item)
        if (type === 'add') newValues.words.push(v)
        if (type === 'delete') newValues.words.splice(v, 1)
        onChange('white-list', newValues)
    }

    return (
        <>
            <TextAreaInput
                values={item.words}
                onInputValueChange={onItemChange}
                dark={false}
                disabled={disable}
                style={{
                    minHeight: '144px',
                    overflowY: 'auto',
                }}
            />
        </>
    )
}

const MReplaceWord = ({ item, onChange, disable }) => {

    const { main: { lang } } = useMainContext()
    const [search, setSearch] = useState('')
    const [replace, setReplace] = useState('')

    const searchRef = useRef()
    const replaceRef = useRef()

    const onKeyPress = (e, type) => {
        const newValues = deepCopy(item)
        if (e?.code === 'Enter') {
            if (type === 'search') {
                replaceRef.current?.focus()
            } else {
                newValues.search.push(search)
                newValues.replace.push(replace)
                onChange('replace-word', newValues)
                setSearch('')
                setReplace('')
                searchRef.current?.focus()
            }
        }
    }

    const onDelete = (idx) => {
        const newValues = deepCopy(item)
        newValues.search.splice(idx, 1)
        newValues.replace.splice(idx, 1)
        onChange('replace-word', newValues)
    }


    return (
        <Flex
            fw
            wrap
            align={'flex-start'}
            justify={'flex-start'}
            height={'170px'}
            style={{ overflowY: 'auto' }}
        >
            {item.search.map((r, idx) => (
                <Flex style={{ padding: lang === 'en' ? '0 8px 0 0' : '0 0 0 8px' }}>
                    <DText main fontSize={'s'}>
                        {idx + 1}-
                    </DText>
                    <Tag>
                        {r}
                    </Tag>
                    <TbArrowsLeftRight
                        size={16}
                        color={'#ffc800'}
                    />
                    <Tag>
                        {item.replace[idx]}
                    </Tag>
                    <CloseIcon size={14} onClick={() => !disable && onDelete(idx)} />
                    <HLineBreak style={{ height: '32px' }} />
                </Flex>
            ))}
            {!disable &&
                <Flex style={{ margin: '4px' }}>
                    <MiniInput
                        style={{ margin: '0 5px' }}
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value)}
                        onKeyPress={(e) => onKeyPress(e, 'search')}
                        ref={searchRef}
                    />
                    <TbArrowsLeftRight
                        size={16}
                        color={'#ffc800'}
                    />
                    <MiniInput
                        style={{ margin: '0 5px' }}
                        value={replace}
                        onChange={(e) => setReplace(e?.target?.value)}
                        onKeyPress={(e) => onKeyPress(e, 'replace')}
                        ref={replaceRef}
                    />
                </Flex>
            }
        </Flex>
    )
}

const MOptions = ({ item, onChange, disable }) => {

    const onItemChange = (type, v) => {
        const newValues = deepCopy(item)
        newValues[type] = v
        onChange('option', newValues)
    }


    return (
        <div className={'grid grid-cols-2 gap-x-10 gap-y-5'}>
            <div>
                <Background bg={'secondaryBg'}>
                    <Input
                        value={item.manySpaces}
                        onInputChange={(v) => onItemChange('manySpaces', v)}
                        size={'small'}
                        height={'32px'}
                        label={'manySpaces'}
                        disabled={disable}
                        code
                    />
                </Background>
            </div>
            <div>
                <Background bg={'secondaryBg'}>
                    <Input
                        value={item.spaceChange}
                        onInputChange={(v) => onItemChange('spaceChange', v)}
                        size={'small'}
                        height={'32px'}
                        label={'spaceChange'}
                        disabled={disable}
                        code
                    />
                </Background>
            </div>
            <div>
                <Background bg={'secondaryBg'}>
                    <Input
                        value={item.manyEnters}
                        onInputChange={(v) => onItemChange('manyEnters', v)}
                        size={'small'}
                        height={'32px'}
                        label={'manyEnters'}
                        disabled={disable}
                        code
                    />
                </Background>
            </div>
            <div>
                <Background bg={'secondaryBg'}>
                    <Input
                        value={item.enterChange}
                        onInputChange={(v) => onItemChange('enterChange', v)}
                        size={'small'}
                        height={'32px'}
                        label={'enterChange'}
                        disabled={disable}
                        code
                    />
                </Background>
            </div>
        </div>
    )
}

const Tag = styled.div`
  background-color: ${props => props.dark ? props.theme.mainBg : props.theme.primaryBg};
  border-radius: 4px;
  font-size: 0.75rem;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding: 0 12px;
  border: 1px solid ${props => props.theme.primary}20;
  margin: 4px 8px;
`

export {
    MTemplate,
    MLink,
    MReplaceWord,
    MBoycott,
    MDeleteWord,
    MAddWord,
    MAddressReplace,
    MWhiteList,
    MOptions
}
