import { useEffect, useState } from "react";
import { CFlex, CloseIcon, DText, Flex } from "../../styles/components/common/common-styles";
import EChart from "./EChart";
import { useGetCCounter } from "../../core/services/react-query/dashboard";
import { useMainContext } from "../../core/contexts/main";
import Text from "../../core/utils/Text";
import DateFilter from "../common/date/DateFilter";
import SingleSelect from "../common/select/SingleSelect";
import { useGetAllSims } from "../../core/services/react-query/sim";
import { useSimChannelsQuery } from "../../core/services/react-query/channel";
import EitaaSelect from "../common/select/EitaaSelect";
import { DeleteFilters } from "../../styles/components/dashboard";
import Tooltip from "../common/utils/Tooltip";


const CCounterChart = () => {

    const { main: { theme } } = useMainContext()
    const { data: channels, refetch: getAllChannels } = useSimChannelsQuery('all')
    const { data: sims } = useGetAllSims()

    /**
     * chart states
     */
    const [dates, setDates] = useState([])
    const [bars, setBars] = useState([])

    /**
     * params
     */
    const [mode, setMode] = useState('monthly')
    const [calendarType, setCalendarType] = useState('shamsi')
    const [filterDate, setFilterDate] = useState((new Date()).setUTCHours(20, 30, 0))
    const [channel, setChannel] = useState(null)
    const [sim, setSim] = useState(null)
    const [eitaa, setEitaa] = useState('')

    const [params, setParams] = useState({})

    useEffect(() => {
        const newParams = {
            mode, date: new Date(filterDate),
            tz: calendarType === 'shamsi' ? 'Asia/Tehran' : 'UTC'
        }
        if (eitaa) newParams.eitaa = eitaa
        if (channel) newParams.channel = channel._id
        if (sim) newParams.simId = sim._id
        setParams(newParams)
    }, [mode, calendarType, filterDate, channel, sim, eitaa])

    const { data: ccounter } = useGetCCounter(params)

    const onParamsChange = (v, type) => {
        switch (type) {
            case 'mode':
                setMode(v)
                break
            case 'date':
                const date = new Date(v)
                date.setUTCHours(24, 0, 0, 0)
                setFilterDate(date)
                break
            case 'calendarType':
                setCalendarType(v)
                break
            default:
                break
        }
    }

    useEffect(() => {
        getAllChannels()
    }, [])

    useEffect(() => {
        if (ccounter) {
            // setting dates
            const formattedDates = ccounter.dates.map(d => d)
            setDates(formattedDates)

            //setting bars
            const formattedBars = []
            ccounter.bars.forEach((item, idx) => {
                const today = new Date()
                // TODO: find today
                // const faToday = new Date(p2e(today.toLocaleDateString('fa-IR')))

                today.setUTCHours(0, 0, 0, 0)
                if (new Date(ccounter.dates[idx]).getTime() === today.getTime()) {
                    formattedBars.push({
                        value: item,
                        itemStyle: {
                            color: '#15A865'
                        }
                    })
                } else
                    formattedBars.push(item)
            })
            setBars(ccounter.bars)
        }
    }, [ccounter])

    const option = {
        xAxis: {
            data: dates
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '20%'],
            splitLine: {
                lineStyle: {
                    color: theme === 'dark' ? '#c3c5b720' : '#00000020',
                    type: 'dashed'
                }
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        dataZoom: [
            {
                type: 'inside',
                start: mode === 'yearly' ? 75 : 0,
                end: 100
            },
            {
                show: true,
                type: 'slider',
                top: '90%',
                start: mode === 'yearly' ? 75 : 0,
                end: 100
            }
        ],
        series: [
            {
                data: bars,
                type: 'bar',
                color: [
                    '#A0B7AC'
                ]
            }
        ]
    };

    const deleteAllFilters = () => {
        setSim(null)
        setEitaa('')
        setChannel(null)
    }

    return (
        <CFlex fw fh justify={'space-between'}>
            <Flex fw>
                <DText primary cFontSize={'15px'} style={{ fontWeight: 600 }}>
                    <Text tid={'c2c-count'} />
                </DText>
                {(!!sim || !!channel || !!eitaa) &&
                    <DeleteFilters onClick={deleteAllFilters}>
                        <Tooltip
                            content={'delete-all-filters'}
                        >
                            <div>
                                <CloseIcon size={18} />
                            </div>
                        </Tooltip>
                    </DeleteFilters>
                }
            </Flex>
            <Flex fw justify={'space-between'}>
                <Flex width={'50%'}>
                    <DateFilter
                        mode={mode}
                        date={filterDate}
                        calendarType={calendarType}
                        onParamsChange={onParamsChange}
                        noLabel
                    />
                </Flex>
                <Flex width={'50%'} justify={'flex-end'}>
                    <EitaaSelect
                        onChange={(v) => setEitaa(v)}
                        value={eitaa}
                    />
                    <SingleSelect
                        value={channel}
                        onValueChange={(g) => setChannel(g)}
                        placeHolder='filter-by-channel'
                        options={channels || []}
                        type={'chat'}
                        param='address'
                        style={{ margin: '0 6px' }}
                        noLabel
                    />
                    <SingleSelect
                        value={sim}
                        onValueChange={(s) => setSim(s)}
                        placeHolder='filter-by-sim'
                        options={sims || []}
                        type={'sim'}
                        param='username'
                        noLabel
                    />
                </Flex>
            </Flex>
            <Flex fw height={'calc(100% - 80px)'}>
                <EChart option={option} style={{ width: '100%', height: '100%' }} />
            </Flex>
        </CFlex>
    )
}

export default CCounterChart
