import { useState } from "react";
import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { CacheKeys, extractMemberOperationFilterOptions, memberFilterOptions } from "../../core/constants/filter";
import { useGetAllMembers, useGetExtractOperations } from "../../core/services/react-query/member";
import MembersComp from "../../components/main/member/members/MembersComp";
import Text from "../../core/utils/Text";
import { DText } from "../../styles/components/common/common-styles";
import ExtractMembersComp from "../../components/main/member/extract-operations/ExtractMembersComp";
import ModalLayout from "../../components/layouts/main/ModalLayout";
import ExtractMembersModal from "../../components/main/member/extract-operations/ExtractMembersModal";
import { useSearchParams } from "react-router-dom";
import Tabs from "../../components/common/select/Tabs";
import SendMessageToMemebersModal from "../../components/main/member/send-message/SendMessageToMemebersModal";


const Members = () => {

    const [searchParams, setSearchParams] = useSearchParams()
    const tabs = ['members-list', 'extract-list']
    const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'members-list')
    const onTabChange = (t) => {
        setActiveTab(t)
        setSearchParams({ tab: t })
    }

    const [modal, setModal] = useState(null)
    const closeModal = () => setModal(null)

    const [members, setMembers] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setMembers(res)

    const [extractOperations, setExtractOperations] = useState({ data: null, loading: true })
    const onExtractQuerySuccess = res => setExtractOperations(res)

    return (
        <>
            <CardLayout>
                <Tabs
                    tabs={tabs}
                    active={activeTab}
                    onChange={onTabChange}
                />
                {activeTab === 'members-list' ?
                    <FilterLayout
                        query={useGetAllMembers}
                        cache={CacheKeys.MEMBERS}
                        onQuerySuccess={onQuerySuccess}
                        options={memberFilterOptions}
                    >
                        <MembersComp
                            data={members}
                        />
                    </FilterLayout>
                    : null}

                {activeTab === 'extract-list' ?
                    <FilterLayout
                        query={useGetExtractOperations}
                        cache={CacheKeys.EXTRACT_MEMBER_OPERATIONS}
                        onQuerySuccess={onExtractQuerySuccess}
                        options={extractMemberOperationFilterOptions}
                    >
                        <ExtractMembersComp
                            data={extractOperations}
                        />
                    </FilterLayout>
                    : null}

            </CardLayout>

            <div className={'absolute left-3 top-3 flex items-center justify-center gap-4'}>
                <div
                    className={'bg-active text-white rounded-md py-2 px-5 text-sm cursor-pointer'}
                    onClick={() => setModal('extract-members')}
                >
                    <Text tid={'extract-members'} />
                </div>
                <div
                    className={'bg-active text-white rounded-md py-2 px-5 text-sm cursor-pointer'}
                    onClick={() => setModal('send-message')}
                >
                    <Text tid={'send-message'} />
                </div>
            </div>

            <ModalLayout
                open={modal === 'extract-members'}
                isStatic={true}
                width={'520px'}
            >
                <ExtractMembersModal
                    onClose={closeModal}
                />
            </ModalLayout>

            <ModalLayout
                open={modal === 'send-message'}
                isStatic={true}
                width={'600px'}
            >
                <SendMessageToMemebersModal
                    onClose={closeModal}
                />
            </ModalLayout>
        </>
    )
}

export default Members