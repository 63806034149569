import {useParams} from "react-router-dom";
import {useGetOperation, useUpdateOperation} from "../../../../core/services/react-query/operation";
import DetailLayout from "../../../layouts/main/DetailLayout";
import useDetailsLayout from "../../../../core/hooks/layout/useDetailsLayout";
import OperationDetailsShow from "./OperationDetailsShow";
import OperationDetailsEdit from "./OperationDetailsEdit";
import {useEffect, useState} from "react";
import {deepCopy} from "../../../../core/utils/common";
import {useQueryContext} from "../../../../core/contexts/query";



const OperationDetails = () => {

    const params = useParams()

    const { setToast } = useQueryContext()

    const { mutate: updateOperation, isLoading: updating, isSuccess: updateSuccess } = useUpdateOperation()

    const [sim, setSim] = useState(null)
    const initial = {
        name: '',
        active: false,
        groups: [],
        channels: [],
        delay: {}
    }
    const [operationData, setOperationData] = useState(initial)
    const { data: operation, refetch: resetOperation } = useGetOperation(params.id)

    const resetData = () => {
        setSim(deepCopy(operation.sim_id))
        setOperationData(deepCopy(operation))
    }

    useEffect(() => {
        if (operation) resetData()
    }, [operation])

    const {
        mode,
        exitEditMode,
        enterEditMode
    } = useDetailsLayout(resetData)

    const Detail = mode === 'show' ? OperationDetailsShow : OperationDetailsEdit

    const readyToUpdate = (before, after) => {
        return JSON.stringify(before) !== JSON.stringify(after)
    }

    const onSubmitChanges = () => {
        if (!operation) {
            setToast({
                isError: true, show: true,
                message: 'something-went-wrong'
            })
            return
        }

        let needUpdate = false
        let payload = {
            operation_id: operation._id
        }

        if (operation.name !== operationData.name) {
            payload.name = operationData.name
            needUpdate = true
        }

        /**
         * check sim card change
         */
        if (sim?.username !== operation.sim_id?.username) {
            payload.sim_id = sim._id
            needUpdate = true
        }

        if (readyToUpdate(operation.groups, operationData.groups)) {
            payload.groups = operationData.groups.map(item => item._id)
            needUpdate = true
        }

        if (readyToUpdate(operation.channels, operationData.channels)) {
            payload.channels = operationData.channels.map(item => item._id)
            needUpdate = true
        }

        if (readyToUpdate(operation.delay, operationData.delay)) {
            payload.delay = operationData.delay
            needUpdate = true
        }

        if (operation.active !== operationData.active) {
            payload.active = operationData.active
            needUpdate = true
        }

        if (needUpdate) {
            updateOperation(payload)
        }else {
            setToast({
                isError: true, show: true,
                message: 'nothing-to-update'
            })
        }

    }

    const onOperationDetailChange = (v, type) => {
        if (type === 'sim_id') {
            setSim(v)
        }
        else {
            const newData = deepCopy(operationData)
            newData[type] = v
            setOperationData(newData)
        }
    }


    useEffect(() => {
        if (sim) {
            if (sim?.username !== operation.sim_id?.username) {
                const newData = deepCopy(operationData)
                newData.groups = []
                newData.channels = []
                setOperationData(newData)
            }
        }
    }, [sim])

    useEffect(() => {
        if (updateSuccess) {
            exitEditMode()
            resetOperation()
        }
    }, [updateSuccess])


    return (
        <>
            <DetailLayout
                mode={mode}
                exitEditMode={exitEditMode}
                enterEditMode={enterEditMode}
                loading={updating}
                onSubmitChanges={onSubmitChanges}
            >
                <Detail
                    sim={sim}
                    operation={operationData}
                    onOperationDetailChange={onOperationDetailChange}
                />
            </DetailLayout>
        </>
    )

}


export default OperationDetails
