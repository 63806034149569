import Text from "../../../core/utils/Text";
import {useEffect, useRef, useState} from "react";
import {CloseIcon, OpenIcon} from "../../../styles/components/common/select-styles";
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import {ItemWrapper} from "../../../styles/layouts/filter";
import {Flex} from "../../../styles/components/common/common-styles";
import FilterBox from "./FilterBox";


const FilterItem = (props) => {

    const { prefix, suffix, options } = props
    const filter = options.find(item => item.search === prefix)

    const ref = useRef()

    const [filterItem, setFilterItem] = useState({name: '', search: ''})

    useEffect(() => {
        switch (prefix) {
            case 'firstName':
                setFilterItem({name: 'firstName', search: 'name'})
                break
            case 'lastName':
                setFilterItem({name: 'lastName', search: 'name'})
                break
            case 'dateFrom':
                setFilterItem({name: 'dateFrom', search: 'date'})
                break
            case 'dateTo':
                setFilterItem({name: 'dateTo', search: 'date'})
                break
            default:
                setFilterItem(filter)
        }
    }, [prefix])


    const [open, setOpen] = useState(false)
    useClickOutside(ref, () => setOpen(false))
    const onClose = () => setOpen(false)

    if (prefix === 'defaults') {
        return  <></>
    }

    return (
        <ItemWrapper
            ref={ref}
        >
            <Flex
                onClick={() => setOpen(true)}
                style={{ cursor: 'pointer' }}
            >
                <Text tid={filterItem.name} /> :
                <Text style={{margin: '0 2px'}} tid={suffix} />
                {open ?
                    <CloseIcon size={22} />
                    :
                    <OpenIcon size={22} />
                }
            </Flex>

            {open &&
                <FilterBox
                    type={'update'}
                    filter={filterItem}
                    onClose={onClose}
                />
            }
        </ItemWrapper>
    )
}

export default FilterItem
