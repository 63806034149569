import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'
import { FiMoreVertical } from 'react-icons/fi'

const TableWrapper = styled(motion.div)`
  width: 100%;
  overflow-x: auto;
  border: 2px solid ${props => props.theme.color}20;
  border-radius: 8px;
  box-shadow: 0 0 2px ${props => props.theme.shadow};
`

const TableBody = styled.div`
  width: 100%;
  display: inline-block;
  padding: 10px 15px;
  min-width: ${props => props.minWidth || 1000}px;
`

const ContentWrapper = styled.div`
  min-height: 400px;
  max-height: 50vh;
  overflow-y: auto;
  position: relative;
  padding: 0 4px;
  scrollbar-width: thin;
  scrollbar-color: #5e6673;
`

const rowColors = {
  active: '#1ce087',
  disabled: '#e9106c',
  done: '#1ce087',
  error: '#e9106c',
  paused: '#ffc800',
  progress: '#2463ea',
  inactive: '#9ba7c1'
}
const Row = styled.div`
  margin: 4px 0;
  border-bottom: 0;
  display: grid;
  grid-template-columns: ${props => props.cs};
  row-gap: 5px;
  color: ${props => props.theme.color};
  padding: ${props => props.padding || '15px 10px'};
  border-radius: 4px;
  font-size: 0.75rem;
  align-items: center;
  transition: all 0.3s;
  width: 100%;
  position: relative;

  ${props => props.action && css`
    background-image: 
            linear-gradient(
                    45deg, 
                    ${({ action }) => rowColors[action]}10 20%, 
                    ${props => props.theme.table} 60%
            );
  `};
  
  background-color: ${props => props.index % 2 ? props.theme.secondaryBg : props.theme.primaryBg};

  ${props => props.header && css`
    background-color: transparent;
  `};

  ${props => props.hasParty && css`
    background-image:
        linear-gradient(
            45deg,
            #819F8230 0%,
            ${props => props.index % 2 ? props.theme.secondaryBg : props.theme.primaryBg} 25%
        );
  `};
  
  ${props => !props.header && css`
    min-height: 64px;
  `};
  
  ${props => props.currentOrder && css`
    &::after{
      content: ' ';
      position: absolute;
      width: ${props => props.percent && props.percent}%;
      height: 100%;
      
      ${props => props.theme.english && css`
        left: 0;
      `};
      ${props => !props.theme.english && css`
        right: 0;
      `};

      bottom: 0;
      background-color: ${props => props.type === 'buy' ? props.theme.mainGreen : props.theme.mainRed}10;
      /* background-color: ${props => props.theme.mainGreen}15; */
      border-radius: 4px;

      @media screen and (max-width: 1050px) {
        height: ${props => props.percent && props.percent}%;
        width: 100%;
      };
    };
  `};
  
  ${props => !props.noHover && css`
    &:hover{
      ${props => !props.header && css`
      background-color: ${props => props.theme.tInputBg};
      transform: scale(1.02);
      
      @media screen and (max-width: 1050px) {
        transform: scale(1);
      };
    `};
  `};
  };
`

const Column = styled.div`
  padding: 0 4px;
  display: flex;
  position: relative;
  word-wrap: anywhere;
  
  ${props => props.center && css`
    justify-content: center;
  `};
  
  ${props => props.more && css`
    justify-content: flex-end;
    align-items: center;
  `};
`


const DateBadge = styled.div`
  border-radius: 12px;
  border: 1px solid ${props => props.theme.color}15;
  padding: 4px 20px;
  background-color: ${props => props.theme.mainBg};
  color: ${props => props.theme.primary};
  box-shadow: 0 0 2px ${props => props.theme.shadow};
`

const DetailsLtr = styled(IoIosArrowForward)`
  color: ${props => props.theme.primary};
`

const DetailsRtl = styled(IoIosArrowBack)`
  color: ${props => props.theme.primary};
`

const MoreIcon = styled(FiMoreVertical)`
  color: ${props => props.theme.primary};
`


export {
  TableWrapper,
  TableBody,
  ContentWrapper,
  Row,
  Column,
  DateBadge,
  DetailsLtr,
  DetailsRtl,
  MoreIcon
}
