import { Background, CFlex, CMargin, DText, Flex, LineBreak } from "../../../../styles/components/common/common-styles";
import Text from "../../../../core/utils/Text";
import Input from "../../../common/input/Input";
import CheckBox from "../../../common/input/CheckBox";
import ActionButton from "../../../common/buttons/ActionButton";
import { CREATE_C2C } from "./CreateC2c";
import MultiSelect from "../../../common/select/MultiSelect";
import { useGetAllUsers } from "../../../../core/services/react-query/setting";
import { useEffect, useState } from "react";
import LocalTimePicker from "../../../common/date/TimePicker";


const Step4 = ({ inputData, onInputValueChange, onCreateC2c, loading, mode }) => {

    const { data: users } = useGetAllUsers()
    const { hasDelay, delay } = inputData

    const [valid, setValid] = useState({})
    useEffect(() => {
        const newValid = {
            sources: inputData.sources.length,
            users: inputData.users.length,
            eitaa: !inputData.hasEitaa || (!!inputData.eitaaDestination && inputData.eitaaYar),
            sim: !!inputData.sim,
            name: !!inputData.name
        }
        setValid(newValid)
    }, [inputData])


    return (
        <>
            <DText primary>
                <Text tid={'delay'} />
            </DText>
            <LineBreak style={{ width: '500px' }} />
            <CMargin margin={'6px'} />
            <Flex>
                <CheckBox
                    active={hasDelay}
                    onClick={() => onInputValueChange(null, CREATE_C2C.HAS_DELAY)}
                />
                <DText primary style={{ margin: '0 8px' }}>
                    <Text tid={'has-delay'} />
                </DText>
            </Flex>
            <CMargin margin={'8px'} />
            <Flex width={'500px'} justify={'space-between'}>
                <Background bg={'primaryBg'}>
                    <Input
                        value={delay.begin}
                        onInputChange={(v) => onInputValueChange(v, 'begin')}
                        label={'min'}
                        width={'30%'}
                        size={'small'}
                        code
                    />
                    <Input
                        value={delay.end}
                        onInputChange={(v) => onInputValueChange(v, 'end')}
                        label={'max'}
                        width={'30%'}
                        size={'small'}
                        code
                    />
                    <Input
                        value={delay.delay}
                        onInputChange={(v) => onInputValueChange(v, 'delay')}
                        label={'delay-time'}
                        width={'30%'}
                        size={'small'}
                        code
                    />
                </Background>
            </Flex>
            <Flex width={'500px'} justify={'space-between'}>
                <Background bg={'primaryBg'}>
                    <CFlex align={'flex-start'}>
                        <DText main margin={'0 0 10px 0'}>
                            <Text tid={'min-day'} /> :
                        </DText>
                        <LocalTimePicker
                            time={delay.min}
                            onChange={(v) => onInputValueChange(v, 'min')}
                        />
                    </CFlex>

                    <CFlex align={'flex-start'}>
                        <DText main margin={'0 0 10px 0'}>
                            <Text tid={'max-day'} /> :
                        </DText>
                        <LocalTimePicker
                            time={delay.max}
                            onChange={(v) => onInputValueChange(v, 'max')}
                        />
                    </CFlex>
                </Background>
            </Flex>
            <CMargin margin={'12px'} />
            <DText primary>
                <Text tid={'users'} />
            </DText>
            <LineBreak style={{ width: '500px' }} />

            <MultiSelect
                values={inputData.users}
                options={users || []}
                type={'user'}
                param={'email'}
                placeHolder={`choose-users`}
                onValuesChange={(selectedOptions) => onInputValueChange(selectedOptions, 'users')}
                width={'500px'}
                noLabel
            />

            <CMargin margin={'12px'} />
            <ActionButton active={Object.values(valid).every(Boolean)} width={'500px'} height={'42px'} loading={loading} onClick={() => onCreateC2c(valid)}>
                <Text tid={`${mode}-c2c`} />
            </ActionButton>
        </>
    )
}


export default Step4
