import {
    HeaderWrapper, LayoutSettingIcon,
} from "../../../styles/layouts/main-styles";
import { DText, Flex } from "../../../styles/components/common/common-styles";
import { useMainContext } from "../../../core/contexts/main";
import { useEffect, useRef, useState } from "react";
import Text from "../../../core/utils/Text";
import Select from "../../common/select/Select";
import { FaDownload } from "react-icons/fa";
import { fileDownloader } from "../../../core/utils/common";



const MainHeader = ({ onLayoutSetting }) => {

    const lsRef = useRef()
    const { main: { lang, setLang } } = useMainContext()

    const [language, setLanguage] = useState(lang)

    useEffect(() => {
        if (language !== lang) setLang(language)
    }, [language])


    const onLangChange = (idx) => {
        setLanguage(idx === 0 ? 'en' : 'fa')
    }

    return (
        <HeaderWrapper>
            <Flex fw fh justify='space-between'>
                <Flex>
                    <img
                        src={require('../../../assets/images/logo.png')}
                        alt=' '
                        width="52px"
                    />
                    <DText style={{ margin: '0 4px', fontWeight: 600, letterSpacing: '1.4px' }} cFontSize={'1.2rem'} color={'#3B6978'}>
                        <Text tid={'rayna'} />
                    </DText>
                </Flex>

                <Flex>
                    <a href={"https://rynet.ir/app.apk"} download="app.apk">
                        <div className={'rounded-md bg-active py-2 cursor-pointer px-4 flex items-center gap-2 text-white text-sm mx-2'}>
                            <FaDownload size={16} />
                            <Text tid={'download-app'} />
                        </div>
                    </a>

                    <Select
                        width={'90px'}
                        options={languages}
                        value={langValues[language]}
                        onValueChange={onLangChange}
                        dropdownWidth='100px'
                        size='small'
                        height='32px'
                        noLabel
                    />
                    <Flex ref={lsRef} style={{ margin: '0 8px' }}>
                        <LayoutSettingIcon size={20} onClick={() => onLayoutSetting(lsRef)} />
                    </Flex>
                </Flex>
            </Flex>
        </HeaderWrapper>
    )
}

const pallets = [
    'default', 'dark-sky',
    'violet-owl', 'darker',
    'smooth'
]

const languages = [
    'english',
    'فارسی'
]

const langValues = {
    en: 'english',
    fa: 'فارسی'
}

export default MainHeader;
