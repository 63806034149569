import styled from "styled-components";
import {CFlex} from "../components/common/common-styles";


export const SigninWrapper = styled(CFlex)`
  align-items: flex-start;
  background-color: ${props => props.theme.mainBg};
  border-radius: 12px;
  
  @media screen and (max-width: 1050px) {
    align-items: center;
  }
`

export const IllustrationWrapper = styled.div`
  background-color: ${props => props.theme.mainBg};
  width: 50%;
  height: calc(100vh + 28px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${props => props.theme.active}60;
  box-shadow: 0 0 4px ${props => props.theme.secondaryBg};
`
