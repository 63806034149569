import {
    CFlex,
    CMargin,
    DText,
    Flex,
    NormalBtn,
    LineBreak,
    Decoration
} from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import { FiAlertTriangle } from 'react-icons/fi'



const DeleteChatModal = ({
    chat,
    onSubmit,
    onCancel,
    type
}) => {

    return (
        <CFlex fw align="flex-start">
            <Flex fw justify='space-between'>
                <DText primary fontSize='sb'>
                    <Text tid={`delete-${type}`} />
                </DText>
                <FiAlertTriangle color='#ffc800' size={32} />
            </Flex>
            <CMargin margin='4px' />
            <LineBreak />
            <CMargin margin='8px' />
            <DText main>
                <Text tid={`delete-${type}-question`} />
            </DText>

            <CMargin margin='4px' />
            <Flex>
                <Decoration />
                <DText main>
                    <Text tid={'in-operation'} /> :
                </DText>
                <DText primary>
                    {chat?.operations?.length}
                </DText>
            </Flex>

            <CMargin margin='2px' />
            {type === 'group' &&
                <Flex>
                    <Decoration />
                    <DText main>
                        <Text tid={'in-normalizer'} /> :
                    </DText>
                    <DText primary>
                        {chat?.normalizers?.length}
                    </DText>
                </Flex>
            }

            <CMargin margin='20px' />
            <Flex fw justify='flex-end'>
                <NormalBtn active onClick={onSubmit}>
                    <Flex fw fh>
                        <Text tid={'submit'} />
                    </Flex>
                </NormalBtn>

                <NormalBtn onClick={onCancel}>
                    <Flex fw fh>
                        <Text tid={'cancel'} />
                    </Flex>
                </NormalBtn>
            </Flex>
        </CFlex>
    )
}


export default DeleteChatModal
