import { useEffect, useMemo, useState } from "react"
import { CloseIcon, IconWrapper } from "../../../../styles/components/common/common-styles"
import { useGetCategoryType } from "../../../../core/services/react-query/category"
import SendPvStage1 from "./SendPvStage1"
import ActionButton from "../../../common/buttons/ActionButton"
import ButtonLoader from "../../../common/loading/ButtonLoader"
import { useGetTotalMemebersByCategories } from "../../../../core/services/react-query/member"
import SendPvStage2 from "./SendPvStage2"
import SendPvStage3 from "./SendPvStage3"


const SendMessageToMemebersModal = ({ onClose }) => {

    const { data: categoryOptions } = useGetCategoryType('member')

    const [stage, setStage] = useState(1)
    const [categories, setCategories] = useState([])

    const [input, setInput] = useState({
        body: '',
        source_address: '',
        message_id: '',
        activeTab: 'send-text',
        sims: [],
        operation_length: 1,
        message_per_send: 0,
        delay_per_send: 1,
        maximum_message: 0,
        date: ''

    })
    const onInputChange = (v, t) => {
        setInput(s => ({ ...s, [t]: v }))
    }

    const { data: totalByCategories, mutate: getTotalMembers } =
        useGetTotalMemebersByCategories({
            categories: categories?.map(x => x._id)
        })
    const totalMembers = useMemo(() => {
        return totalByCategories?.data?.data?.total || 0
    }, [totalByCategories])

    useEffect(() => {
        if (categories?.length) getTotalMembers({ categories: categories.map(c => c._id) })
    }, [categories, getTotalMembers])


    const activeAction = useMemo(() => {
        if (stage === 1) {
            return categories?.length &&
                (
                    (input.activeTab === 'send-text' && !!input.body) ||
                    (input.activeTab !== 'send-text' && !!input.message_id && !!input.source_address)
                )
        }
        if (stage === 2) {
            return input.sims?.length && input.message_per_send
        }
        if (stage === 3) {
            return !!input.date
        }
    }, [input, stage, categories])


    const onSubmit = () => {
        if (stage === 1) setStage(2)
        if (stage === 2) setStage(3)
        if (stage === 3) {

        }
    }

    return (
        <div className={'relative'}>
            <div className={'absolute left-[-10px] top-[-20px]'}>
                <IconWrapper onClick={onClose}>
                    <CloseIcon />
                </IconWrapper>
            </div>

            {stage === 1 ?
                <SendPvStage1
                    categories={categories}
                    setCategories={setCategories}
                    categoryOptions={categoryOptions}
                    input={input}
                    onInputChange={onInputChange}
                    totalMembers={totalMembers}
                />
                : null}

            {stage === 2 ?
                <SendPvStage2
                    categories={categories}
                    totalMembers={totalMembers}
                    input={input}
                    onInputChange={onInputChange}
                />
                : null}

            {stage === 3 ?
                <SendPvStage3
                    input={input}
                    onInputChange={onInputChange}
                    totalMembers={totalMembers}
                    categories={categories}
                />
                : null}

            <ActionButton
                active={activeAction}
                className={'mt-8'}
                onClick={onSubmit}
            >
                <ButtonLoader tid={
                    stage < 3 ? 'next'
                        : 'submit'
                } />
            </ActionButton>
        </div>
    )
}

export default SendMessageToMemebersModal