import {CMargin} from "../../styles/components/common/common-styles";
import Input from "../../components/common/input/Input";
import {SigninWrapper} from "../../styles/pages/register-signin";
import ActionButton from "../../components/common/buttons/ActionButton";
import ButtonLoader from "../../components/common/loading/ButtonLoader";
import {useAuth} from "../../core/hooks/main/auth/useAuth";


const RegisterSignin = () => {

    const {
        onInputValueChange, EMAIL,
        inputData, inputStatus,
        PASSWORD, OTP, isLoading,
        step, otp, verifyingOtp,
        onSubmitClicked, valid,
    } = useAuth()

    return(
        <>
            <SigninWrapper>
                <CMargin margin="4px" />
                <Input
                    onInputChange={(value) => onInputValueChange(value, EMAIL)}
                    value={inputData.email}
                    height={'42px'}
                    label={EMAIL}
                    valid={inputStatus.email}
                    error={'email-error'}
                    direction='ltr'
                />
                <CMargin margin="4px" />
                <Input
                    onInputChange={(value) => onInputValueChange(value, PASSWORD)}
                    value={inputData.password}
                    label={PASSWORD}
                    height={'42px'}
                    valid={inputStatus.password}
                    error={'password-error'}
                    direction='ltr'
                    password
                />
                { step === 2 &&
                    <>
                        <CMargin margin="4px" />
                        <Input
                            onInputChange={(value) => onInputValueChange(value, OTP)}
                            value={otp}
                            label={'otp'}
                            valid={otp.length === 6}
                            height={'42px'}
                            error={'otp-error'}
                            direction='ltr'
                            password
                            code
                        />
                    </>

                }
                <CMargin margin="10px" />
                <ActionButton
                    onClick={onSubmitClicked}
                    active={valid}
                    height={'42px'}
                >
                    <ButtonLoader
                        loading={verifyingOtp || isLoading}
                        tid='login'
                    />
                </ActionButton>
            </SigninWrapper>
        </>
    )
}


export default RegisterSignin;
