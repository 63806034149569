import {CheckIcon} from "../../../styles/components/category/category-styles";
import {CloseIcon} from "../../../styles/components/common/common-styles";


export const BooleanIcon = ({bool, size= 20}) => {
    return (
        <>
            {bool ? <CheckIcon size={size} /> : <CloseIcon color={'#e9106c'} size={size} />}
        </>
    )
}
