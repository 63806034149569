import {
    Background,
    CFlex,
    CMargin,
    DText,
    LineBreak,
    Padding
} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Input from "../../common/input/Input";
import { useEffect, useState } from "react";
import { useGetAllRoles, useGetWorkspaceRoles, useGetWorkspaces } from "../../../core/services/react-query/setting";
import SingleSelect from "../../common/select/SingleSelect";
import ActionButton from "../../common/buttons/ActionButton";
import { useProfileQuery, useRegisterUser, useUpdateUser } from "../../../core/services/react-query/auth";
import { deepCopy } from "../../../core/utils/common";
import { useMainContext } from "../../../core/contexts/main";
import { useQueryContext } from "../../../core/contexts/query";


const CreateUserModal = ({ onClose, type, user }) => {

    const { setToast } = useQueryContext()
    const { data: workspaces } = useGetWorkspaces()
    const { mutate: registerUser, creatingUser } = useRegisterUser(onClose)
    const { mutate: updateUser, isLoading: updatingUser } = useUpdateUser(onClose)
    const { data: profile } = useProfileQuery()

    const { profile: { isSuperAdmin } } = useMainContext()

    const [userWorkspace, setUserWorkspace] = useState()
    const [validAction, setValidAction] = useState(false)
    const [input, setInput] = useState({
        username: '', email: '', password: '', role: ''
    })

    useEffect(() => {
        if (type === 'edit') {
            setInput(user)
        }
    }, [type, user])

    useEffect(() => {
        if (profile) setUserWorkspace(profile.workspace)
    }, [profile])

    const { data: roles, refetch: getWorkspaceRoles } = useGetWorkspaceRoles((isSuperAdmin && type === 'create') ? userWorkspace?.name : userWorkspace)
    useEffect(() => {
        if (!!userWorkspace) getWorkspaceRoles()
    }, [userWorkspace])

    useEffect(() => {
        if (!!roles && !!user && type === 'edit') {
            const userRole = roles.find(r => r._id === user.role)
            if (userRole) {
                setInput(state => ({
                    ...state,
                    role: userRole
                }))
            }
        }
    }, [roles, user])


    useEffect(() => {
        const valid =
            input.username &&
            input.email &&
            input.password &&
            input.role
        setValidAction(valid)
    }, [input])

    const onInputChange = (value, key) => {
        setInput(state => ({ ...state, [key]: value }))
    }

    const onRegisterUser = () => {
        if (validAction) {
            const payload = deepCopy(input)
            payload.role = payload.role?._id
            if (userWorkspace) payload.userWorkspace = userWorkspace.name
            if (type === 'edit') updateUser(payload)
            else registerUser(payload)
        } else {
            setToast({
                isError: true, show: true,
                message: 'fill-inputs-error'
            })
        }
    }


    return (
        <CFlex fw align={'flex-start'} transparent>
            <DText primary fontSize='b'>
                <Text tid={`create-user`} />
            </DText>
            <CMargin margin='4px' />
            <LineBreak />
            <CMargin margin='6px' />
            <Background bg={'mainBg'}>
                <Input
                    value={input.username}
                    onInputChange={(v) => onInputChange(v, 'username')}
                    height={'32px'}
                    size={'small'}
                    label={'username'}
                />
                <Input
                    value={input.email}
                    onInputChange={(v) => onInputChange(v, 'email')}
                    height={'32px'}
                    size={'small'}
                    label={'email'}
                />
                <Input
                    value={input.password}
                    onInputChange={(v) => onInputChange(v, 'password')}
                    height={'32px'}
                    size={'small'}
                    label={'password'}
                />
                <CMargin margin={'6px'} />
                {(isSuperAdmin && type === 'create') &&
                    <Padding padding={'0 6px'}>
                        <DText primary>
                            <Text tid={'userWorkspace'} />:
                        </DText>
                        <CMargin margin={'3px'} />
                        <SingleSelect
                            style={{ width: '100%', height: '42px', justifyContent: 'flex-start' }}
                            options={workspaces?.data || []}
                            value={userWorkspace}
                            onValueChange={v => setUserWorkspace(v)}
                            placeHolder='userWorkspace'
                            param={'name'}
                            type={'role'}
                            noLabel
                        />
                    </Padding>
                }
                <CMargin margin={'6px'} />
                {(!!userWorkspace || (!isSuperAdmin)) &&
                    <CFlex fw align={'flex-start'}>
                        <Padding padding={'0 6px'}>
                            <DText primary>
                                <Text tid={'role'} />:
                            </DText>
                            <CMargin margin={'3px'} />
                            <SingleSelect
                                style={{ width: '100%', height: '42px', justifyContent: 'flex-start' }}
                                options={roles || []}
                                value={input.role}
                                onValueChange={(v) => onInputChange(v, 'role')}
                                placeHolder='role'
                                param={'name'}
                                type={'role'}
                                noLabel
                            />

                        </Padding>
                    </CFlex>
                }
                <CMargin margin={'12px'} />
                <ActionButton
                    active={validAction}
                    loading={creatingUser || updatingUser}
                    onClick={onRegisterUser}
                    height={'42px'}
                >
                    <Text tid={'submit'} />
                </ActionButton>
            </Background>

        </CFlex>
    )
}

export default CreateUserModal
