import { MessageRow, NormalizerMessageWrapper, Tag } from "../../../styles/components/normalizer";
import {
    AddIcon,
    DeleteIcon,
    DText,
    Flex,
    IconWrapper,
    UpdateIcon
} from "../../../styles/components/common/common-styles";
import Tooltip from "../../common/utils/Tooltip";
import { useState } from "react";
import ModalLayout from "../../layouts/main/ModalLayout";
import NormalizerMessageModal from "../../modals/NormalizerMessageModal";
import { deepCopy, formatDate } from "../../../core/utils/common";
import { useMainContext } from "../../../core/contexts/main";


const NormalizerMessages = ({
    messages,
    onMessagesChange,
    sender,
    isPost = false
}) => {

    const { main: { lang } } = useMainContext()
    const [editMessage, setEditMessage] = useState(null)

    const [addModal, setAddModal] = useState(false)
    const enterAddMode = () => setAddModal(true)
    const exitAddMode = () => {
        setAddModal(false)
        setEditMessage(null)
    }

    const onAddMessage = (message) => {
        const newMessages = deepCopy(messages)
        newMessages.push(message)
        onMessagesChange(newMessages)
        exitAddMode()
    }

    const onRemoveMessage = (idx) => {
        const newMessages = deepCopy(messages)
        newMessages.splice(idx, 1)
        onMessagesChange(newMessages)
    }

    const onOpenEditMessage = (idx) => {
        setEditMessage({ message: messages[idx], index: idx })
        enterAddMode()
    }

    const onEditMessage = (message) => {
        const index = editMessage?.index
        const newMessages = deepCopy(messages)
        newMessages[index] = message
        onMessagesChange(newMessages)
        setEditMessage(null)
        exitAddMode()
    }

    return (
        <>
            <NormalizerMessageWrapper>
                <Flex fw justify={'flex-end'}>
                    <Tooltip
                        content={'add-message'}
                    >
                        <IconWrapper
                            onClick={enterAddMode}
                        >
                            <AddIcon
                                size={18}
                            />
                        </IconWrapper>
                    </Tooltip>
                </Flex>
                {messages.map((message, idx) => (
                    <MessageRow index={idx}>
                        <Flex width={'80%'} justify={'flex-start'} wrap>
                            <DText main fontSize={'ss'} style={{ margin: '0 4px' }}>
                                {idx + 1}-
                            </DText>
                            <Tag dark>
                                {`${message.type}`}
                            </Tag>
                            {message.type !== 'static' &&
                                <Tag dark>
                                    {`${message.begin}-${message.end}`}
                                </Tag>
                            }
                            {message.type === 'weekly' &&
                                <>
                                    {message.weekDays.map(day => (
                                        <Tag dark>
                                            {day}
                                        </Tag>
                                    ))}
                                </>
                            }
                            {message.type === 'monthly' &&
                                <>
                                    {message.monthDays.map(day => (
                                        <Tag dark>
                                            {day}
                                        </Tag>
                                    ))}
                                </>
                            }
                            {message.type === 'static' &&
                                <>
                                    {message.static.map(item => (
                                        <Tag dark>
                                            {`${formatDate(item, 'date', lang)}-${formatDate(item, 'time', lang)}`}
                                        </Tag>
                                    ))}
                                </>
                            }
                        </Flex>
                        <Flex width={'20%'} justify={'flex-end'}>
                            <IconWrapper
                                onClick={() => onOpenEditMessage(idx)}
                            >
                                <UpdateIcon
                                    size={18}
                                />
                            </IconWrapper>
                            <IconWrapper
                                onClick={() => onRemoveMessage(idx)}
                            >
                                <DeleteIcon
                                    size={18}
                                />
                            </IconWrapper>
                        </Flex>
                    </MessageRow>
                ))}
            </NormalizerMessageWrapper>
            <ModalLayout
                open={addModal}
                onClose={exitAddMode}
                width={'580px'}
                portal={false}
            >
                <NormalizerMessageModal
                    editMessage={editMessage?.message}
                    onAddMessage={onAddMessage}
                    onEditChanges={onEditMessage}
                    sender={sender}
                    isPost={isPost}
                />
            </ModalLayout>
        </>


    )
}


export default NormalizerMessages
