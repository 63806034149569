import Text from "../../../core/utils/Text";
import {ScaleLoader} from "react-spinners";
import {useMainContext} from "../../../core/contexts/main";


const ButtonLoader = ({
    loading, tid
}) => {

    const { main: {theme} } = useMainContext()

    return (
        <>
            {loading ?
                <ScaleLoader
                    color={theme === 'dark' ? '#c3c5b7' : '#191c20'}
                    height={24}
                    width={2}
                />
                :
                <Text tid={tid} />
            }
        </>
    )
}

export default ButtonLoader
