import {useParams} from "react-router-dom";
import {useGetAC2c} from "../../../core/services/react-query/c2c";
import {useEffect} from "react";
import {DetailLayoutWrapper} from "../../../styles/layouts/main-styles";
import DetailBox from "../../common/utils/DetailBox";
import {CFlex, CMargin, DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import DetailContent from "../details/DetailContent";
import {BooleanIcon} from "../../common/utils/BoleanIcon";
import {Tag} from "../../../styles/components/normalizer";
import ModificationDetails from "./ModificationDetails";


const C2cDetails = () => {

    const params = useParams()

    const { data: c2c, refetch: getC2c } = useGetAC2c(params.id)
    useEffect(() => {
        if (params.id) getC2c()
    }, [params])


    return (
        <DetailLayoutWrapper>

            <DetailBox>
                <Flex fw justify={'space-between'}>
                    <Flex>
                        <DText main>
                            <Text tid={'name'} />:
                        </DText>
                        <DText primary margin={'0 5px'}>
                            {c2c?.name}
                        </DText>
                    </Flex>
                    <Flex>
                        <DText main>
                            <Text tid={'sim'} />:
                        </DText>
                        <DText primary margin={'0 5px'}>
                            {c2c?.sim_id?.username}
                        </DText>
                    </Flex>
                    {c2c?.hasDelay ?
                        <Flex>
                            <DText main margin={'0 8px'}>
                                <Text tid={'delay-time'} /> =
                            </DText>
                            <DText primary margin={'0 4px'}>
                                {c2c?.delay?.delay}
                            </DText>
                            <DText main margin={'0 12px'}>
                                <Text tid={'delay-limit'} /> =
                            </DText>
                            <DText primary margin={'0 4px'}>
                                {c2c?.delay?.limit}
                            </DText>
                        </Flex>
                        :
                        <DText primary>
                            <Text tid={'no-delay-set'} />
                        </DText>
                    }

                </Flex>
            </DetailBox>

            <Flex fw justify={'space-between'} wrap align={'stretch'}>
                <DetailBox
                    width={'32%'}
                    title={'sources'}
                >
                    <DetailContent
                        data={c2c?.sources}
                        photo={'address'}
                        title={'title'}
                        type={'channels'}
                    />
                </DetailBox>
                <DetailBox
                    width={'32%'}
                    title={'destination'}
                >
                    <DetailContent
                        data={c2c?.destination ? [c2c?.destination] : []}
                        photo={'address'}
                        title={'title'}
                        type={'channels'}
                    />
                </DetailBox>
                <DetailBox
                    width={'32%'}
                    title={'eitaa'}
                >
                    <CFlex fw align={'flex-start'}>
                        <CMargin margin={'6px'} />
                        <Flex>
                            <DText main>
                                <Text tid={'has-eitaa'} />:
                            </DText>
                            <BooleanIcon bool={c2c?.hasEitaa} />
                        </Flex>
                        <CMargin margin={'6px'} />
                        <Flex>
                            <DText main>
                                <Text tid={'eitaa-yar'} />:
                            </DText>
                            <DText primary margin={'0 5px'}>
                                {c2c?.eitaaYar}
                            </DText>
                        </Flex>
                        <CMargin margin={'6px'} />
                        <Flex fw wrap justify={'flex-start'}>
                            <DText main>
                                <Text tid={'eitaa-destination'} />:
                            </DText>
                            <Tag style={{ fontSize: '0.8rem' }}>
                                {c2c?.eitaaDestination}
                            </Tag>
                        </Flex>
                    </CFlex>
                </DetailBox>

                <DetailBox
                    title={'modifications'}
                >
                    <ModificationDetails c2c={c2c} />
                </DetailBox>
            </Flex>
        </DetailLayoutWrapper>
    )
}

export default C2cDetails
