import Tooltip from "../../common/utils/Tooltip";
import { IconWrapper, RefreshIcon } from "../../../styles/components/common/common-styles";
import { useSocketContent } from "../../../core/contexts/socket-content";
import PcViewer from "../../common/utils/PcViewer";
import Text from "../../../core/utils/Text";


const ChatRefresher = ({ query, type, countUpdateType }) => {

    const { countUpdates } = useSocketContent()
    const { mutate: refreshChat } = query()

    return (
        <div>
            {countUpdates && countUpdates[`${countUpdateType}DetailUpdateCount`] > 0 ?
                <div className={'w-[200px] flex flex-col gap-2 dark:text-white text-xs'}>
                    <Text tid={`${type}-update-count`} />
                    <PcViewer
                        total={countUpdates[`${countUpdateType}DetailUpdateTotal`]}
                        filled={countUpdates[`${countUpdateType}DetailUpdateCount`]}
                    />
                </div>
                :
                <Tooltip
                    content={`refresh-all-${type}-details`}
                >


                    <IconWrapper onClick={refreshChat}>
                        <RefreshIcon size={20} />
                    </IconWrapper>

                </Tooltip>
            }
        </div>

    )
}

export default ChatRefresher
