import { useState } from "react";
import { BASE_URL, STATIC_URL } from "../../../../core/constants/constants";
import mimeDb from '../../../../assets/mime/mimeDb.json'
import { FileIconWrapper, FileWrapper, TelegramMediaBody } from "../../../../styles/components/c2c";
import { DText, Flex } from "../../../../styles/components/common/common-styles";
import { FaPlay } from "react-icons/fa";
import { HiDocument } from 'react-icons/hi';
import { fileDownloader } from "../../../../core/utils/common";
import axios from "axios";


const TelegramPost = ({ post }) => {




    return (
        <>
            {post.mime?.map((item, idx) => {
                let Media;
                if (item.includes('video')) Media = Video
                if (item.includes('audio')) Media = Audio
                if (item.includes('image')) Media = Image
                if (item.includes('application') && !Media) Media = File

                return (
                    <>
                        {Media &&
                            <Media key={idx} name={post.name[idx]} mime={item} />
                        }
                    </>
                )
            })}
        </>
    )
}

const Video = ({ name, mime }) => {

    const [show, setShow] = useState(false)
    let ext
    try {
        ext = mimeDb[mime].extensions[0]
    }
    catch (err) {
        return <></>
    }

    if (!ext) return <></>

    return (
        <>
            {show ?
                <video crossOrigin={'anonymous'} width={'100%'} height={'100%'} controls autoPlay>
                    <source src={BASE_URL + `/c2c/video/${name}.${ext}/${mime.split('/').join('-')}`} type={mime} />
                </video>
                :
                <TelegramMediaBody>
                    <Flex fw fh>
                        <FaPlay size={32} style={{ cursor: 'pointer' }} onClick={() => setShow(true)} />
                    </Flex>
                </TelegramMediaBody>
            }
        </>
    )
}

const Image = ({ name }) => {

    return (
        <img
            src={STATIC_URL + `c2c/${name}.jpeg`}
            alt={' '}
            width={'100%'}
            crossOrigin={'anonymous'}
        />
    )
}

const File = ({ name, mime }) => {

    let ext
    try {
        ext = mimeDb[mime].extensions[0]
    }
    catch (err) {
        return <></>
    }

    if (!ext) return <></>

    const downloadFile = async () => {
        const res = await axios.get(STATIC_URL + `c2c/${name}.${ext}`)
        fileDownloader({
            data: res.data,
            fileName: `${name}.${ext}`,
            fileType: mime,
        })
    }

    return (
        <FileWrapper>
            <Flex fw fh justify={'flex-start'}>

                <FileIconWrapper onClick={downloadFile}>
                    <HiDocument size={24} />
                </FileIconWrapper>
                <DText primary margin={'0 8px'}>
                    {`${name.substring(0, 20)}.${ext}`}
                </DText>
            </Flex>
        </FileWrapper>
    )
}

const Audio = ({ name, mime }) => {

    let ext
    try {
        ext = mimeDb[mime].extensions[0]
    }
    catch (err) {
        return <></>
    }

    if (!ext) return <></>

    return (
        <audio crossOrigin={'anonymous'} controls>
            <source src={BASE_URL + `/c2c/video/${name}.${ext}/${mime.split('/').join('-')}`} type={mime} />
        </audio>
    )
}

export default TelegramPost
