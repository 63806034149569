import {
    Background,
    CFlex,
    CMargin,
    DText,
    Flex,
    LineBreak,
    Padding
} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import {
    useCreateRole,
    useGetRoleConfig,
    useGetWorkspaces,
    useUpdateRole
} from "../../../core/services/react-query/setting";
import Input from "../../common/input/Input";
import { useEffect, useState } from "react";
import CheckBox from "../../common/input/CheckBox";
import ActionButton from "../../common/buttons/ActionButton";
import { useMainContext } from "../../../core/contexts/main";
import SingleSelect from "../../common/select/SingleSelect";
import { deepCopy } from "../../../core/utils/common";


const CreateRoleModal = ({ onClose, details }) => {

    const { item } = details
    const updateMode = !!item
    const { profile: { isSuperAdmin } } = useMainContext()
    const { data: workspaces } = useGetWorkspaces()
    const [allChecked, setAllChecked] = useState(false)

    const { data: config } = useGetRoleConfig()
    const { mutate: createRole, isLoading: createLoading } = useCreateRole(onClose)
    const { mutate: updateRole, isLoading: updateLoading } = useUpdateRole(onClose)

    const [role, setRole] = useState({})
    useEffect(() => {
        if (config) {
            if (updateMode) setRole(item)
            else setRole({ ...config.data, name: '', userWorkspace: '' })
        }
    }, [config])

    const onInputChange = (type, v) => {
        if (type === 'name' || type === 'userWorkspace') setRole(state => ({ ...state, [type]: v }))
        else setRole(state => ({ ...state, permissions: { ...state.permissions, [v]: !state.permissions[v] } }))
    }

    const onCreateRole = () => {
        if (!role.name || updateLoading || createLoading) return
        if (role.userWorkspace) role.userWorkspace = role.userWorkspace.name
        if (updateMode) updateRole(role)
        else createRole(role)
    }

    const onAllCheck = () => {
        setAllChecked(state => !state)
        const newRole = deepCopy(role)
        Object.keys(newRole.permissions).forEach(k => {
            newRole.permissions[k] = !allChecked
        })
        setRole(newRole)
    }

    return (
        <CFlex fw align={'flex-start'} transparent>
            <DText primary fontSize='b'>
                <Text tid={`${updateMode ? 'update' : 'create'}-role`} />
            </DText>
            <CMargin margin='4px' />
            <LineBreak />
            <CMargin margin='4px' />

            <Background bg={'mainBg'}>
                <Input
                    value={role?.name}
                    onInputChange={v => onInputChange('name', v)}
                    size={'small'}
                    height={'32px'}
                    label={'name'}
                />
                {isSuperAdmin &&
                    <Padding padding={'12px 4px'}>
                        <SingleSelect
                            style={{ width: '100%', height: '42px', justifyContent: 'flex-start' }}
                            options={workspaces?.data || []}
                            value={role?.userWorkspace}
                            onValueChange={v => onInputChange('userWorkspace', v)}
                            placeHolder='userWorkspace'
                            param={'name'}
                            type={'role'}
                        />
                    </Padding>

                }
            </Background>
            <CFlex fw>
                <Flex fw justify={'space-between'} style={{ marginTop: '12px' }}>
                    <DText main>
                        <Text tid={'permissions'} />
                    </DText>
                    <Flex>
                        <CheckBox active={allChecked} onClick={onAllCheck} />
                        <DText primary margin={'0 5px'}>
                            <Text tid={`${allChecked ? 'deselect' : 'select'}-all`} />
                        </DText>
                    </Flex>
                </Flex>
                <LineBreak />

                <RoleRow
                    onInputChange={onInputChange}
                    item1={{ key: 'sim', value: role?.permissions?.sim }}
                    item2={{ key: 'user', value: role?.permissions?.user }}
                />
                <RoleRow
                    onInputChange={onInputChange}
                    item1={{ key: 'group', value: role?.permissions?.group }}
                    item2={{ key: 'channel', value: role?.permissions?.channel }}
                />
                <RoleRow
                    onInputChange={onInputChange}
                    item1={{ key: 'c2c', value: role?.permissions?.c2c }}
                    item2={{ key: 'operation', value: role?.permissions?.operation }}
                />
                <RoleRow
                    onInputChange={onInputChange}
                    item1={{ key: 'normalizer', value: role?.permissions?.normalizer }}
                    item2={{ key: 'post', value: role?.permissions?.post }}
                />
                <RoleRow
                    onInputChange={onInputChange}
                    item1={{ key: 'setting', value: role?.permissions?.setting }}
                    item2={{ key: 'panel', value: role?.permissions?.panel }}
                />
                <RoleRow
                    onInputChange={onInputChange}
                    item1={{ key: 'category', value: role?.permissions?.category }}
                    item2={{ key: 'moderator', value: role?.permissions?.moderator }}
                />
                <RoleRow
                    onInputChange={onInputChange}
                    item1={{ key: 'sender', value: role?.permissions?.sender }}
                    item2={{ key: 'manager', value: role?.permissions?.manager }}
                />
                <Flex fw justify={'flex-start'}>
                    <DText main fontSize={'s'} margin={'8px 12px'}>
                        <Text tid={`role-c2c_panel`} />:
                    </DText>
                    <CheckBox
                        active={role?.permissions?.c2c_panel}
                        onClick={() => onInputChange('bool', 'c2c_panel')}
                    />
                </Flex>
                <CMargin margin={'12px'} />
                <ActionButton
                    active={!!role.name}
                    loading={updateLoading || createLoading}
                    onClick={onCreateRole}
                    height={'42px'}
                >
                    <Text tid={'submit'} />
                </ActionButton>
            </CFlex>
        </CFlex>
    )
}


const RoleRow = ({ onInputChange, item1, item2 }) => {

    return (
        <Flex fw justify={'space-between'}>
            <Padding padding={'6px 12px'}>
                <Flex>
                    <DText main fontSize={'s'} margin={'0 4px'}>
                        <Text tid={`role-${item1.key}`} />:
                    </DText>
                    <CheckBox
                        active={item1.value}
                        onClick={() => onInputChange('bool', item1.key)}
                    />
                </Flex>
                <Flex>
                    <DText main fontSize={'s'} margin={'0 4px'}>
                        <Text tid={`role-${item2.key}`} />:
                    </DText>
                    <CheckBox
                        active={item2.value}
                        onClick={() => onInputChange('bool', item2.key)}
                    />
                </Flex>
            </Padding>
        </Flex>
    )
}

export default CreateRoleModal
