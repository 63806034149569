import Text from "../../core/utils/Text";


const Section2 = () => {

    const data = [
        { head: '2.5B+', desc: 'messages-sent' },
        { head: '50M+', desc: 'user-added' },
        { head: '40K+', desc: 'groups-added' },
        { head: '+∞', desc: 'views' },
    ]

    return (
        <div className={'w-full p-12'}>
            <div className={'grid grid-cols-4 max-w-[1144px] mx-auto'}>
                {data.map((item, idx) => {
                    return (
                        <div className={`flex flex-col gap-2 ${idx !== 3 && 'border-l-[1px] border-gray-300'} min-h-[64px] px-6`}>
                            <span className={'text-gray-900 text-4xl font-semibold'}>{item.head}</span>
                            <Text tid={item.desc} className={'text-gray-500'} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Section2
