import { useEffect, useState } from "react";
import useOperationalSims from "../../../core/hooks/main/sim/useOperationalSims";
import { useSimGroupsQuery } from "../../../core/services/react-query/group";
import { CREATE_POST_TYPE } from "../../../core/constants/constants";
import { useQueryContext } from "../../../core/contexts/query";
import { COBody, COWrapper, OperationSVG } from "../../../styles/components/operation/operation-styles";
import { fadeVariants } from "../../../core/utils/theme";
import { AnimationWrapper, CFlex, CMargin, DText, Flex, Padding } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Input from "../../common/input/Input";
import Select from "../../common/select/Select";
import { AnimatePresence } from "framer-motion";
import MultiSelect from "../../common/select/MultiSelect";
import ActionButton from "../../common/buttons/ActionButton";
import ButtonLoader from "../../common/loading/ButtonLoader";
import { useCreatePost } from "../../../core/services/react-query/post";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useMainContext } from "../../../core/contexts/main";
import DateInput from "../../common/input/DateInput";
import { useNavigate } from "react-router-dom";
import { stringToNumber } from "../../../core/utils/common";
import { routes } from "../../../core/constants/routes";
import { useAclContext } from "../../../core/contexts/acl";



const CreatePost = () => {

    const navigate = useNavigate()
    const { main: { lang, theme }, setNeedPackage } = useMainContext()
    const { setToast } = useQueryContext()
    const { permissions } = useAclContext()


    const { mutate: createPost, isLoading: creatingPost, isSuccess } = useCreatePost()

    const [simOptions, setSimOptions] = useState([])
    const { simsList } = useOperationalSims()
    useEffect(() => {
        if (simsList) setSimOptions(simsList.map(item => item.username))
    }, [simsList])

    const initialState = {
        sim_id: '',
        message_id: '',
        viewLimit: '',
        groups: [],
        dates: [],
        alpha_id: '',
        source_address: '',
        delay: ''
    }
    const [inputData, setInputData] = useState(initialState)
    const [valid, setValid] = useState(false)

    const [selectedSim, setSelectedSim] = useState(null)

    /**
     * sim groups
     */
    const { data: groups, refetch: getSimGroups } = useSimGroupsQuery(selectedSim?._id)

    /**
     * on sim select
     */
    useEffect(() => {
        if (selectedSim) {
            getSimGroups()
        }
    }, [selectedSim])

    /**
     * validate all inputs for api call
     */
    useEffect(() => {
        const valid =
            !!inputData.message_id &&
            !!inputData.sim_id &&
            inputData.groups?.length &&
            inputData.dates?.length &&
            !!inputData.viewLimit

        setValid(valid)

    }, [inputData])

    const onInputValueChange = (value, type) => {
        switch (type) {
            case CREATE_POST_TYPE.DATES:
                setInputData(state => ({ ...state, dates: value }))
                break
            case CREATE_POST_TYPE.MESSAGE_ID:
                setInputData(state => ({ ...state, message_id: value }))
                break
            case CREATE_POST_TYPE.SIM_ID:
                const simName = simOptions[value]
                setInputData(state => ({ ...state, sim_id: simName }))
                setSelectedSim(simsList?.find(sim => sim.username === simName))
                break
            case CREATE_POST_TYPE.VIEW_LIMIT:
                setInputData(state => ({ ...state, viewLimit: value }))
                break
            case CREATE_POST_TYPE.SOURCE_ADDRESS:
                setInputData(state => ({ ...state, source_address: value }))
                break
            case CREATE_POST_TYPE.DELAY:
                setInputData(state => ({ ...state, delay: value }))
                break
            default:
                break
        }
    }

    const onGroupsChange = (selectedOptions) => {
        setInputData(state => ({ ...state, groups: selectedOptions }))
    }


    const onSubmitClicked = () => {
        if (valid) {
            if (permissions?.post) {
                const payload = { ...inputData }
                payload.message_id = parseInt(payload.message_id)
                payload.delay = parseInt(payload.delay)
                payload.sim_id = selectedSim._id
                payload.groups = inputData.groups.map(x => x._id)
                payload.dates = inputData.dates.map(date => (new Date(date)).getTime())
                payload.viewLimit = stringToNumber(payload.viewLimit)
                createPost(payload)
            } else
                setNeedPackage(true)
        }
        else setToast({
            isError: true,
            show: true, message: 'fill-inputs-error'
        })
    }


    useEffect(() => {
        if (isSuccess) navigate(routes.post)
    }, [isSuccess])

    return (
        <>
            <COWrapper>
                <COBody variants={fadeVariants} animate='in' exit='out' initial='out'>
                    <CFlex fw>
                        <Flex fw justify='flex-start'>
                            <DText fontSize='sb' primary>
                                <Text tid='add-post' />
                            </DText>
                        </Flex>

                        <CMargin margin='20px' />
                        <Input
                            label={CREATE_POST_TYPE.SOURCE_ADDRESS}
                            value={inputData.source_address}
                            onInputChange={(e) => onInputValueChange(e, CREATE_POST_TYPE.SOURCE_ADDRESS)}
                        />

                        <Input
                            label={CREATE_POST_TYPE.MESSAGE_ID}
                            value={inputData.message_id}
                            onInputChange={(e) => onInputValueChange(e, CREATE_POST_TYPE.MESSAGE_ID)}
                            code
                        />

                        <Input
                            label={CREATE_POST_TYPE.VIEW_LIMIT}
                            value={inputData.viewLimit}
                            onInputChange={(e) => onInputValueChange(e, CREATE_POST_TYPE.VIEW_LIMIT)}
                            number
                        />

                        <Input
                            label={`${CREATE_POST_TYPE.DELAY}-seconds`}
                            value={inputData.delay}
                            onInputChange={(e) => onInputValueChange(e, CREATE_POST_TYPE.DELAY)}
                            code
                        />

                        <CMargin margin={'5px'} />
                        <Padding padding='4px'>
                            <Select
                                width={'100%'}
                                value={inputData.sim_id}
                                placeHolder={'choose-sim'}
                                options={simOptions}
                                onValueChange={(idx) => onInputValueChange(idx, CREATE_POST_TYPE.SIM_ID)}
                            />
                        </Padding>

                        {/*
                            groups
                        */}
                        <CMargin margin={'10px'} />
                        <AnimatePresence exitBeforeEnter>
                            {selectedSim &&
                                <AnimationWrapper
                                    initial={'out'}
                                    animate={'in'}
                                    exit={'out'}
                                    variants={fadeVariants}
                                >
                                    <CMargin margin={'4px'} />
                                    <Padding padding='4px'>
                                        <MultiSelect
                                            values={inputData.groups}
                                            onValuesChange={onGroupsChange}
                                            placeHolder='select-groups'
                                            options={groups || []}
                                            type='chat'
                                            param='address'
                                        />
                                    </Padding>

                                    <Padding padding={'4px'}>
                                        <CMargin margin={'20px'} />
                                        <DatePicker
                                            calendar={lang === 'fa' && persian}
                                            locale={lang === 'fa' && persian_fa}
                                            value={inputData.dates}
                                            onChange={(v) => onInputValueChange(v, CREATE_POST_TYPE.DATES)}
                                            plugins={[<TimePicker />]}
                                            render={<DateInput label={'dates'} />}
                                            className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal rmdp-mobile`}
                                            calendarPosition={lang === 'fa' ? 'left-top' : 'right-top'}
                                            minDate={new Date()}
                                        />
                                    </Padding>


                                    <CMargin margin={'20px'} />
                                    <ActionButton
                                        active={valid}
                                        onClick={onSubmitClicked}
                                    >
                                        <ButtonLoader
                                            tid='submit'
                                            loading={creatingPost}
                                        />
                                    </ActionButton>
                                </AnimationWrapper>
                            }
                        </AnimatePresence>

                    </CFlex>
                </COBody>
                <OperationSVG />
            </COWrapper>
        </>
    )
}


export default CreatePost
