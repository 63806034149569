import styled, {css, keyframes} from 'styled-components'
import {motion} from "framer-motion";
import { IoMdSettings } from 'react-icons/io'
import {DText} from "../components/common/common-styles";


const MainBody = styled.div`
  width: 100%;
  height: ${props => props.hasHeader ? 'calc(100vh - 80px)' : '100vh'};
  position: relative;
  top: ${props => props.hasHeader ? '80px' : '0'};
  overflow-y: auto;
  overflow-x: hidden;
  font-family: ${props => props.theme.english ? "Jetbrain" : "Vazir"};
  //padding-top: 80px;
  background-color: ${props => props.theme.primaryBg};
  direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
`

const HeaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  padding: 0 20px;
  top: 0;
  box-shadow: 0 0 8px ${props => props.theme.mainBg};
  border-bottom: 1px solid ${props => props.theme.color}10;
  background-color: ${props => props.theme.mainBg};
  z-index: 999;
  direction: ${props => props.theme.english ? 'ltr' : 'rtl'};
  
  &::after{
    content: '';
    position: absolute;
    bottom: 5px;
    width: 70%;
    left: 0;
    background-color: ${props => props.theme.color}20;
    height: 1px;
  }
  
  &::before{
    content: '';
    position: absolute;
    top: 5px;
    width: 70%;
    right: 0;
    background-color: ${props => props.theme.color}20;
    height: 1px;
  }
`


const DetailLayoutWrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  padding: 20px 40px;
  
  @media screen and (max-width: 768px) {
    padding: 20px;
  };
`

const SubmitModalWrapper = styled(motion.div)`
    width: 300px;
    top: 80px;
  position: fixed;
  ${props => props.theme.english && css` 
    right: calc(50% - 250px);  
  `};
  
  ${props => !props.theme.english && css`
    left: calc(50% - 250px);
  `};
  
    border: 1px solid ${props => props.theme.color}10;
    background-color: ${props => props.theme.mainBg};
    padding: 20px 10px;
    box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
    z-index: 100;
    border-radius: 0 0 4px 4px;
  
  @media screen and (max-width: 768px) {
    ${props => props.theme.english && css` 
      right: calc(50% - 150px);  
    `};

    ${props => !props.theme.english && css`
      left: calc(50% - 150px);
    `};

  };
`

export const NoAccessText = styled.div`
  font-size: 1.2rem;
  color: ${props => props.theme.primary};
  position: absolute;
  margin: auto;
  bottom: 40px;
  
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  };
`

const Animation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`

export const NoAccessDesc = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.active};
  position: absolute;
  margin: auto;
  bottom: 20px;
  
  animation: ${Animation} 1s infinite;

  @media screen and (max-width: 768px) {
    font-size: 0.6rem;
  };
`

const LayoutSettingIcon = styled(IoMdSettings)`
  color: ${props => props.theme.color};
  margin: 0 8px;
  cursor: pointer;

  &:hover{
    color: ${props => props.theme.active};
  };
`

const LSWrapper = styled(motion.div)`
  position: fixed;
  top: 80px;
  z-index: 10000;
  direction: ${props => props.theme.english ? 'ltr': 'rtl'};
  ${props => props.theme.english && css`
    right: 0;
  `};
  ${props => !props.theme.english && css`
    left: 0;
  `};
`

const LSBody = styled.div`
  width: 340px;
  padding: 10px 16px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 16%) 0px 16px 32px, rgb(0 0 0 / 16%) 0px 8px 16px, rgb(94 102 115 / 90%) 0px 0px 1px inset;
  background-color: ${props => props.theme.settingBg};
`

const LSRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.color}20;
  padding: 16px 0;
`

const ColorBox = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: ${props => props.color};
  margin: 0 4px;
`

const LSText = styled(DText)`
  ${props => props.main && css`
    color: ${props => props.theme.mainBg};
  `};
  ${props => props.primary && css`
    color: ${props => props.theme.primaryBg};
  `};
`

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.sidebar ? '220px' : '80px'} calc(100% - ${props => props.sidebar ? '220px' : '80px'});
  ${props => props.login && css`
    grid-template-columns: 100%;
  `};
  align-items: stretch;
`

export {
    MainBody,
    HeaderWrapper,
    DetailLayoutWrapper,
    SubmitModalWrapper,
    LayoutSettingIcon,
    LSWrapper,
    LSBody,
    LSRow,
    ColorBox,
    LSText,
    MainContainer
}
