import { CFlex, DText, Flex, LineBreak } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import { useMainContext } from "../../../core/contexts/main";


const PostStatusMap = ({ statusMap }) => {

    const { main: { lang } } = useMainContext()

    return (
        <CFlex fw width={'300px'}>
            {Object.keys(statusMap).map((key, idx) => {

                const { color, desc } = statusMap[key]

                return (
                    <>
                        <Flex fw key={key} justify={'space-between'} align={'flex-start'}>
                            <DText color={color} style={{ width: '140px' }} fontSize={'s'}>
                                {key} :
                            </DText>
                            <DText main style={{ width: '160px', direction: lang === 'en' ? 'ltr' : 'rtl' }} fontSize={'s'}>
                                <Text tid={desc} />
                            </DText>
                        </Flex>
                        {idx !== 4 &&
                            <LineBreak />
                        }
                    </>
                )
            })}
        </CFlex>
    )
}

export default PostStatusMap
