import Input from "../../common/input/Input";
import { useState } from "react";
import Select from "../../common/select/Select";
import { CHAT_OPTIONS } from "../../../core/constants/constants";
import { CMargin, DText, Padding } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import ActionButton from "../../common/buttons/ActionButton";
import { useCreateGroupMutation } from "../../../core/services/react-query/group";
import { useQueryContext } from "../../../core/contexts/query";
import ButtonLoader from "../../common/loading/ButtonLoader";
import { useGetASim } from "../../../core/services/react-query/sim";
import SingleSelect from "../../common/select/SingleSelect";
import { useAclContext } from "../../../core/contexts/acl";
import { useMainContext } from "../../../core/contexts/main";
import MultiSelect from "../../common/select/MultiSelect";
import { useGetCategoryType } from "../../../core/services/react-query/category";


const CreateGroup = ({
    onCreateGroupSuccess,
    onClose
}) => {

    const { setNeedPackage } = useMainContext()
    const { permissions } = useAclContext()
    const { setToast } = useQueryContext()

    const [address, setAddress] = useState('')
    const [type, setType] = useState('')
    const [sim, setSim] = useState()
    const [selectedCategories, setSelectedCategories] = useState([])

    const { data: categories } = useGetCategoryType('group')

    const { mutate: sendCreateRequest, isLoading: creatingGroup } = useCreateGroupMutation(onCreateGroupSuccess)
    const { data: alphaSims } = useGetASim('alpha')

    const VALID = address && type && !!sim

    const createGroup = () => {
        if (!VALID) {
            setToast({
                isError: true, show: true,
                message: 'fill-inputs-error'
            })
        } else {
            if (permissions?.group) {
                sendCreateRequest({
                    address, type, sim_id: sim?._id, categories: selectedCategories
                })
            } else {
                onClose()
                setNeedPackage(true)
            }
        }
    }

    return (
        <>
            <DText fontSize='b' primary>
                <Text tid='create-group' />
            </DText>
            <CMargin margin='20px' />
            <Input
                value={address}
                onInputChange={(value) => setAddress(value)}
                label='address'
                direction='ltr'
            />
            <Padding padding='4px'>
                <Select
                    options={CHAT_OPTIONS}
                    value={type}
                    onValueChange={(idx) => setType(CHAT_OPTIONS[idx])}
                    placeHolder='choose-type'
                />
            </Padding>
            <CMargin margin='20px' />
            <Padding padding='4px'>
                <SingleSelect
                    value={sim}
                    onValueChange={(s) => setSim(s)}
                    placeHolder='select-alpha-sim'
                    options={alphaSims || []}
                    type={'sim'}
                    param='username'
                    style={{ height: '48px', justifyContent: 'flex-start', padding: '0 12px' }}
                />
            </Padding>
            <CMargin margin='20px' />
            <Padding padding='4px'>
                <MultiSelect
                    values={selectedCategories}
                    options={categories || []}
                    type={'category'}
                    param={'name'}
                    placeHolder={`choose-categories`}
                    onValuesChange={(selectedOptions) => setSelectedCategories(selectedOptions)}
                />
            </Padding>
            <CMargin margin='30px' />
            <ActionButton
                active={VALID}
                onClick={createGroup}
            >
                <ButtonLoader
                    tid='submit'
                    loading={creatingGroup}
                />
            </ActionButton>
        </>
    )

}


export default CreateGroup
