import { useMutation, useQuery } from "react-query";
import { filterFetch, normalFetch } from "../../fetch-api/get";
import { makeQueryFilters } from "../../../utils/query-maker";
import { postApi } from "../../fetch-api/post";
import { useQueryContext } from "../../../contexts/query";
import { deleteApi } from "../../fetch-api/delete";


const POST_KEYS = {
    CREATE_POST: 'create-post',
    FETCH_POSTS: 'fetch-post',
    GET_A_POST: 'get-a-post',
    UPDATE_POST: 'update-post',
    RUN_POST: 'run-post',
    STOP_POST: 'stop-post',
    DELETE_POST: 'delete-post',
}

const useFetchPosts = (filters, pagination) => {

    const params = makeQueryFilters(filters, pagination)

    return useQuery(
        POST_KEYS.FETCH_POSTS, () => filterFetch(params, '/post'),
        {
            refetchInterval: 60 * 1000
        }
    )
}


const useGetAPost = (id) => {

    return useQuery(
        POST_KEYS.GET_A_POST, () => normalFetch(`/post/${id}`),
        {
            select: res => res?.data?.data
        }
    )
}

const useCreatePost = () => {

    return useMutation(
        POST_KEYS.CREATE_POST, (data) => postApi(data, '/post')
    )
}

const useUpdatePost = (onSuccess) => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        POST_KEYS.UPDATE_POST, (data) => postApi(data, '/post/update'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(POST_KEYS.FETCH_POSTS)
                setToast({
                    message: 'op-success',
                    show: true
                })
                onSuccess()
            }
        }
    )
}


const useRunPost = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        POST_KEYS.RUN_POST, (data) => postApi(data, '/post/run'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(POST_KEYS.FETCH_POSTS)
                setToast({
                    message: 'op-success',
                    show: true
                })
            }
        }
    )
}

const useStopPost = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        POST_KEYS.STOP_POST, (data) => postApi(data, '/post/stop'),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(POST_KEYS.FETCH_POSTS)
                setToast({
                    message: 'op-success',
                    show: true
                })
            }
        }
    )
}

const useDeletePost = () => {

    const { setToast, queryClient } = useQueryContext()

    return useMutation(
        POST_KEYS.DELETE_POST, (id) => deleteApi(`/post/${id}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(POST_KEYS.FETCH_POSTS)
                setToast({
                    message: 'op-success',
                    show: true
                })
            }
        }
    )
}



export {
    useFetchPosts,
    useCreatePost,
    useRunPost,
    useStopPost,
    useDeletePost,
    useGetAPost,
    useUpdatePost
}
