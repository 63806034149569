import { CFlex, CMargin, DText, LineBreak } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import MultiSelect from "../../common/select/MultiSelect";
import { useMemo, useState } from "react";
import useOperationalSims from "../../../core/hooks/main/sim/useOperationalSims";
import { useGetCategoryType } from "../../../core/services/react-query/category";
import ActionButton from "../../common/buttons/ActionButton";
import ButtonLoader from "../../common/loading/ButtonLoader";


const UpdateChatModal = ({
    chat,
    type,
    onSubmit,
    loading
}) => {

    const { simsList } = useOperationalSims()
    const { data: categories } = useGetCategoryType(
        type === 'channels' ? 'channel' : 'group'
    )

    const initialState = {
        sims: chat?.sims,
        categories: chat?.categories
    }
    const [inputData, setInputData] = useState(initialState)

    const onInputValueChange = (options, changeType) => {
        setInputData(state => ({ ...state, [changeType]: options }))
    }

    const onActionButtonClicked = () => {
        onSubmit(inputData)
    }

    const sortedCategories = useMemo(() => {
        return categories?.sort((a, b) => (a.list.length < b.list.length ? 1 : (a.list.length > b.list.length ? -1 : 0))) || []
    }, [categories])

    return (
        <CFlex fw align='flex-start' transparent>
            <DText fontSize='b' primary>
                <Text tid={`update-${type}`} />
            </DText>
            <LineBreak />

            <CMargin margin={'10px'} />
            <MultiSelect
                values={inputData.categories}
                options={sortedCategories || []}
                type={'category'}
                param={'name'}
                placeHolder={`choose-categories`}
                onValuesChange={(selectedOptions) => onInputValueChange(selectedOptions, 'categories')}
            />

            <CMargin margin='15px' />
            <MultiSelect
                values={inputData.sims}
                options={simsList || []}
                type='sim'
                param='username'
                placeHolder={`choose-sims`}
                onValuesChange={(selectedOptions) => onInputValueChange(selectedOptions, 'sims')}
            />
            <CMargin margin='20px' />
            <ActionButton
                active
                width='100%'
                onClick={onActionButtonClicked}
            >
                <ButtonLoader
                    tid={'submit'}
                    loading={loading}
                />
            </ActionButton>
        </CFlex>
    )
}


export default UpdateChatModal
