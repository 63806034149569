import useOperationalSims from "../../../../core/hooks/main/sim/useOperationalSims"
import { formatNumber } from "../../../../core/utils/common"
import Text from "../../../../core/utils/Text"
import { DText } from "../../../../styles/components/common/common-styles"
import CheckBox from "../../../common/input/CheckBox"
import Input from "../../../common/input/Input"
import MultiSelect from "../../../common/select/MultiSelect"



const SendPvStage2 = ({
    categories,
    totalMembers,
    input,
    onInputChange
}) => {

    const { simsList } = useOperationalSims()
    const totalPosSendMessage = (60 / (input.delay_per_send || 1)) * 60 * input.operation_length * input.message_per_send * (input.sims?.length || 0)
    const operationProbLength = Math.ceil(totalMembers / (totalPosSendMessage / 60 / input.operation_length))

    return (
        <>
            <div className={'w-full grid grid-cols-6 gap-2 items-center mt-4'}>
                <DText main className={'text-xs col-span-2'}>
                    <Text tid={'categories-col'} />
                </DText>
                <DText primary className={'col-span-4'}>
                    <span className={'w-full truncate'}>{categories?.map(c => c.name).join(',')}</span>
                </DText>

                <DText main className={'text-xs col-span-2'}>
                    <Text tid={'member-total'} />
                </DText>

                <span className={`text-sm text-white rounded-md 
                        bg-blue-400 dark:bg-cyan-600 px-5 py-[2px] border-opacity-10 
                        border-[1px] cols-span-4 w-max
                        `}>
                    {formatNumber(totalMembers, true)}
                </span>
            </div>

            <div className={'bg-gray-500 bg-opacity-10 h-[1px] w-full mt-3'} />

            <div className={'mt-5'}>
                <MultiSelect
                    values={input.sims}
                    options={simsList || []}
                    type='sim'
                    param='username'
                    placeHolder={`choose-sims`}
                    onValuesChange={(v) => onInputChange(v, 'sims')}
                />
            </div>

            <div className={'bg-gray-500 bg-opacity-10 h-[1px] w-full mt-3'} />

            <div className={'flex flex-col gap-3 mt-3'}>
                <DText main>
                    <Text tid={'calculator'} />
                </DText>

                <div className={'flex flex-col gap-1'}>
                    <div className={'grid grid-cols-6 gap-4 w-full'}>

                        {/* operation length */}
                        <DText main className={'text-xs col-span-1'}>
                            <Text tid={'operation-length'} />
                        </DText>
                        <div className={'col-span-5 flex items-center gap-4'}>
                            {[1, 2, 3].map(item => {

                                const active = input.operation_length === item

                                return (
                                    <div className={'flex items-center gap-1'}>
                                        <CheckBox active={active} onClick={() => onInputChange(item, 'operation_length')} />
                                        <DText main>
                                            <Text tid={`op-${item}hour`} />
                                        </DText>
                                    </div>
                                )
                            })}
                        </div>

                        {/* Message per send */}
                        <div className={'col-span-3'}>
                            <Input
                                value={input.message_per_send}
                                onInputChange={v => onInputChange(v, 'message_per_send')}
                                height={42}
                                width={'90%'}
                                label={'message_per_send'}
                                error={'num-between-0-30'}
                                valid={input.message_per_send < 31}
                                size={'small'}
                                code
                            />
                        </div>

                        {/* delay per send */}
                        <div className={'col-span-3'}>
                            <Input
                                value={input.delay_per_send}
                                onInputChange={v => onInputChange(v, 'delay_per_send')}
                                height={42}
                                width={'90%'}
                                label={'delay_per_send'}
                                size={'small'}
                                code
                            />
                        </div>

                        <div className={'col-span-3 mt-[-15px]'}>
                            <Input
                                value={input.maximum_message}
                                onInputChange={(v) => onInputChange(v, 'maximum_message')}
                                height={42}
                                width={'90%'}
                                label={'maximum_message'}
                                error={'num-exceed-total-members'}
                                valid={input.maximum_message <= totalMembers}
                                size={'small'}
                                code
                            />
                        </div>

                    </div>
                </div>

                <div className={'h-[1px] w-full bg-gray-500 bg-opacity-20 mt-[-15px]'} />

                <div className={'grid grid-cols-2 gap-2'}>
                    <div className={'flex items-center gap-2'}>
                        <DText main>
                            <Text tid={'op-length-status'} />
                        </DText>
                        <StatusLamp status={input.operation_length} />
                    </div>

                    <div className={'flex items-center gap-2'}>
                        <DText main>
                            <Text tid={'message-per-send-status'} />
                        </DText>
                        <StatusLamp status={
                            input.message_per_send / 10
                        } />
                    </div>

                    <DText main>
                        <Text tid={'send-message-total-prob'} />
                    </DText>

                    <DText primary>
                        <span className={` text-white rounded-md 
                        bg-blue-400 dark:bg-cyan-600 px-5 py-[2px] border-opacity-10 
                        border-[1px]
                        `}>
                            {formatNumber(totalPosSendMessage, true)}
                        </span>
                    </DText>

                    <DText main>
                        <Text tid={'operation-length-prob'} />
                    </DText>

                    <DText primary>
                        <span className={` text-white rounded-md 
                        bg-orange-400 dark:bg-orange-500 px-5 py-[2px] border-opacity-10 
                        border-[1px]
                        `}>
                            {formatNumber(operationProbLength, true)}
                        </span>
                    </DText>

                </div>
            </div>
        </>
    )
}

const StatusLamp = ({ status }) => {

    let s = Math.ceil(status)
    if (status > 0 && status < 1) s = 1

    const backgroundColors = {
        1: 'bg-green-500',
        2: 'bg-orange-500',
        3: 'bg-red-500'
    }

    return (
        <div className={`rounded-[50%] w-[14px] h-[14px] 
            ${(s < 4 && s > 0)
                ? backgroundColors[s] : 'bg-gray-500'}
        `} />
    )
}

export default SendPvStage2