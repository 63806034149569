import {
    AddIcon, CFlex, DeleteIcon, DText,
    Flex, IconWrapper,
    Padding, RMargin, UpdateIcon
} from "../../../../styles/components/common/common-styles";
import { AddButtonWrapper } from "../../../../styles/components/setting";
import Text from "../../../../core/utils/Text";
import { useState } from "react";
import ModalLayout from "../../../layouts/main/ModalLayout";
import CreateWorkspaceModal from "../../../modals/setting/CreateWorkspaceModal";
import FilterLayout from "../../../layouts/filter/FilterLayout";
import { useChangeWorkspace, useDeleteWorkspace, useGetWorkspaces } from "../../../../core/services/react-query/setting";
import { CacheKeys, workspaceFilterOptions } from "../../../../core/constants/filter";
import TableLayout from "../../../layouts/main/TableLayout";
import { Column, Row } from "../../../../styles/layouts/table-styles";
import { useMainContext } from "../../../../core/contexts/main";
import { formatDate } from "../../../../core/utils/common";
import SingleSelect from "../../../common/select/SingleSelect";
import Input from "../../../common/input/Input";
import { useTranslate } from "../../../../core/utils/translator";
import { useProfileQuery } from "../../../../core/services/react-query/auth";
import DeleteModal from "../../../modals/DeleteModal";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../core/constants/routes";


const Workspace = () => {

    const { main: { lang } } = useMainContext()
    const { setToast } = useMainContext()
    const { data: profile, refetch: refetchProfile } = useProfileQuery()

    const onChangeWorkspaceSuccess = () => {
        refetchProfile()
        setToast({
            message: 'op-success',
            show: true
        })
    }

    const { mutate: changeWorkspace } = useChangeWorkspace(onChangeWorkspaceSuccess)

    const [workspaces, setWorkspaces] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setWorkspaces(res)

    const onAdminWorkspaceChange = (v) => {
        changeWorkspace({ newWorkspace: v.name })
    }

    /* create workspace */
    const navigate = useNavigate()
    const navigateToWorkspace = () => navigate(routes.createWorkspace)
    const navigateToPackage = () => navigate(routes.createPackage)

    /* delete workspace */
    const [deleteModal, setDeleteModal] = useState({ show: false, _id: null })
    const onCloseDelete = () => setDeleteModal({ show: false, _id: null })
    const onOpenDelete = (_id) => setDeleteModal({ show: true, _id })

    const { mutate: deleteWorkspace } = useDeleteWorkspace()
    const onDeleteSubmitted = () => {
        deleteWorkspace(deleteModal._id)
        onCloseDelete()
    }

    const onUpdateWorkspace = (id) => {
        navigate(`/setting/create-workspace?id=${id}`)
    }

    return (
        <>
            <CFlex fw>
                <Padding padding={'20px'}>
                    <Flex fw justify={'flex-end'}>
                        <SingleSelect
                            style={{ width: '128px', height: '32px', justifyContent: 'flex-start' }}
                            options={workspaces?.data?.data || []}
                            onValueChange={onAdminWorkspaceChange}
                            value={workspaces?.data?.data?.find(item => profile.workspace === item.name)}
                            placeHolder='change-workspace'
                            param={'name'}
                            type={'role'}
                            noLabel
                        />
                        <RMargin margin={'5px'} />
                        <AddButtonWrapper onClick={navigateToWorkspace}>
                            <Flex fw fh>
                                <AddIcon size={18} />
                                <Text tid={'create-workspace'} />
                            </Flex>
                        </AddButtonWrapper>
                        {profile.role === 'superAdmin' &&
                            <>
                                <RMargin margin={'5px'} />
                                <AddButtonWrapper onClick={navigateToPackage}>
                                    <Flex fw fh>
                                        <AddIcon size={18} />
                                        <Text tid={'create-package'} />
                                    </Flex>
                                </AddButtonWrapper>
                            </>
                        }
                    </Flex>


                    <FilterLayout
                        query={useGetWorkspaces}
                        cache={CacheKeys.WORKSPACE}
                        onQuerySuccess={onQuerySuccess}
                        options={workspaceFilterOptions}
                    >
                        <TableLayout
                            headers={headers}
                            cs={cs}
                            data={workspaces}
                            minWidth={1}
                        >
                            {workspaces?.data?.data?.map((item, idx) => (
                                <Row cs={cs} index={idx}>
                                    <Column>
                                        {idx + 1}
                                    </Column>
                                    <Column>
                                        {item.name}
                                    </Column>
                                    <Column>
                                        {item.users?.length}
                                    </Column>
                                    <Column>
                                        {`${formatDate(item.createdAt, 'date', lang)}-${formatDate(item.createdAt, 'time', lang)}`}
                                    </Column>
                                    {item.name !== 'master' &&
                                        <Column more>
                                            <Flex>
                                                <IconWrapper>
                                                    <UpdateIcon size={16} onClick={() => onUpdateWorkspace(item?.name)} />
                                                </IconWrapper>
                                                <IconWrapper onClick={() => onOpenDelete(item._id)}>
                                                    <DeleteIcon size={18} />
                                                </IconWrapper>
                                            </Flex>
                                        </Column>
                                    }

                                </Row>
                            ))}
                        </TableLayout>
                    </FilterLayout>
                </Padding>
            </CFlex>
            <ModalLayout
                open={deleteModal.show}
                onClose={onCloseDelete}
                width={'580px'}
            >
                <DeleteModal
                    type={'workspace'}
                    onSubmit={onDeleteSubmitted}
                    onCancel={onCloseDelete}
                />
            </ModalLayout>
        </>
    )
}


const cs = '8% 45% 20% 20% 7%'
const headers = [
    'name', 'users-count', 'date'
]

export default Workspace
