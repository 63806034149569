import { useParams } from "react-router-dom";
import DetailBox from "../../common/utils/DetailBox";
import { DetailLayoutWrapper } from "../../../styles/layouts/main-styles";
import {
  CFlex,
  CMargin,
  DText,
  Flex,
} from "../../../styles/components/common/common-styles";
import ChatPhoto from "../../common/utils/ChatPhoto";
import Text from "../../../core/utils/Text";
import DetailContent from "../details/DetailContent";
import { useGetASim } from "../../../core/services/react-query/sim";
import { useMemo } from "react";

const ChannelDetails = () => {
  const params = useParams();
  const { data: sim } = useGetASim(params.id);

  return (
    <DetailLayoutWrapper>
      <DetailBox>
        <Flex fw justify={"space-between"} wrap>
          <Flex>
            <ChatPhoto chat={{ title: sim?.username }} />
            <DText main style={{ margin: "0 5px" }}>
              {sim?.username}
            </DText>
          </Flex>

          <CFlex width={"300px"}>
            <Flex fw justify={"space-between"}>
              <DText main fontSize={"s"}>
                <Text tid={"api_id"} /> :
              </DText>
              <DText primary margin={"0 5px"} fontSize={"s"}>
                {sim?.api_id}
              </DText>
            </Flex>
            <CMargin margin={"3px"} />
            <Flex fw justify={"space-between"}>
              <DText main fontSize={"s"}>
                <Text tid={"api_hash"} /> :
              </DText>
              <DText primary margin={"0 5px"} fontSize={"s"}>
                {sim?.api_hash}
              </DText>
            </Flex>
          </CFlex>

          <Flex>
            <DText main>
              <Text tid={"phone"} /> :
            </DText>
            <DText main style={{ margin: "0 5px" }}>
              {sim?.phone}
            </DText>
          </Flex>
        </Flex>
      </DetailBox>

      <Flex fw justify={"space-between"} wrap align={"stretch"}>
        <DetailBox width={"49%"} title={"channels"}>
          <DetailContent
            data={sim?.channels}
            photo={"address"}
            title={"title"}
            type={"channels"}
          />
        </DetailBox>
        <DetailBox
          width={"49%"}
          title={"groups"}
          extra={<GroupDetails groups={sim?.groups} />}
        >
          <DetailContent
            data={sim?.groups}
            photo={"address"}
            title={"title"}
            type={"groups"}
          />
        </DetailBox>
      </Flex>
    </DetailLayoutWrapper>
  );
};

const GroupDetails = ({ groups }) => {
  const subscribers = useMemo(() => {
    let res = 0;
    if (groups?.length) groups.forEach((g) => (res += g.subscriber));
    return res;
  }, [groups]);

  return (
    <div className={"flex justify-center items-center gap-4"}>
      <div className={"flex justify-center items-center gap-2"}>
        <DText main fontSize={"s"}>
          <Text tid={"group-count"} /> :
        </DText>
        <DText primary fontSize={"s"}>
          <span>{groups?.length || 0}</span>
        </DText>
      </div>
      <div className={"flex justify-center items-center gap-2"}>
        <DText main fontSize={"s"}>
          <Text tid={"subscriber-count"} /> :
        </DText>
        <DText primary fontSize={"s"}>
          <span>{subscribers}</span>
        </DText>
      </div>
    </div>
  );
};

export default ChannelDetails;
