import { useEffect, useRef } from "react"



export const useEnterFunction = (callback) => {

    const ref = useRef()
    const handler = (e) => {
        if (e?.keyCode === 13) {
            callback()
        }
    }

    useEffect(() => {
        ref.current = handler
    }, [handler])


    useEffect(() => {

        const isSupported = window && window.addEventListener

        if (!isSupported) return

        const eventListener = event => ref.current(event)

        window.addEventListener('keyup', eventListener)

        return () => window.removeEventListener('keyup', eventListener)
    }, [window])

}