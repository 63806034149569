import {CSWrapper, SimBackground, CSBody} from "../../../styles/components/sim/sim-styles";
import {CFlex, CMargin, DText, Flex, Padding} from "../../../styles/components/common/common-styles";
import {fadeVariants} from "../../../core/utils/theme";
import Text from "../../../core/utils/Text";
import Input from "../../common/input/Input";
import {useEffect, useState} from "react";
import {CREATE_SIM_TYPES} from "../../../core/constants/constants";
import {
    useChangeSim,
    useCreateSim,
    useSimsQuery
} from "../../../core/services/react-query/sim";
import Note from "../../common/utils/Note";
import ActionButton from "../../common/buttons/ActionButton";
import {useQueryContext} from "../../../core/contexts/query";
import ModalLayout from "../../layouts/main/ModalLayout";
import {useLocation} from "react-router-dom";
import ActivateSimModal from "../../modals/ActivateSimModal";
import ButtonLoader from "../../common/loading/ButtonLoader";
import Select from "../../common/select/Select";
import {useAclContext} from "../../../core/contexts/acl";
import {useMainContext} from "../../../core/contexts/main";


const CreateSim = () => {

    const { permissions } = useAclContext()
    const { setNeedPackage } = useMainContext()

    const id = useLocation()?.state?.id
    const editMode = !!id

    const { setToast } = useQueryContext()

    /**
     * tld code states
     */
    const [activateData, setActivateData] = useState({
        code: '', code_hash: '', sim_id: ''
    })
    const [codeModalOpen, setCodeModalOpen] = useState(false)

    const onCreateSimSuccess = (res) => {
        setCodeModalOpen(true)
        const data = res?.data?.data
        setActivateData(state => ({
            ...state,
            code_hash: data?.code_response?.phoneCodeHash,
            sim_id: data?.sim?._id
        }))
    }

    const { data: sims } = useSimsQuery()
    const { mutate: createSim, isLoading: creatingSim } = useCreateSim(onCreateSimSuccess)
    const { mutate: changeSim, isLoading: changingSim } = useChangeSim(onCreateSimSuccess)

    const NO_SIM = sims?.data?.length === 0

    const initialState = {
        username: '',
        phone: '',
        api_id: '',
        api_hash: '',
        password: '',
        type: ''
    }

    /**
     * input data states
     */
    const [inputData, setInputData] = useState(initialState)
    const [phoneValid, setPhoneValid] = useState(true)
    const [valid, setValid] = useState(false)

    const onInputValueChange = (value, type) => {
        setInputData(state => ({...state, [type]: value}))
    }


    /**
     * validate phone -- no leading zero
     */
    useEffect(() => {
        if (inputData.phone.length) {
            if (inputData.phone[0] === '0') setPhoneValid(false)
            else setPhoneValid(true)
        }
    }, [inputData])


    /**
     * validate all inputs for api call
     */
    useEffect(() => {
        const valid =
            inputData.username &&
            inputData.phone &&
            phoneValid &&
            inputData.api_id &&
            inputData.api_hash

        setValid(valid)

    }, [inputData])


    const onSubmitClicked = () => {
        if (valid) {
            if (permissions?.sim) {
                if (editMode) changeSim({data: inputData, id})
                else createSim(inputData)
            }else {
                setNeedPackage(true)
            }

        }
        else setToast({
                isError: true,
                show: true, message: 'fill-inputs-error'
            })

    }

    const simTypes = ['alpha', 'operation']

    return (
        <>
            <CSWrapper>
                <CSBody variants={fadeVariants} animate='in' exit='out' initial='out'>
                    <CFlex fw>
                        <Flex fw justify='flex-start'>
                            <DText fontSize='sb' primary>
                                <Text tid={editMode ? 'edit-sim' : 'add-sim'} />
                            </DText>
                        </Flex>
                        <CMargin margin='20px'/>

                        {
                            NO_SIM && <Note note='alpha-register-note' />
                        }

                        <Padding padding={'4px'}>
                            <Select
                                value={inputData.type}
                                onValueChange={idx => onInputValueChange(simTypes[idx], 'type')}
                                options={simTypes}
                                width={'100%'}
                                placeHolder={'select-sim-type'}
                            />
                        </Padding>
                        <CMargin margin={'4px'} />
                        <Input
                            label={CREATE_SIM_TYPES.USERNAME}
                            value={inputData.username}
                            onInputChange={(e) => onInputValueChange(e, CREATE_SIM_TYPES.USERNAME)}
                        />

                        <Input
                            label={CREATE_SIM_TYPES.PHONE}
                            value={inputData.phone}
                            onInputChange={(e) => onInputValueChange(e, CREATE_SIM_TYPES.PHONE)}
                            valid={phoneValid}
                            error='phone-error'
                            code
                        />

                        <Input
                            label={CREATE_SIM_TYPES.API_ID}
                            value={inputData.api_id}
                            onInputChange={(e) => onInputValueChange(e, CREATE_SIM_TYPES.API_ID)}
                            code
                        />

                        <Input
                            label={CREATE_SIM_TYPES.API_HASH}
                            value={inputData.api_hash}
                            onInputChange={(e) => onInputValueChange(e, CREATE_SIM_TYPES.API_HASH)}
                        />

                        <Input
                            label={CREATE_SIM_TYPES.PASSWORD}
                            value={inputData.password}
                            onInputChange={(e) => onInputValueChange(e, CREATE_SIM_TYPES.PASSWORD)}
                        />

                        <CMargin margin='10px' />
                        <ActionButton
                            onClick={onSubmitClicked}
                            active={valid}
                        >
                            <ButtonLoader
                                tid='submit'
                                loading={creatingSim || changingSim}
                            />
                        </ActionButton>
                        <CMargin margin='10px' />
                    </CFlex>
                </CSBody>
                <SimBackground />
            </CSWrapper>
            <ModalLayout
                open={codeModalOpen}
                onClose={() => {}}
                width='500px'
            >
                <ActivateSimModal
                    activateData={activateData}
                    onClose={() => setCodeModalOpen(false)}
                    editMode={editMode}
                />
            </ModalLayout>
        </>

    )
}


export default CreateSim
