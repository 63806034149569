import { CategoryChip, CheckIcon, ChipInput } from "../../../styles/components/category/category-styles";
import { useEffect, useRef, useState } from "react";
import { CloseIcon, UpdateIcon } from "../../../styles/components/common/common-styles";
import useClickOutside from "../../../core/hooks/common/useClickOutside";
import { DetailsLtr, DetailsRtl } from "../../../styles/layouts/table-styles";
import { useMainContext } from "../../../core/contexts/main";
import { useNavigate } from "react-router-dom";


const CategoryItem = ({
    category,
    onDeleteClicked,
    onUpdateClicked,
}) => {

    const { main: { lang } } = useMainContext()
    const inputRef = useRef()
    const wrapperRef = useRef()

    const [disabled, setDisabled] = useState(true)
    const [editableValue, setEditableValue] = useState('')

    useEffect(() => {
        if (!disabled) {
            inputRef.current?.focus()
        }

    }, [disabled])

    const onChipDoubleClicked = () => {
        setDisabled(false)
    }

    const onOutsideClicked = () => {
        setDisabled(true)
        setEditableValue('')
    }
    useClickOutside(wrapperRef, onOutsideClicked)

    const onValueChange = (e) => {
        const value = e.target.value
        setEditableValue(value)
    }

    const navigate = useNavigate()
    const navigateToList = () => {
        navigate(`/${category.type}s?category=${category.name}`)
    }

    return (
        <CategoryChip
            ref={wrapperRef}
        >
            <ChipInput
                placeholder={category.name}
                value={editableValue}
                onChange={onValueChange}
                disabled={disabled}
                autofocus
                id={category._id}
                ref={inputRef}
            />
            {(!disabled && editableValue) &&
                <CheckIcon size={22} onClick={() => onUpdateClicked(category._id, editableValue)} />
            }
            {disabled ?
                <UpdateIcon size={20} onClick={onChipDoubleClicked} />
                : null}
            <CloseIcon size={22} onClick={() => onDeleteClicked(category._id)} />
            <div className={'cursor-pointer'} onClick={navigateToList}>
                <DetailsRtl size={22} className={lang === 'fa' ? 'rotate-0' : 'rotate-180'} />
            </div>
        </CategoryChip>
    )
}


export default CategoryItem
