import { useState, useEffect } from "react";
import { CFlex, CustomInputBorder, DText, Flex } from "../../../styles/components/common/common-styles";
import ModalLayout from "../../layouts/main/ModalLayout";
import CategorySelectItems from "./CategorySelectItems";
import SimSelectItems from "./SimSelectModal";
import ChatSelectItems from "./ChatSelectItems";
import Text from "../../../core/utils/Text";
import Input from "../input/Input";
import RoleSelectItems from "./RoleSelectItems";
import { useTranslate } from "../../../core/utils/translator";
import { Label } from "../../../styles/components/common/select-styles";


const SingleSelect = (props) => {

    const {
        value,
        onValueChange,
        placeHolder,
        options,
        width,
        type,
        param,
        noLabel = false,
        ...rest
    } = props

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [onBoard, setOnBoard] = useState([])

    useEffect(() => {
        setOnBoard(options)
    }, [options])

    const onSearch = (v) => {
        setSearch(v)
        const newBoard = options?.filter(item => item[param]?.toLowerCase()?.includes(v))
        setOnBoard(newBoard)
    }

    const onOptionClicked = (v) => {
        if (v === value) onValueChange(null)
        else onValueChange(v)
        setOpen(false)
    }

    const ITEMS =
        type === 'category' ? CategorySelectItems : (
            type === 'sim' ? SimSelectItems : (
                type === 'role' ? RoleSelectItems
                    : ChatSelectItems
            )
        )

    return (
        <>
            <CustomInputBorder
                onClick={() => setOpen(true)}
                {...rest}
            >
                {(value && !noLabel) ?
                    <Label
                        size={'big'}
                        animate={true}
                        normal={true}
                    >
                        <Text tid={placeHolder} />
                    </Label>
                    : null}
                <Text tid={value ? value[param]?.substring(0, 10) : placeHolder} />{value?.length > 10 ? '...' : ''}
            </CustomInputBorder>
            <ModalLayout
                open={open}
                onClose={() => setOpen(false)}
                width='700px'
                portal={false}
            >
                <CFlex fw height='480px' justify={'flex-start'} transparent>
                    <DText primary fontSize='b'>
                        <Text tid={placeHolder} />
                    </DText>
                    <Flex fw>
                        <Input
                            placeholder={useTranslate('search')}
                            height={'32px'}
                            size={'small'}
                            value={search}
                            onInputChange={onSearch}
                        />
                    </Flex>
                    <ITEMS
                        options={onBoard}
                        selected={value ? [value[param]] : []}
                        onOptionClicked={onOptionClicked}
                    />
                </CFlex>
            </ModalLayout>
        </>
    )
}

export default SingleSelect
