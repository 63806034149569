import { useMainContext } from "../../../core/contexts/main";
import { Column, DateBadge, DetailsLtr, DetailsRtl, Row } from "../../../styles/layouts/table-styles";
import ChatPhoto from "../../common/utils/ChatPhoto";
import { CFlex, DeleteIcon, DText, IconWrapper, UpdateIcon } from "../../../styles/components/common/common-styles";
import { formatDate } from "../../../core/utils/common";
import ModalLayout from "../../layouts/main/ModalLayout";
import UpdateChatModal from "../chat/UpdateChatModal";
import useChatListOperations from "../../../core/hooks/components/useChatListOperations";
import DeleteChatModal from "../../modals/DeleteChatModal";
import GroupError from "../../common/utils/GroupError";
import { Link } from "react-router-dom";


const GroupList = ({
    groups,
    cs
}) => {

    const { main: { lang } } = useMainContext()

    const {
        onUpdateClicked,
        onDeleteClicked,
        updateModalOpen,
        deleteModalOpen,
        closeUpdateModal,
        selectedChat: selectedGroup,
        onUpdateSubmitted,
        closeDeleteModal,
        onDeleteSubmitted,
        chatIsUpdating
    } = useChatListOperations(
        'group'
    )

    return (
        <>
            {groups?.data?.map((group, idx) => (
                <Row key={group.createdAt} cs={cs} index={idx}>
                    <Column style={{ alignItems: 'center' }}>
                        <div>
                            {idx + 1}
                        </div>
                        {group.error &&
                            <GroupError message={group.errorMessage} />
                        }
                        <ChatPhoto
                            chat={group}
                            idx={idx}
                        />
                    </Column>
                    <Column>
                        <DText>
                            {group.title}
                        </DText>
                    </Column>
                    <Column>
                        <DText>
                            {group.address}
                        </DText>
                    </Column>
                    <Column>
                        <DText>
                            {group.subscriber}
                        </DText>
                    </Column>
                    <Column>
                        <DText color={'#1ce087'}>
                            {group.online}
                        </DText>
                    </Column>
                    <Column>
                        <DText>
                            {group.type}
                        </DText>
                    </Column>

                    <Column>
                        <DateBadge>
                            <CFlex>
                                <DText primary>
                                    {formatDate(group.updatedAt, 'date', lang)}
                                </DText>
                                <DText primary>
                                    {formatDate(group.updatedAt, 'time', lang)}
                                </DText>
                            </CFlex>
                        </DateBadge>
                    </Column>

                    <Column more>
                        <IconWrapper
                            onClick={() => onUpdateClicked(group)}
                        >
                            <UpdateIcon size={22} />
                        </IconWrapper>

                        <IconWrapper
                            onClick={() => onDeleteClicked(group)}
                        >
                            <DeleteIcon size={22} />
                        </IconWrapper>

                        <Link to={`/groups/${group._id}?type=group`}>
                            <IconWrapper>
                                {lang === 'en' ?
                                    <DetailsLtr size={24} />
                                    :
                                    <DetailsRtl size={24} />
                                }
                            </IconWrapper>
                        </Link>
                    </Column>
                </Row>
            ))}

            {/* update modal */}
            <ModalLayout
                open={updateModalOpen}
                onClose={closeUpdateModal}
                width='600px'
            >
                <UpdateChatModal
                    chat={selectedGroup}
                    type={'groups'}
                    onSubmit={onUpdateSubmitted}
                    loading={chatIsUpdating}
                />
            </ModalLayout>

            {/* delete modal */}
            <ModalLayout
                open={deleteModalOpen}
                onClose={closeDeleteModal}
                width='600px'
            >
                <DeleteChatModal
                    chat={selectedGroup}
                    onCancel={closeDeleteModal}
                    onSubmit={onDeleteSubmitted}
                    type='group'
                />
            </ModalLayout>
        </>
    )
}

export default GroupList
