import axios from "axios"
import { BASE_URL } from "../../constants/constants"

/**
 * Delete api
 * @param path {string}
 * @returns {Promise}
 */
const deleteApi = (path) => {
    return axios.delete(
        BASE_URL + path
    )
}


export {
    deleteApi
}
