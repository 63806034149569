import { CloseIcon, Label, OpenIcon, SearchInput, SelectWrapper } from "../../../styles/components/common/select-styles";
import { useEffect, useState } from "react";
import { DText } from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Dropdown from "../../modals/Dropdown";



const Select = ({
    value,
    onValueChange,
    placeHolder,
    options,
    width,
    height,
    dropdownWidth,
    direction = 'bottom',
    size = 'big',
    fontSize = 'm',
    noLabel = false,
    isCoin
}) => {

    const [filteredOptions, setFilteredOptions] = useState([])

    useEffect(() => {
        setFilteredOptions(options)
    }, [options])

    const [search, setSearch] = useState('')
    const [_value, setValue] = useState('')


    const onSearchChange = (value) => {
        if (value) {
            // TODO
        } else {
            setFilteredOptions(options)
        }

    }

    useEffect(() => {
        onSearchChange(search)
    }, [search])

    const _onValueChange = (idx) => {
        onValueChange(idx)
    }

    useEffect(() => {
        if (value) setValue(value)
        else setValue(placeHolder)
    }, [value])


    const TSelect = (props) => {

        const { open } = props

        useEffect(() => {
            if (!open) setSearch('')
        }, [open])

        return (
            <SelectWrapper
                height={height}
                size={size}
                {...props}
            >
                {!noLabel && !!value ?
                    <Label
                        size={'big'}
                        animate={true}
                        normal={true}
                    >
                        <Text tid={placeHolder} />
                    </Label>
                    : null}

                {open ?
                    <SearchInput
                        value={search}
                        onChange={(e) => setSearch(e?.target?.value)}
                        autoFocus
                    />
                    :
                    <DText main fontSize={fontSize}>
                        <Text tid={_value} />
                    </DText>
                }


                {open ?
                    <CloseIcon size={20} />
                    :
                    <OpenIcon size={20} />
                }
            </SelectWrapper>
        )
    }

    return (
        <>
            <Dropdown
                width={width}
                options={filteredOptions}
                Controller={TSelect}
                minWidth={dropdownWidth}
                bottom={direction === 'top' && '110%'}
                top={direction === 'top' && 'unset'}
                onOptionClicked={_onValueChange}
                selected={value}
                isCoin={isCoin}
            />
        </>
    )
}


export default Select
