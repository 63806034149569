import {useGetAllSims} from "../../../services/react-query/sim";
import {useEffect, useState} from "react";


const useOperationalSims = () => {

    const { data: sims } = useGetAllSims()
    const [simsList, setSimsList] = useState([])

    /**
     * filter sims ( not showing Alpha )
     */
    useEffect(() => {
        if (sims) {
            const filtered = sims?.filter(sim => sim.type !== 'alpha')
            setSimsList(filtered)
        }
    }, [sims])

    return { simsList }
}

export default useOperationalSims
