import { useGetModerator } from "../../../../core/services/react-query/c2c";
import { MAWrapper, ModeratorInput, ModeratorWrapper, PostTab } from "../../../../styles/components/c2c";
import { CFlex, DText, Flex, LineBreak } from "../../../../styles/components/common/common-styles";
import { ScaleLoader } from "react-spinners";
import ModeratorActions from "./ModeratorActions";
import { Fragment, useCallback, useEffect, useState } from "react";
import { deepCopy, formatDate } from "../../../../core/utils/common";
import { useProfileQuery } from "../../../../core/services/react-query/auth";
import { useMainContext } from "../../../../core/contexts/main";
import TelegramPost from "./TelegramPost";
import Text from "../../../../core/utils/Text";
import { useInView } from "react-intersection-observer";


const C2cModerator = () => {

    const { ref, inView } = useInView({ threshold: 0.5 });

    const [page, setPage] = useState(1)

    const { main: { lang } } = useMainContext()
    const { data: profile, refetch: getProfile } = useProfileQuery()
    const { data: posts, refetch: getModerator, isLoading: loadingModerator } = useGetModerator({
        id: profile?._id,
        page
    })

    useEffect(() => {
        const totalPages = Math.ceil(posts?.meta?.total / posts?.meta?.limit)
        if (inView && page < totalPages) {
            setPage(state => state + 1)
        }
    }, [inView])

    useEffect(() => {
        const viewPort = document.getElementById('viewport')
        viewPort.setAttribute('content', 'width=device-width, initial-scale=1')

        return () => {
            viewPort.setAttribute('content', 'width=1250, initial-scale=1')
        }
    }, [])

    useEffect(() => {
        if (profile) getModerator()
        else getProfile()
    }, [profile])

    const [onboardIds, setOnboardIds] = useState([])
    const [c2cPosts, setC2cPosts] = useState([])
    const [messages, setMessages] = useState([])
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        if (!!posts) {
            const _messages = []
            const _tabs = []

            for (let j = 0; j < posts.data?.length; j++) {

                _messages.push({ ...posts.data[j].message, _id: posts.data[j]._id })
                _tabs.push({
                    active: 0,
                    items: makeTabs(posts?.data[j]),
                    _id: posts?.data[j]?._id
                })
            }

            setMessages(state => {
                const newState = deepCopy(state)
                for (const message of _messages) {
                    if (onboardIds.includes(message._id)) continue
                    newState.push(message)
                }
                return newState
            })
            setTabs(state => {
                const newState = deepCopy(state)
                for (const tab of _tabs) {
                    if (onboardIds.includes(tab._id)) continue
                    newState.push(tab)
                }
                return newState
            })
            setC2cPosts(state => {
                const newState = deepCopy(state)
                for (const post of posts.data) {
                    if (onboardIds.includes(post._id)) continue
                    newState.push(post)
                }
                setOnboardIds(newState.map(x => x._id))
                return newState
            })
        }
    }, [posts])

    const onEditMessage = (e, postIndex) => {
        const _messages = deepCopy(messages)
        const { active, items } = tabs[postIndex]
        const activeTab = items[active]

        _messages[postIndex][activeTab] = e?.target?.value
        setMessages(_messages)
    }

    const calcHeight = (value) => {
        let numberOfLineBreaks = (value.match(/\n/g) || [])?.length;
        return 20 + numberOfLineBreaks * 20 + 12 + 2;
    }

    const handleRef = useCallback(node => {
        if (node) {
            node.style.height = calcHeight(node.value) + "px"
            node.addEventListener('keyup', () => {
                node.style.height = calcHeight(node.value) + "px"
            })
        }
    }, [])

    const makeTabs = (post) => {
        let temp = []
        if (!!post?.message?.telegram) temp.push('telegram')
        if (!!post?.message?.eitaa) temp.push('eitaa')
        return temp
    }

    const onChangeTab = (postIndex, tabIndex) => {
        const newTabs = deepCopy(tabs)
        newTabs[postIndex].active = tabIndex
        setTabs(newTabs)
    }

    const onSendMessage = (postIndex) => {

        const newC2cPosts = deepCopy(c2cPosts)
        newC2cPosts.splice(postIndex, 1)
        setC2cPosts(newC2cPosts)

        const newTabs = deepCopy(tabs)
        newTabs.splice(postIndex, 1)
        setTabs(newTabs)

        const newMessages = deepCopy(messages)
        newMessages.splice(postIndex, 1)
        setMessages(newMessages)
    }

    let mainIdx = 0

    return (
        <Flex fw fh align={'start'}>
            <div className={'relative'}>
                {loadingModerator ?
                    <div className={`absolute left-0 top-0 flex items-center z-[1000] 
                                    justify-center w-full h-full backdrop-blur-sm`}>
                        <ScaleLoader
                            color={'#3B6978'}
                            height={18}
                            width={2}
                        />
                    </div>
                    : null}
                <ModeratorWrapper>

                    <CFlex fw>
                        {
                            c2cPosts.map((item, postIndex) => {

                                const activeTab = tabs[postIndex]
                                const { active, items } = activeTab
                                const activeTabName = items[active]
                                const mgs = messages[postIndex]
                                const activeMg = mgs[activeTabName]

                                if (item.status === 'sending') return <></>

                                mainIdx += 1
                                const isLastInList = c2cPosts?.length - 1 === postIndex

                                return (
                                    <Fragment key={item._id}>
                                        <Flex justify={`flex-${lang === 'en' ? 'end' : 'start'}`} ref={isLastInList ? ref : null}>
                                            <MAWrapper style={{ marginBottom: '6px', height: 'max-content', padding: '10px' }}>
                                                <CFlex fw align={'flex-start'}>
                                                    <TelegramPost post={item} />

                                                    <Flex style={{ marginTop: '12px' }}>
                                                        {items?.map((t, idx) => (
                                                            <PostTab active={active === idx} onClick={() => onChangeTab(postIndex, idx)}>
                                                                <Text tid={t} />
                                                            </PostTab>
                                                        ))}
                                                    </Flex>

                                                    <LineBreak />
                                                    <ModeratorInput
                                                        ref={handleRef}
                                                        value={activeMg}
                                                        onChange={e => onEditMessage(e, postIndex)}
                                                    />
                                                    <Flex fw justify={'flex-end'} margin={'8px 0 0 0'}>
                                                        <DText main cFontSize={'0.7rem'}>
                                                            {formatDate(new Date(item.date * 1000), 'time', lang)}
                                                        </DText>
                                                    </Flex>
                                                </CFlex>
                                            </MAWrapper>
                                        </Flex>
                                        <ModeratorActions
                                            post={item}
                                            message={mgs}
                                            index={mainIdx}
                                            onSendMessage={() => onSendMessage(postIndex)}
                                        />
                                    </Fragment>
                                )
                            })
                        }



                    </CFlex>

                </ModeratorWrapper>
            </div>
        </Flex>

    )
}



export default C2cModerator
