import { useMainContext } from "../../../../core/contexts/main"
import Text from "../../../../core/utils/Text"
import { formatDate } from "../../../../core/utils/common"
import { CFlex, DText } from "../../../../styles/components/common/common-styles"
import { Column, DateBadge, Row } from "../../../../styles/layouts/table-styles"
import DetailContent from "../../details/DetailContent"
import DetailMiniView from "../../details/DetailMiniView"



const ExtractMembersList = ({ operations, cs }) => {

    const { main: { lang } } = useMainContext()

    return (
        <>
            {operations?.data?.map((operation, idx) => {

                return (
                    <Row cs={cs} key={operation._id} index={idx}>
                        <Column>
                            {idx + 1}
                        </Column>
                        <Column>
                            {operation.name}
                        </Column>
                        <Column>
                            <DetailMiniView
                                margin={'0'}
                                chat={{
                                    title: operation.sim_id?.username
                                }}
                                link={'sims'}
                                idx={idx}
                                _id={operation.sim_id?._id}
                            />
                        </Column>
                        <Column>
                            <DetailContent
                                data={operation?.groups}
                                photo={'address'}
                                title={'title'}
                                type={'groups'}
                                padding={'0'}
                            />
                        </Column>
                        <Column>
                            {operation.categories.map(x => x.name).join(',')}
                        </Column>
                        <Column>
                            <DText color={operation.status === 'done' ? '#1ce087' : '#e0c216'}>
                                <Text tid={operation.status} />
                            </DText>
                        </Column>
                        <Column>
                            <DateBadge>
                                <CFlex>
                                    <DText primary>
                                        {formatDate(operation.createdAt, 'date', lang)}
                                    </DText>
                                    <DText primary>
                                        {formatDate(operation.createdAt, 'time', lang)}
                                    </DText>
                                </CFlex>
                            </DateBadge>
                        </Column>
                    </Row>
                )
            })}
        </>
    )
}

export default ExtractMembersList