import Text from "../../../core/utils/Text"
import { DText } from "../../../styles/components/common/common-styles"


const Tabs = ({
    tabs = [],
    active,
    onChange
}) => {

    return (
        <div className={'mb-4'}>
            <div className={'flex items-start gap-1'}>
                {tabs.map(tab => {
                    const activeTab = active === tab
                    return (
                        <div
                            className={`flex flex-col gap-1 cursor-pointer items-center
                                    min-w-[110px] p-2 rounded-t transition
                                    ${activeTab ? 'bg-active bg-opacity-30 border-b-[3px] border-active' :
                                    'bg-gray-500 bg-opacity-10 border-b-[3px] border-gray-500 border-opacity-20'
                                }
                                `}
                            onClick={() => onChange(tab)}
                            key={tab}
                        >
                            <DText main>
                                <Text tid={tab} />
                            </DText>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Tabs