import styled, { keyframes } from "styled-components";
import { CFlex } from "../common/common-styles";
import { BsInfoCircle } from 'react-icons/bs'


export const DashboardBox = styled.div`
  background-color: ${props => props.theme.mainBg};
  border-radius: 8px;
  border: 1px solid ${props => props.theme.color}20;
  padding: 10px;
  grid-area: ${props => props.area};
`

export const Legend = styled.div`
  border-radius: 4px;
  background-color: ${props => props.color};
  width: 24px;
  height: 14px;
  margin: 0 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.1);
`

export const GlobalPieChartHeader = styled.div`
  padding: 5px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color}20;
  display: flex;
  justify-content: center;
`

export const DashboardWrapper = styled.div`
  width: 100%;
`

export const DashboardGrid = styled.div`
  display: grid;
  padding: 10px;
  grid-template-columns: calc(50% - 110px) calc(50% - 110px) 220px;
  grid-template-rows: 25% 25% 25% 25%;
  grid-row-gap: 2px;
  grid-column-gap: 5px;
  grid-template-areas: 
    "a b g1"
    "a b g2"
    "c d g3"
    "c d g4"
  ;
`

export const DOverallStatBox = styled(CFlex)`
  padding: 10px;
  width: 49%;
  height: 100%;
  max-height: calc(100vh / 3);
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
`

const Flasher = keyframes`
  0% {
    opacity: 0.5;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
`

export const SimFlasher = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${props => props.active ? props.theme.mainGreen : props.theme.mainRed};
  border-radius: 50%;
  animation: ${Flasher} 0.8s infinite;
`

export const InfoIcon = styled(BsInfoCircle)`
  color: ${props => props.theme.mainOrange};
  cursor: pointer;
`

export const ScrollBox = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const C2cDashboardGrid = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  display: grid;
  padding: 10px;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
  grid-row-gap: 2px;
  grid-column-gap: 5px;
  grid-template-areas: 
    "a b"
    "c d"
  ;
`


export const DeleteFilters = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  color: ${props => props.theme.color};
  font-size: 11px;
  cursor: pointer;
`
