import { CFlex, CMargin, DText, Flex, LineBreak, TextArea } from "../../styles/components/common/common-styles";
import Text from "../../core/utils/Text";
import { FiAlertTriangle } from "react-icons/fi";
import { useEffect, useState } from "react";
import { deepCopy } from "../../core/utils/common";
import Select from "../common/select/Select";
import WeekPicker from "../common/date/WeekPicker";
import MonthDayPicker from "../common/date/MonthDayPicker";
import ActionButton from "../common/buttons/ActionButton";
import { useQueryContext } from "../../core/contexts/query";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DateInput from "../common/input/DateInput";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/analog_time_picker";
import LocalTimePicker from '../../components/common/date/TimePicker'
import { useMainContext } from "../../core/contexts/main";
import { useTranslation } from "react-i18next";
import Input from "../common/input/Input";


const NormalizerMessageModal = ({
    editMessage,
    onAddMessage,
    onEditChanges,
    sender,
    isPost
}) => {

    const { main: { lang, theme } } = useMainContext()
    const { t } = useTranslation()
    const { setToast } = useQueryContext()
    const [valid, setValid] = useState(false)

    const messageInitial = {
        body: '',
        begin: '',
        end: '',
        type: sender ? 'static' : 'daily',
        weekDays: [],
        monthDays: [],
        static: [],
        source_address: '',
        message_id: ''
    }
    const [message, setMessage] = useState(messageInitial)

    useEffect(() => {
        if (editMessage) {
            setMessage(editMessage)
        }
    }, [editMessage])

    const messageTypes = [
        'daily', 'weekly', 'monthly'
    ]

    useEffect(() => {
        let valid =
            message.body
            && message.begin
            && message.end
            && message.type
        if (message.type === 'weekly') {
            valid = valid && message.weekDays.length
        }
        if (message.type === 'monthly') {
            valid = valid && message.monthDays.length
        }
        if (message.type === 'static') {
            valid = (message.body || (message.source_address && message.message_id)) && message.static.length
        }
        setValid(valid)
    }, [message])

    const onInputValueChange = (v, type) => {
        const newMessage = deepCopy(message)
        newMessage[type] = v
        if (type === 'static') newMessage[type] = v.map(x => (new Date(x)).toISOString())
        setMessage(newMessage)
    }

    const onSubmitChanges = () => {
        if (!valid) {
            setToast({
                isError: true, show: true,
                message: 'fill-inputs-error'
            })
        } else {
            if (editMessage) {
                onEditChanges(message)
            } else {
                onAddMessage(message)
            }
        }
    }

    return (
        <CFlex fw align={'flex-start'}>
            <Flex fw justify='space-between'>
                <DText primary fontSize='b'>
                    <Text tid={'add-message'} />
                </DText>
                <FiAlertTriangle color='#ffc800' size={32} />
            </Flex>
            <CMargin margin='4px' />
            <LineBreak />

            {isPost ?
                <>
                    <Input
                        label={'source_address'}
                        value={message.source_address}
                        onInputChange={(e) => onInputValueChange(e, 'source_address')}
                    />

                    <Input
                        label={'message_id'}
                        value={message.message_id}
                        onInputChange={(e) => onInputValueChange(e, 'message_id')}
                        code
                    />

                </>
                :
                <>
                    <CMargin margin={'4px'} />
                    <DText main>
                        <Text tid={'message'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <TextArea
                        value={message.body}
                        onChange={(e) => onInputValueChange(e?.target?.value, 'body')}
                        placeholder={`${t('message')}...`}
                    />
                </>

            }

            {!sender ?
                <>
                    <CMargin margin={'10px'} />
                    <DText main>
                        <Text tid={'message-type'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <Select
                        options={messageTypes}
                        value={message.type}
                        onValueChange={(idx) => onInputValueChange(messageTypes[idx], 'type')}
                        width={'100%'}
                        placeHolder={'select-message-type'}
                        noLabel
                    />
                    <CMargin margin={'10px'} />
                    <Flex fw justify={'space-between'}>
                        <CFlex width={'48%'} align={'flex-start'}>
                            <DText main>
                                <Text tid={'message-begin'} /> :
                            </DText>
                            <CMargin margin={'4px'} />
                            <LocalTimePicker
                                time={message.begin}
                                onChange={(v) => onInputValueChange(v, 'begin')}
                            />
                        </CFlex>
                        <CFlex width={'48%'} align={'flex-start'}>
                            <DText main>
                                <Text tid={'message-end'} /> :
                            </DText>
                            <CMargin margin={'4px'} />
                            <LocalTimePicker
                                time={message.end}
                                onChange={(v) => onInputValueChange(v, 'end')}
                            />
                        </CFlex>
                    </Flex>
                </>
                :
                <>
                    <CMargin margin={'10px'} />
                    <DatePicker
                        calendar={lang === 'fa' && persian}
                        locale={lang === 'fa' && persian_fa}
                        value={message.static}
                        onChange={(v) => onInputValueChange(v, 'static')}
                        plugins={[<TimePicker />]}
                        render={<DateInput dark={false} label={'dates'} />}
                        className={`${theme === 'dark' && 'bg-dark custom-calendar'} teal rmdp-mobile`}
                        calendarPosition={'top-center'}
                    />
                </>
            }
            <CMargin margin={'10px'} />
            {message.type === 'weekly' &&
                <CFlex fw align={'flex-start'}>
                    <DText main>
                        <Text tid={'week-days'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <WeekPicker
                        days={message.weekDays}
                        onChange={(v) => onInputValueChange(v, 'weekDays')}
                    />
                </CFlex>

            }
            {message.type === 'monthly' &&
                <CFlex fw align={'flex-start'}>
                    <DText main>
                        <Text tid={'month-days'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <MonthDayPicker
                        days={message.monthDays}
                        onChange={(v) => onInputValueChange(v, 'monthDays')}
                    />
                </CFlex>
            }
            <CMargin margin={'10px'} />
            <ActionButton
                active={valid}
                onClick={onSubmitChanges}
            >
                <Text tid={'add-message'} />
            </ActionButton>
        </CFlex>
    )

}


export default NormalizerMessageModal
