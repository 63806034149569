import {forwardRef} from "react";
import {useMainContext} from "../../../core/contexts/main";
import {ColorBox, LSBody, LSRow, LSWrapper, LSText} from "../../../styles/layouts/main-styles";
import {CFlex, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Toggle from "../../common/buttons/Toggle";
import {availablePreferences} from "../../../core/utils/theme";
import SettingCheck from "../../common/input/SettingCheck";
import {useState} from "react";


const LayoutSetting = forwardRef((props, ref) => {

    const { main: { lang, theme }, colorPref, setColorPref } = useMainContext()

    const onColorPrefChange = (pref) => {
        if (pref !== colorPref) setColorPref(pref)
    }

    return (
        <LSWrapper
            ref={ref}
            variants={getVariants(lang)}
            animate={'in'}
            initial={'out'}
            exit={'out'}
        >
            <LSBody>
                <LSRow>
                    <Flex fw justify={'space-between'}>
                        <LSText cFontSize={'14px'} primary>
                            <Text tid={'theme'} />
                        </LSText>
                        <Toggle themeSwitcher />
                    </Flex>
                </LSRow>
                <LSRow>
                    <CFlex fw align={'flex-start'}>
                        <LSText style={{ marginBottom: '4px' }} cFontSize={'12px'} main>
                            <Text tid={'color-preferences'} />
                        </LSText>
                        { availablePreferences(theme).map(item => (
                            <Flex
                                fw
                                justify={'space-between'}
                                style={{ padding: '8px 0', cursor: 'pointer' }}
                                onClick={() => onColorPrefChange(item.name)}
                            >
                                <Flex>
                                    <SettingCheck checked={colorPref === item.name}/>
                                    <LSText style={{ margin: '0 8px' }} primary cFontSize={'13px'}>
                                        <Text tid={item.name} />
                                    </LSText>
                                </Flex>
                                <Flex>
                                    <ColorBox color={item.main} />
                                    <ColorBox color={item.primary} />
                                </Flex>
                            </Flex>
                        ))}
                    </CFlex>
                </LSRow>
            </LSBody>
        </LSWrapper>
    )
})

const getVariants = (lang) => ({
    in: {
        x: 0, transition: { duration: 0.4, type: 'spring' }
    },
    out: {
        x: lang === 'fa' ? '-100%' : '100%', transition: { duration: 0.3, type: 'spring' }
    }
})

export default LayoutSetting
