import { Column, DateBadge, DetailsLtr, DetailsRtl, Row } from "../../../styles/layouts/table-styles";
import { CFlex, DeleteIcon, DText, IconWrapper } from "../../../styles/components/common/common-styles";
import DetailMiniView from "../details/DetailMiniView";
import { formatDate } from "../../../core/utils/common";
import { useMainContext } from "../../../core/contexts/main";
import Text from "../../../core/utils/Text";
import { ActionWrapper } from "../../../styles/components/sim/sim-styles";
import { useDeletePost, useRunPost, useStopPost } from "../../../core/services/react-query/post";
import { useState } from "react";
import ModalLayout from "../../layouts/main/ModalLayout";
import DeleteModal from "../../modals/DeleteModal";
import { Link, useNavigate } from "react-router-dom";
import { BooleanIcon } from "../../common/utils/BoleanIcon";
import PcViewer from "../../common/utils/PcViewer";
import UpdatePostModal from "./UpdatePostModal";


export const statusMap = {
    inactive: { desc: 'post-inactive', color: 'gray' },
    notSent: { desc: 'post-notSent', color: '#42B2F1' },
    sentAndPending: { desc: 'post-sentAndPending', color: 'orange' },
    reachedView: { desc: 'post-reachedView', color: 'green' },
    error: { desc: 'post-error', color: 'red' },
}

const PostList = ({
    posts,
    cs
}) => {

    const navigate = useNavigate()
    const { main: { lang } } = useMainContext()

    const { mutate: runPost } = useRunPost()
    const { mutate: stopPost } = useStopPost()
    const { mutate: deletePost } = useDeletePost()

    const [selectedPost, setSelectedPost] = useState(null)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const [updateModal, setUpdateModal] = useState({ con: false, post: null })

    const PostAction = (props) => {
        const { post } = props
        const active = post.status === 'notSent'

        const onActionClicked = () => {

            if (post.status === 'error') {
                setUpdateModal({ con: true, post })
                return
            }

            const payload = {
                post_id: post._id
            }
            active ? stopPost(payload) : runPost(payload)
        }

        return (
            <ActionWrapper active={active} {...props} onClick={onActionClicked}>
                <Text tid={active ? 'stop' : 'start'} />
            </ActionWrapper>
        )
    }

    const onDeleteClicked = (post) => {
        setSelectedPost(post)
        setDeleteModalOpen(true)
    }

    const onDeleteSubmitted = () => {
        setSelectedPost(null)
        setDeleteModalOpen(false)
        deletePost(selectedPost._id)
    }


    return (
        <>
            {posts?.data?.map((post, idx) => {

                const hasSim = !!post.sim_id?._id

                return (
                    <Row
                        index={idx}
                        cs={cs}
                        action={post.status === 'notSent' ? 'done' : (post.status === 'reachedView' ? 'done' : 'disabled')}
                    >
                        <Column style={{ alignItems: 'center' }}>
                            <div>
                                {idx + 1}
                            </div>
                        </Column>
                        <Column>
                            {post.message_id}
                        </Column>
                        <Column>
                            <DetailMiniView
                                margin={'0'}
                                chat={{
                                    title: post.sim_id?.username
                                }}
                                link={'sims'}
                                idx={idx}
                                _id={post.sim_id?._id}
                            />
                        </Column>
                        <Column>
                            <PcViewer filled={post.views} total={post.viewLimit} />
                        </Column>
                        <Column>
                            <PcViewer filled={post.ids.length} total={post.dates.length * post.groups.length} />
                        </Column>
                        <Column>
                            <DateBadge>
                                <CFlex>
                                    <DText primary>
                                        {formatDate(post.updatedAt, 'date', lang)}
                                    </DText>
                                    <DText primary>
                                        {formatDate(post.updatedAt, 'time', lang)}
                                    </DText>
                                </CFlex>
                            </DateBadge>
                        </Column>
                        <Column>
                            <DText color={statusMap[post.status]?.color}>
                                {post.status}
                            </DText>
                        </Column>
                        <Column more>
                            {post.done ?
                                <BooleanIcon bool />
                                :
                                (hasSim ?
                                    <PostAction post={post} />
                                    : null)
                            }
                            <IconWrapper
                                onClick={() => onDeleteClicked(post)}
                            >
                                <DeleteIcon size={22} />
                            </IconWrapper>

                            <Link to={`/post-forwarder/${post._id}`}>
                                <IconWrapper>
                                    {lang === 'en' ?
                                        <DetailsLtr size={24} />
                                        :
                                        <DetailsRtl size={24} />
                                    }
                                </IconWrapper>
                            </Link>

                        </Column>
                    </Row>
                )
            })}

            <ModalLayout
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                width={'580px'}
            >
                <DeleteModal
                    type={'post'}
                    onSubmit={onDeleteSubmitted}
                    onCancel={() => setDeleteModalOpen(false)}
                    extra={[
                        { title: 'groups', count: selectedPost?.groups.length }
                    ]}
                />
            </ModalLayout>

            <ModalLayout
                open={updateModal.con}
                onClose={() => setUpdateModal({ con: false, post: null })}
                width={'460px'}
            >
                <UpdatePostModal
                    post={updateModal.post}
                    onClose={() => setUpdateModal({ con: false, post: null })}
                />
            </ModalLayout>
        </>
    )
}

export default PostList
