import Text from "../../core/utils/Text";
import {CgArrowLeft} from "react-icons/cg";
import Smart from "../../assets/landing/section4/smart.svg"
import Forwarder from "../../assets/landing/section4/forwarder.svg"
import ChannelToGroup from "../../assets/landing/section4/channelToGroup.svg"
import SendAll from "../../assets/landing/section4/sendAll.svg"

const Section4 = () => {

    const images = [
        { src: Smart, width: 178 },
        { src: ChannelToGroup, width: 212 },
        { src: Forwarder, width: 164 },
        { src: SendAll, width: 218 },
    ]

    return (
        <div className={'w-full p-12 bg-mainBg'}>
            <div className={'grid grid-cols-4 max-w-[1144px] mx-auto gap-8'}>
                <div className={'col-span-4 flex justify-center items-center'}>
                    <Text tid={'safe-and-trust-head'} className={'text-gray-900 font-semibold text-3xl'} />
                </div>
                <div className={'col-span-4 flex justify-center items-center'}>
                    <div className={'flex rounded-md bg-primeBg w-max justify-center items-center gap-2 px-8 py-3 text-white cursor-pointer'}>
                        <Text tid={'learn-more'} />
                        <CgArrowLeft size={24} />
                    </div>
                </div>
                {Array.from(Array(4).keys()).map(item => {

                    return (
                        <div className={'flex flex-col gap-3 justify-start items-center'}>
                            <div className={'min-h-[200px]'}>
                                <img
                                    src={images[item].src}
                                    width={images[item].width}
                                    alt={item}
                                />
                            </div>
                            <Text tid={`safe-head-${item}`} className={'text-2xl mt-5 text-gray-900 font-semibold'} />
                            <Text tid={`safe-desc-${item}`} className={'text-gray-600 text-justify'} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Section4
