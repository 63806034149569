import {CFlex, CMargin, DText, Flex, LineBreak, UpdateIcon} from "../../../styles/components/common/common-styles";
import {useEffect, useState} from "react";
import {useQueryContext} from "../../../core/contexts/query";
import {deepCopy} from "../../../core/utils/common";
import ModalLayout from "../../layouts/main/ModalLayout";
import Text from "../../../core/utils/Text";
import ActionButton from "../buttons/ActionButton";
import {PickerContainer, Tag} from "../../../styles/components/normalizer";


const MonthDayPicker = ({
    days,
    onChange
}) => {

    const { setToast } = useQueryContext()
    const [editMode, setEditMode] = useState(false)
    const exitEditMode = () => setEditMode(false)
    const enterEditMode = () => setEditMode(true)

    const [allDays, setAllDays] = useState([])

    useEffect(() => {
        const temp = []
        for (let i = 1; i <= 31; i++) {
            temp.push({
                day: i, active: days?.includes(i)
            })
        }
        setAllDays(temp)
    }, [])

    const onMonthDaysStateChange = (idx) => {
        const newDays = deepCopy(allDays)
        newDays[idx].active = !newDays[idx].active
        setAllDays(newDays)
    }

    const onSubmitChanges = () => {
        let activeDays = allDays.filter(item => item.active)
        activeDays = activeDays.map(item => item.day)
        if (activeDays.length) {
            onChange(activeDays)
            exitEditMode()
        }else{
            setToast({
                isError: true, show: true,
                message: 'weekdays-error'
            })
        }
    }

    return (
        <>
            <PickerContainer>
                <Flex fw justify={'space-between'}>
                    <Flex width={'80%'} wrap justify={'flex-start'}>
                        {days?.map(day => {
                            return (
                                <Tag>
                                    <Flex fw>
                                        {day}
                                    </Flex>
                                </Tag>
                            )
                        })}
                    </Flex>
                    <UpdateIcon
                        size={18}
                        onClick={enterEditMode}
                    />
                </Flex>
            </PickerContainer>
            <ModalLayout
                open={editMode}
                onClose={exitEditMode}
                width={'400px'}
                portal={false}
            >
                <CFlex fw>
                    <Flex fw justify='space-between'>
                        <DText primary>
                            <Text tid={'select-days'} />
                        </DText>
                    </Flex>
                    <CMargin margin='4px' />
                    <LineBreak />
                    <CMargin margin={'4px'} />
                    <Flex fw justify={'flex-start'} wrap>
                        {allDays.map((day, idx) => (
                            <Tag
                                active={day.active}
                                onClick={() => onMonthDaysStateChange(idx)}
                            >
                                <Flex fw>
                                    {day.day}
                                </Flex>
                            </Tag>
                        ))}
                    </Flex>
                    <CMargin margin={'10px'} />
                    <ActionButton
                        active
                        height={'38px'}
                        onClick={onSubmitChanges}
                    >
                        <Text tid={'submit'} />
                    </ActionButton>
                </CFlex>
            </ModalLayout>
        </>
    )
}



export default MonthDayPicker
