import {forwardRef, useEffect, useRef, useState} from "react";
import {CFlex, DText} from "../../../styles/components/common/common-styles";


const AnimatedTyping = forwardRef(({ messages }, mainRef) => {

    const [typed, setTyped] = useState([])
    const [step, setStep] = useState(0)
    const refs = useRef([])

    useEffect(() => {
        if (!messages.length) return
        if (messages.length === step) {
            setStep(0)
            setTyped([])
            return
        }

        const message = messages[step]

        if (!typed[step]) typed[step] = ''
        const nextTypingText = message.slice(0, typed[step].length + 1)

        if (typed[step] === nextTypingText) {
            setStep(state => state + 1)
        }

        const timeout = setTimeout(() => {
            const newTyped = [...typed]
            newTyped[step] = message.slice(0, typed[step].length + 1)
            setTyped(newTyped)
        }, 20)

        return () => clearTimeout(timeout)

    } ,[typed, step, messages])

    useEffect(() => {
        const bounds = mainRef.current?.getBoundingClientRect()
        if (bounds.height >= 400) {
            refs.current[refs.current.length - 1]?.scrollIntoView()
        }
    }, [typed])

    return (
        <CFlex fw fh align={'flex-start'} justify={'flex-start'}>
            {typed.map((type, idx) => (
                <DText ref={el => refs.current[idx] = el} main={idx % 2 === 0} primary={idx % 2}>
                    {type}
                </DText>
            ))}
        </CFlex>
    )
})

export default AnimatedTyping
