import {
    CFlex,
    CMargin, Flex
} from "../../../../styles/components/common/common-styles";
import Wizard from "./Wizard";
import { useState, useEffect } from "react";
import useOperationalSims from "../../../../core/hooks/main/sim/useOperationalSims";
import Text from "../../../../core/utils/Text";
import { useAdminChannels, useSimChannelsQuery } from "../../../../core/services/react-query/channel";
import { C2cNext } from "../../../../styles/components/c2c";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { deepCopy } from "../../../../core/utils/common";
import Step3 from "./Step3";
import { ReactComponent as Illustration } from "../../../../assets/illustrations/c2c.svg";
import Step4 from "./Step4";
import { useCreateC2c, useGetAC2c, useUpdateC2c } from "../../../../core/services/react-query/c2c";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryContext } from "../../../../core/contexts/query";
import { routes } from "../../../../core/constants/routes";
import { useAclContext } from "../../../../core/contexts/acl";
import { useMainContext } from "../../../../core/contexts/main";


export const CREATE_C2C = {
    NAME: 'name',
    SIM_ID: 'sim_id',
    SOURCES: 'sources',
    DESTINATION: 'destination',
    IS_DIRECT: 'is-direct',
    HAS_EITAA: 'has-eitaa',
    EITAA_DESTINATION: 'eitaa-destination',
    EITAA_DEST_DELETE: 'eitaa-dest-delete',
    MODIFICATIONS: 'modifications',
    HAS_DELAY: 'has-delay',
    DELAY: 'delay',
    MIN: 'min',
    MAX: 'max',
    BEGIN: 'begin',
    END: 'end',
    EITAAYAR: 'eitaa-yar',
    USERS: 'users',
    TELEGRAM_BRIDGE: 'telegram-bridge',
    EITAA_BRIDGE: 'eitaa-bridge',
    REPEAT_FINDER: 'repeat_finder'
}

const initialInput = {
    sources: [],
    destination: null,
    eitaaDestination: '',
    repeat_finder: {
        isActive: false,
        count: 0,
        similarity: 0
    },
    isDirect: false,
    hasEitaa: false,
    telegramBridge: null,
    eitaaBridge: null,
    modifications: { telegram: [], eitaa: [] },
    hasDelay: false,
    delay: { delay: 60, begin: 2, end: 5, min: '', max: '' },
    users: [],
    eitaaYar: ''
}


const CreateC2c = () => {

    const { permissions } = useAclContext()
    const { setNeedPackage } = useMainContext()
    const navigate = useNavigate()
    const search = new URLSearchParams((useLocation()).search)
    const id = search.get('id')

    const { setToast } = useQueryContext()

    const [mode, setMode] = useState('create')
    const [step, setStep] = useState(0)
    const [inputData, setInputData] = useState(initialInput)

    const { data: c2c, refetch: getC2c } = useGetAC2c(id)
    useEffect(() => {
        if (id) getC2c()
    }, [id])

    useEffect(() => {
        if (id && c2c) {
            const newData = deepCopy(c2c)
            newData.sim = newData.sim_id
            newData.sim_id = newData.sim?.username

            if (!newData.sim) {
                newData.destination = ''
                newData.sources = []
                newData.telegramBridge = ''
            }
            setInputData(newData)
            setMode('update')
        }
    }, [c2c])


    /**
     * available sims
     */
    const [simOptions, setSimOptions] = useState([])
    const { simsList } = useOperationalSims()
    useEffect(() => {
        if (simsList) setSimOptions(simsList.map(item => item.username))
    }, [simsList])

    /**
     * sim channels
     */
    const { data: channels } = useSimChannelsQuery(inputData.sim?._id)

    /**
     * admin channels
     */
    const { data: adminChannels } = useAdminChannels(inputData.sim?._id)

    const onInputValueChange = (value, type) => {
        const newInput = deepCopy(inputData)
        switch (type) {
            case CREATE_C2C.NAME:
                setInputData(state => ({
                    ...state,
                    name: value
                }))
                break
            case CREATE_C2C.SIM_ID:
                setInputData(state => ({
                    ...state,
                    sim_id: simOptions[value],
                    sim: simsList[value]
                }))
                break
            case CREATE_C2C.SOURCES:
                setInputData(state => {
                    const oldModifications = state.modifications
                    const oldSources = state.sources
                    const newModifications = {
                        telegram: value.map(_ => []),
                        eitaa: value.map(_ => [])
                    }
                    for (let i = 0; i < value.length; i++) {
                        const newSource = value[i]
                        const isOld = oldSources.findIndex(d => d._id === newSource._id)
                        if (isOld > -1) {
                            newModifications.telegram[i] = oldModifications.telegram[isOld]
                            newModifications.eitaa[i] = oldModifications.eitaa[isOld]
                        }
                    }
                    return ({
                        ...state,
                        sources: value,
                        modifications: newModifications
                    })
                })
                break
            case CREATE_C2C.REPEAT_FINDER:
                setInputData(state => ({
                    ...state,
                    repeat_finder: {
                        ...state.repeat_finder,
                        [value.key]: value.value
                    }
                }))
                break
            case CREATE_C2C.DESTINATION:
                setInputData(state => ({
                    ...state,
                    destination: value
                }))
                break
            case CREATE_C2C.IS_DIRECT:
                setInputData(state => ({
                    ...state,
                    isDirect: !state.isDirect
                }))
                break
            case CREATE_C2C.HAS_EITAA:
                setInputData(state => ({
                    ...state,
                    hasEitaa: !state.hasEitaa
                }))
                break
            case CREATE_C2C.EITAA_DESTINATION:
                setInputData(state => ({
                    ...state,
                    eitaaDestination: value
                }))
                break
            case CREATE_C2C.MODIFICATIONS:
                newInput.modifications[value.type][value.idx] = value.kinds
                setInputData(newInput)
                break
            case CREATE_C2C.HAS_DELAY:
                newInput.hasDelay = !newInput.hasDelay
                setInputData(newInput)
                break
            case CREATE_C2C.DELAY:
                newInput.delay.delay = value
                setInputData(newInput)
                break
            case CREATE_C2C.MIN:
                newInput.delay.min = value
                setInputData(newInput)
                break
            case CREATE_C2C.MAX:
                newInput.delay.max = value
                setInputData(newInput)
                break
            case CREATE_C2C.BEGIN:
                newInput.delay.begin = value
                setInputData(newInput)
                break
            case CREATE_C2C.END:
                newInput.delay.end = value
                setInputData(newInput)
                break
            case CREATE_C2C.EITAAYAR:
                newInput.eitaaYar = value
                setInputData(newInput)
                break
            case CREATE_C2C.USERS:
                newInput.users = value
                setInputData(newInput)
                break
            case CREATE_C2C.TELEGRAM_BRIDGE:
                newInput.telegramBridge = value
                setInputData(newInput)
                break
            case CREATE_C2C.EITAA_BRIDGE:
                newInput.eitaaBridge = value
                setInputData(newInput)
                break
            default:
                break
        }
    }

    const onNext = (idx) => setStep(idx)

    const STEP_1_VALID = !!inputData.sim_id && !!inputData.sources.length
    const STEP_2_VALID = (inputData.hasEitaa && !!inputData.eitaaDestination && !!inputData.eitaaYar) || !inputData.hasEitaa
    const STEP_4_VALID = (inputData.hasDelay && !!inputData.delay.delay && !!inputData.delay.min && !!inputData.delay.max && !!inputData.delay.begin && !!inputData.delay.end) || !inputData.hasDelay

    const { mutate: createC2c, isLoading: creating, isSuccess: createSuccess } = useCreateC2c()
    const { mutate: updateC2c, isLoading: updating, isSuccess: updateSuccess } = useUpdateC2c()

    const onCreateC2c = (validation) => {
        const isValid = Object.values(validation).every(Boolean)
        if (isValid) {
            if (permissions?.c2c) {
                const payload = deepCopy(inputData)
                payload.sim_id = payload.sim._id
                payload.sources = payload.sources.map(item => item._id)
                payload.destination = payload.destination?._id || null
                delete payload.sim
                if (STEP_4_VALID) {
                    if (mode === 'create') createC2c(payload)
                    if (mode === 'update') updateC2c(payload)
                }
            } else
                setNeedPackage(true)

        } else {
            setToast({
                message: 'fill-inputs-error',
                show: true,
                isError: true
            })
        }
    }

    useEffect(() => {
        if (updateSuccess || createSuccess) {
            navigate(routes.c2c)
        }
    }, [updateSuccess, createSuccess])

    return (
        <Flex fw justify={'flex-start'}>
            <CFlex width={'50%'} align={'flex-start'} style={{ margin: '100px' }}>

                <Wizard
                    active={step}
                    title={'setup-c2c'}
                    index={0}
                    completed={step > 0}
                    onClick={() => onNext(0)}
                />
                {step === 0 &&
                    <>
                        <CMargin margin={'10px'} />
                        <Step1
                            inputData={inputData}
                            onInputValueChange={onInputValueChange}
                            channels={channels}
                            adminChannels={adminChannels}
                            simOptions={simOptions}
                        />
                        {STEP_1_VALID &&
                            <C2cNext onClick={() => onNext(1)}>
                                <Text tid={'next'} />
                            </C2cNext>
                        }
                        <CMargin margin={'10px'} />
                    </>
                }

                <Wizard
                    active={step}
                    title={'setup-eitaa'}
                    index={1}
                    completed={step > 1}
                    onClick={() => onNext(1)}
                />
                {step === 1 &&
                    <>
                        <CMargin margin={'10px'} />
                        <Step2
                            inputData={inputData}
                            onInputValueChange={onInputValueChange}
                        />
                        {STEP_2_VALID &&
                            <C2cNext onClick={() => onNext(2)}>
                                <Text tid={'next'} />
                            </C2cNext>
                        }
                        <CMargin margin={'10px'} />
                    </>
                }

                <Wizard
                    active={step}
                    title={'setup-modification'}
                    index={2}
                    completed={step > 2}
                    onClick={() => onNext(2)}
                />
                {step === 2 &&
                    <>
                        <CMargin margin={'10px'} />
                        <Step3
                            inputData={inputData}
                            onInputValueChange={onInputValueChange}
                        />
                        <C2cNext onClick={() => onNext(3)}>
                            <Text tid={'next'} />
                        </C2cNext>
                        <CMargin margin={'10px'} />
                    </>
                }

                <Wizard
                    active={step}
                    title={'final-step'}
                    index={3}
                    onClick={() => onNext(3)}
                />

                {step === 3 &&
                    <>
                        <CMargin margin={'10px'} />
                        <Step4
                            inputData={inputData}
                            onInputValueChange={onInputValueChange}
                            loading={creating || updating}
                            onCreateC2c={onCreateC2c}
                            mode={mode}
                        />
                    </>
                }

            </CFlex>
            <Flex width={'50%'} justify={'flex-start'}>
                <Illustration
                    width={'400px'}
                />
            </Flex>
        </Flex>
    )
}


export default CreateC2c
