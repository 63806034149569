import GroupComp from "../../components/main/group/GroupComp";
import CreateButton from "../../components/common/buttons/CreateButton";
import { useMemo, useState } from "react";
import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import { useGroupsQuery } from "../../core/services/react-query/group";
import { CacheKeys, chatFilterOptions } from "../../core/constants/filter";
import { useSearchParams } from "react-router-dom";


const Groups = () => {

    const [searchParams] = useSearchParams()
    const [createModal, setCreateModal] = useState(false)

    const [groups, setGroups] = useState({ data: null, loading: true })
    const onQuerySuccess = (res) => setGroups(res)

    const extra = useMemo(() => {
        const category = searchParams.get('category')
        let ex = {}
        if (category) ex.categories = category
        return ex
    }, [searchParams])


    return (
        <>
            <CardLayout>
                <FilterLayout
                    query={useGroupsQuery}
                    cache={CacheKeys.GROUP}
                    onQuerySuccess={onQuerySuccess}
                    options={chatFilterOptions}
                    extra={extra}
                >
                    <GroupComp
                        modal={{ createModal, setCreateModal }}
                        data={groups}
                    />
                </FilterLayout>
            </CardLayout>

            <CreateButton
                onClick={() => setCreateModal(true)}
                tooltip='create-group'
            />
        </>
    )
}


export default Groups
