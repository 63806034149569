import {INPUT_TYPES} from "../constants/constants";

//eslint-disable-next-line
export const validEmailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const validUsernameRegex = /^(?=.{6,100}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/


const onInputValidation = (value, type) => {

    let valid = true
    let entryType = null
    if (value.length === 0) {
        return {
            valid: null,
            entryType
        }
    }

    switch (type) {
        case INPUT_TYPES.EMAIL:
            valid = validEmailRegex.test(value)
            break
        case INPUT_TYPES.PASSWORD:
            if (value.length < 8) {
                valid = false
            }
            break
        default:
            break
    }

    return {
        valid, entryType
    }
}

export {
    onInputValidation
}
