import { CategoryWrapper } from "../../../styles/components/category/category-styles";
import {
    useCategoryQuery, useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useUpdateCategoryMutation
} from "../../../core/services/react-query/category";
import CategoryList from "./CategoryList";
import ModalLayout from "../../layouts/main/ModalLayout";
import { useState } from "react";
import CreateCategory from "./CreateCategory";


const CategoryComp = () => {

    const [addType, setAddType] = useState(null)
    const [createModal, setCreateModal] = useState(false)

    const onCreateCategorySuccess = () => {
        setCreateModal(false)
    }

    const { data: categories, isFetching } = useCategoryQuery()
    const { mutate: createCategory, isLoading: creatingCategory } = useCreateCategoryMutation(onCreateCategorySuccess)
    const { mutate: updateCategory, isLoading: updatingCategory } = useUpdateCategoryMutation()
    const { mutate: deleteCategory, isLoading: deletingCategory } = useDeleteCategoryMutation()

    const loading = isFetching || updatingCategory || deletingCategory || creatingCategory

    const addCategory = (type) => {
        setAddType(type)
        setCreateModal(true)
    }

    return (
        <CategoryWrapper>
            {
                categoryTypes.map(type => (
                    <CategoryList
                        key={type}
                        type={type}
                        list={categories ? categories[`${type}s`] : []}
                        loading={loading}
                        addCategory={addCategory}
                        updateCategory={updateCategory}
                        deleteCategory={deleteCategory}
                    />
                ))
            }
            <ModalLayout
                open={createModal}
                onClose={() => setCreateModal(false)}
                width='500px'
            >
                <CreateCategory
                    createCategory={createCategory}
                    loading={creatingCategory}
                    type={addType}
                />
            </ModalLayout>
        </CategoryWrapper>
    )
}


const categoryTypes = [
    'group', 'channel', 'member', 'normalizer'
]

export default CategoryComp
