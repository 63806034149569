import {CardWrapper} from "../../../styles/layouts/card-styles";
import {pageVariants} from "../../../core/utils/theme";
import {useMainContext} from "../../../core/contexts/main";


const CardLayout = ({children}) => {

    const { main: {lang} } = useMainContext()

    return (
        <CardWrapper initial='out' animate='in' exit='out' variants={pageVariants(lang)}>
            {children}
        </CardWrapper>
    )
}


export default CardLayout
