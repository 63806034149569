import {useSocketContent} from "../../../core/contexts/socket-content";
import {useState, useEffect, useRef} from "react";
import {DashboardBox, ScrollBox} from "../../../styles/components/dashboard";
import {Column, Row} from "../../../styles/layouts/table-styles";
import {DText, Flex} from "../../../styles/components/common/common-styles";
import Text from "../../../core/utils/Text";
import Tooltip from "../../common/utils/Tooltip";
import {formatDate} from "../../../core/utils/common";
import {CheckIcon} from "../../../styles/components/category/category-styles";
import AnimatedTyping from "../../common/utils/AnimatedTyping";
import {useMainContext} from "../../../core/contexts/main";
import {BooleanIcon} from "../../common/utils/BoleanIcon";


const C2cMessagesShow = (props) => {

    const ref = useRef()
    const { main: { lang } } = useMainContext()
    const { c2cMessages } = useSocketContent()
    const [messages, setMessages] = useState([])
    const [onFocus, setOnFocus] = useState(false)

    useEffect(() => {
        let _messages = []
        c2cMessages?.forEach(message => {
            const title =
                `${message.sim} - ${message.name} : ${message.source?.title} => t: ${message.destination?.title} | e: ${message.eitaaDestination}`
            _messages.push(title)
            _messages.push(message.message.length ? message.message[0] : '')
            _messages.push(message.updatedAt)
        })
        setMessages(_messages)
    }, [c2cMessages])


    return (
        <DashboardBox
            onMouseEnter={() => setOnFocus(true)}
            onMouseLeave={() => setOnFocus(false)}
            {...props}
        >
            <ScrollBox ref={ref}>
                {onFocus ?
                    <>
                        <Row header cs={cs}>
                            {headers.map(item => (
                                <Column>
                                    <DText main fontSize={'ss'}>
                                        <Text tid={item} />
                                    </DText>
                                </Column>
                            ))}
                        </Row>
                        { c2cMessages?.map((item, idx) => {
                            const destination = `${item.destination?.title}`
                            const eitaaDestination = `${item.eitaaDestination}`
                            const message = item.message[0]

                            return (
                                <Row cs={cs} index={idx}>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {item.sim}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {item.c2c}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {item?.source?.title}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <Tooltip
                                            content={destination}
                                        >
                                            <Flex>
                                                <DText main fontSize={'ss'}>
                                                    {destination.substring(0, 10)} ...
                                                </DText>
                                                <BooleanIcon bool={item.sendToTelegram} />
                                            </Flex>
                                        </Tooltip>
                                    </Column>
                                    <Column>
                                        <Tooltip
                                            content={eitaaDestination}
                                        >
                                            <Flex>
                                                <DText main fontSize={'ss'}>
                                                    {eitaaDestination.substring(0, 10)} ...
                                                </DText>
                                                <BooleanIcon bool={item.sendToEitaa} />
                                            </Flex>
                                        </Tooltip>
                                    </Column>
                                    <Column>
                                        <Tooltip
                                            content={message}
                                        >
                                            <DText main fontSize={'ss'}>
                                                {message.substring(0, 20)} ...
                                            </DText>
                                        </Tooltip>
                                    </Column>
                                    <Column>
                                        <DText main fontSize={'ss'}>
                                            {`${formatDate(item.updatedAt, 'date', lang)}-${formatDate(item.updatedAt, 'time', lang)}`}
                                        </DText>
                                    </Column>
                                    <Column>
                                        <BooleanIcon bool={item.isDelete} />
                                    </Column>
                                </Row>
                            )
                        })}
                    </>
                    :
                    <Flex fw fh style={{ overflow: 'hidden' }}>
                        <AnimatedTyping ref={ref} messages={messages} />
                    </Flex>
                }
            </ScrollBox>
        </DashboardBox>
    )
}


const cs = '15% 10% 10% 15% 15% 20% 10% 5%'
const headers = [
    'sim', 'c2c', 'source', 'telegram', 'eitaa', 'message', 'date'
]


export default C2cMessagesShow
