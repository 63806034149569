import html2canvas from "html2canvas"
import { useEffect, useRef, useState } from "react"
import { deepCopy } from "../../../core/utils/common"
import jsPDF from "jspdf"


export const useDownloadPdf = ({
    pdfName = '',
    listLength = 0
}) => {

    const [pdfModal, setPdfModal] = useState(false)
    const openPdfModal = () => setPdfModal(true)

    const [makingPngs, setMakingPngs] = useState(false)
    const [step, setStep] = useState(0)
    const [images, setImages] = useState([])

    // pdf part
    const pdfRef = useRef()
    const generateImages = async () => {
        const element = pdfRef.current
        // const link = document.createElement('a');
        const canvas = await html2canvas(element, { useCORS: true, scale: 2 });
        const canvasData = canvas.toDataURL('image/png');

        setImages(state => {
            const newState = deepCopy(state)
            newState.push(canvasData)
            return newState
        })
    }

    const generatePdf = () => {
        const doc = new jsPDF({ unit: 'px' })

        for (let i = 0; i < images.length; i++) {
            const image = images[i]
            const imgProps = doc.getImageProperties(image)
            const pdfWidth = doc.internal.pageSize.getWidth()
            const pdfHeight = Math.min((imgProps.height * pdfWidth) / imgProps.width, doc.internal.pageSize.height)
            doc.addImage(image, 0, 0, pdfWidth, pdfHeight, null, 'SLOW')
            if (i !== images.length - 1) doc.addPage()
        }
        doc.save(`${pdfName}.pdf`)
    }

    useEffect(() => {
        let fn = async () => {
            let total = Math.ceil(listLength / 10)
            if (makingPngs) {
                if (step >= total) {
                    generatePdf()
                    setStep(0)
                    setMakingPngs(false)
                    setPdfModal(false)
                    setImages([])
                    return
                }
                await generateImages()
                setStep(state => state += 1)
            }
        }
        fn()
    }, [makingPngs, step])

    const onDownloadClick = () => {
        if (makingPngs) return
        setMakingPngs(true)
    }

    const onCancel = () => {
        if (makingPngs) return
        setPdfModal(false)
    }

    return {
        pdfModal,
        openPdfModal,
        pdfRef,
        step,
        onDownloadClick,
        makingPngs,
        onCancel
    }
}