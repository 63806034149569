import CardLayout from "../../components/layouts/main/CardLayout";
import FilterLayout from "../../components/layouts/filter/FilterLayout";
import {CacheKeys, logFilterOptions} from "../../core/constants/filter";
import {useDeleteOutDatedLogs, useFetchLogs} from "../../core/services/react-query/log";
import {useState} from "react";
import TableLayout from "../../components/layouts/main/TableLayout";
import {Column, DateBadge, Row} from "../../styles/layouts/table-styles";
import {CFlex, DeleteIcon, DText, Flex, IconWrapper} from "../../styles/components/common/common-styles";
import {formatDate} from "../../core/utils/common";
import {useMainContext} from "../../core/contexts/main";
import Tooltip from "../../components/common/utils/Tooltip";


const Logs = () => {

    const { main: { lang } } = useMainContext()
    const [logs, setLogs] = useState({ loading: true, data: null })
    const onQuerySuccess = res => setLogs(res)

    const { mutate: deleteOutDatedLogs } = useDeleteOutDatedLogs()

    return (
        <CardLayout>
            <Flex fw justify={'flex-end'} style={{ padding: '0 20px' }}>
                <Tooltip content={'Delete out dated logs > 1 month'}>
                    <IconWrapper onClick={deleteOutDatedLogs}>
                        <DeleteIcon size={18} />
                    </IconWrapper>
                </Tooltip>
            </Flex>
            <FilterLayout
                cache={CacheKeys.LOGS}
                query={useFetchLogs}
                onQuerySuccess={onQuerySuccess}
                options={logFilterOptions}
            >
                <TableLayout
                    headers={headers}
                    cs={CS}
                    data={logs}
                >
                    {logs?.data?.data?.map((log, idx) => (
                        <Row index={idx} cs={CS}>
                            <Column>
                                {idx + 1}
                            </Column>
                            <Column>
                                {log.cause}
                            </Column>
                            <Column>
                                {log.error}
                            </Column>
                            <Column>
                                <DateBadge>
                                    <CFlex>
                                        <DText primary>
                                            {formatDate(log.createdAt, 'date', lang)}
                                        </DText>
                                        <DText primary>
                                            {formatDate(log.createdAt, 'time', lang)}
                                        </DText>
                                    </CFlex>
                                </DateBadge>
                            </Column>
                        </Row>
                    ))}
                </TableLayout>
            </FilterLayout>
        </CardLayout>
    )
}

const CS = '10% 30% 50% 10%'
const headers = [
    'cause', 'error'
]


export default Logs
