import { DashboardBox, SimFlasher, DOverallStatBox, InfoIcon } from "../../../styles/components/dashboard";
import { useSocketContent } from "../../../core/contexts/socket-content";
import { DText, Flex, HLineBreak, IconWrapper } from "../../../styles/components/common/common-styles";
import { useGetAllSims } from "../../../core/services/react-query/sim";
import Tooltip from "../../common/utils/Tooltip";
import Text from "../../../core/utils/Text";


const DashboardOverallStats = (props) => {

    const { forwardStats } = useSocketContent()
    const { data: sims } = useGetAllSims()

    return (
        <DashboardBox {...props}>
            <Flex fw fh>
                <Flex fw height={'80%'}>

                    <DOverallStatBox>
                        {sims?.map((item, idx) => (
                            <Flex fw style={{ margin: '10px 0' }} justify={'space-between'}>
                                <Flex>
                                    <SimFlasher active={!item.state.banned} />
                                    <DText primary style={{ margin: '0 12px' }}>
                                        {item.username}
                                    </DText>
                                </Flex>
                                <Tooltip
                                    content={item.state.message}
                                >
                                    <IconWrapper>
                                        <InfoIcon size={16} />
                                    </IconWrapper>
                                </Tooltip>
                            </Flex>
                        ))}
                    </DOverallStatBox>
                    <HLineBreak />
                    <DOverallStatBox>
                        {forwardStats && ['day', 'month', 'total'].map(time => (
                            <Flex fw style={{ margin: '10px 0' }} justify={'space-between'}>
                                <DText main>
                                    <Text tid={`${time}-forward-count`} /> :
                                </DText>
                                <DText primary>
                                    {forwardStats[time]}
                                </DText>
                            </Flex>
                        ))}
                    </DOverallStatBox>

                </Flex>
            </Flex>
        </DashboardBox>
    )
}

export default DashboardOverallStats
