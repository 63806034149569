import { useEffect, useState } from "react";
import {
    MdDashboard,
    MdSimCard,
    MdSpaceDashboard
} from 'react-icons/md'
import { FaTerminal, FaLayerGroup, FaUikit, FaTypo3, FaGlobe } from 'react-icons/fa'
import { GiBoomerangSun, GiTrade, GiZigArrow } from 'react-icons/gi'
import { SiGoogletagmanager } from 'react-icons/si'
import { useAclContext } from "../../contexts/acl";
import { IoMdSettings } from "react-icons/io";
import { RiSendPlane2Fill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { routes as mainRoutes } from "../../constants/routes"
import { FaUsers } from "react-icons/fa";


const useSidebar = () => {

    const { permissions, loading } = useAclContext()

    const [routes, setRoutes] = useState(null)
    const [urlDirectAccess, setUrlDirectAccess] = useState(null)

    useEffect(() => {
        if (!loading) {
            const { routes, urlAccess } = getSideRoutes(permissions)
            setRoutes(routes)
            setUrlDirectAccess(urlAccess)
        }
    }, [loading, permissions])


    return {
        urlDirectAccess, routes
    }
}



const getSideRoutes = (perms) => {

    const urlAccess = {
        '/login': true,
        '/profile': true,
        '/dashboard': perms?.panel,
        '/c2c-dashboard': perms?.c2c_panel,
        '/categories': perms?.category,
        '/sims': perms?.sim,
        '/channels': perms?.channel,
        '/groups': perms?.group,
        '/operations': perms?.operation,
        '/normalizers': perms?.normalizer,
        '/sender': perms?.sender,
        '/post-forwarder': perms?.post,
        '/c2c': perms?.c2c,
        '/moderator': perms?.moderator,
        '/manager': perms?.manager,
        '/setting': perms?.setting,
        '/logs': perms?.logs,
        '/jobs': perms?.jobs,
        '/messages': perms?.messages,
        '/members': perms?.members
    }



    const routes = {
        preview: [
            { title: 'profile', path: mainRoutes.profile, icon: CgProfile, show: true, },
            { title: 'dashboard', path: mainRoutes.dashboard, icon: MdSpaceDashboard, show: perms?.panel, },
            { title: 'c2c-dashboard', path: mainRoutes.c2cDashboard, icon: MdDashboard, show: perms?.c2c_panel, },
        ],
        structure: [
            { title: 'categories', path: mainRoutes.categories, icon: FaTerminal, show: perms?.category },
            { title: 'sims', path: mainRoutes.sims, icon: MdSimCard, show: perms?.sim },
            { title: 'channels', path: mainRoutes.channel, icon: FaLayerGroup, show: perms?.channel },
            { title: 'groups', path: mainRoutes.groups, icon: FaUikit, show: perms?.group },
            { title: 'members', path: mainRoutes.members, icon: FaUsers, show: perms?.members }
        ],
        operation: [
            { title: 'operations', path: mainRoutes.operations, icon: FaTypo3, show: perms?.operation },
            { title: 'normalizers', path: mainRoutes.normalizer, icon: FaGlobe, show: perms?.normalizer },
            { title: 'sender', path: mainRoutes.sender, icon: RiSendPlane2Fill, show: perms?.sender },
            { title: 'post-forwarder', path: mainRoutes.post, icon: GiZigArrow, show: perms?.post },
            { title: 'c2c', path: mainRoutes.c2c, icon: GiTrade, show: perms?.c2c },
            { title: 'moderator', path: mainRoutes.moderator, icon: GiBoomerangSun, show: perms?.moderator },
        ],
        management: [
            { title: 'manager', path: mainRoutes.manager, icon: SiGoogletagmanager, show: perms?.manager },
            { title: 'setting', path: mainRoutes.setting, icon: IoMdSettings, show: perms?.setting },
        ]
    }


    return {
        urlAccess,
        routes
    }
}



export default useSidebar
