import DetailBox from "../../../common/utils/DetailBox";
import {
    Background,
    CFlex,
    CMargin, Decoration,
    DText,
    Flex
} from "../../../../styles/components/common/common-styles";
import Input from "../../../common/input/Input";
import Select from "../../../common/select/Select";
import { useEffect, useState } from "react";
import useOperationalSims from "../../../../core/hooks/main/sim/useOperationalSims";
import Text from "../../../../core/utils/Text";
import Toggle from "../../../common/buttons/Toggle";
import MultiSelect from "../../../common/select/MultiSelect";
import { useSimChannelsQuery } from "../../../../core/services/react-query/channel";
import { useSimGroupsQuery } from "../../../../core/services/react-query/group";
import OperationDelay from "../OperationDelay";


const OperationDetailsEdit = ({
    sim,
    operation,
    onOperationDetailChange
}) => {

    const [simOptions, setSimOptions] = useState([])
    const { simsList } = useOperationalSims()
    useEffect(() => {
        if (simsList) setSimOptions(simsList.map(item => item.username))
    }, [simsList])

    const { data: channels, refetch: getSimChannels } = useSimChannelsQuery(sim?._id)
    const { data: groups, refetch: getSimGroups } = useSimGroupsQuery(sim?._id)

    useEffect(() => {
        if (sim) {
            getSimGroups()
            getSimChannels()
        }
    }, [sim])

    const onGroupsChange = (groups) => {
        onOperationDetailChange(groups, 'groups')
    }
    const onChannelsChange = (channels) => {
        onOperationDetailChange(channels, 'channels')
    }

    const onSubmitDelay = (delay) => {
        onOperationDetailChange(delay, 'delay')
    }

    return (
        <>
            <DetailBox>
                <Flex fw justify={'flex-end'}>
                    <DText main>
                        <Text tid={'status'} /> :
                    </DText>
                    <Toggle
                        style={{ margin: '0 5px' }}
                        active={operation?.active}
                        onChange={() => onOperationDetailChange(!operation?.active, 'active')}
                    />
                </Flex>
                <CFlex align={'flex-start'}>

                    <Flex>
                        <Decoration />
                        <DText main style={{ margin: '0 5px' }}>
                            <Text tid={'operation-sim-change-note'} />
                        </DText>
                    </Flex>
                    <CMargin margin={'6px'} />
                    <DText main>
                        <Text tid={'sim'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <Select
                        options={simOptions}
                        value={sim?.username}
                        onValueChange={(idx) => onOperationDetailChange(simsList[idx], 'sim_id')}
                        width={'480px'}
                        noLabel
                    />

                    <CMargin margin={'10px'} />
                    <Background bg={'mainBg'}>
                        <DText main>
                            <Text tid={'operation-name'} /> :
                        </DText>
                        <CMargin margin={'4px'} />
                        <Input
                            value={operation?.name}
                            onInputChange={(v) => onOperationDetailChange(v, 'name')}
                            width={'480px'}
                            padding={'0'}
                        />
                    </Background>

                    <DText main>
                        <Text tid={'channels'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <MultiSelect
                        values={operation?.channels}
                        onValuesChange={onChannelsChange}
                        placeHolder='select-channels'
                        options={channels || []}
                        width={'480px'}
                        type='chat'
                        param='address'
                        noLabel
                    />


                    <CMargin margin={'10px'} />

                    <DText main>
                        <Text tid={'groups'} /> :
                    </DText>
                    <CMargin margin={'4px'} />
                    <MultiSelect
                        values={operation?.groups}
                        onValuesChange={onGroupsChange}
                        placeHolder='select-groups'
                        options={groups || []}
                        width={'480px'}
                        type='chat'
                        param='address'
                        noLabel
                    />
                    <CMargin margin={'15px'} />
                    <CFlex width={'480px'}>
                        <OperationDelay
                            data={operation}
                            onSubmitChanges={onSubmitDelay}
                            edit
                        />
                    </CFlex>
                </CFlex>
            </DetailBox>
        </>
    )
}

export default OperationDetailsEdit
