import {useEffect, useState} from "react";
import styled, {keyframes} from "styled-components";
import {DText, Flex} from "../../../styles/components/common/common-styles";
import {BooleanIcon} from "../../common/utils/BoleanIcon";


const ChannelAdminModal = ({ channel }) => {

    const [data, setData] = useState([])

    useEffect(() => {
        if (!!channel) {
            const newData = []
            for (const sim of channel.sims) {
                const isAdmin = channel.isAdmin[sim._id]
                newData.push({ _id: sim._id, username: sim.username, isAdmin })
            }
            setData(newData)
        }
    }, [channel])

    return (
        <Wrapper>
            {data.map(item => (
                <Flex fw justify={'space-between'}>
                    <DText main>
                        {item.username}
                    </DText>
                    <BooleanIcon bool={item.isAdmin} />
                </Flex>
            ))}
        </Wrapper>
    )
}

const animation = keyframes`
  0%{
    transform: scale(0);
  }
  100%{
    transform: scale(1);
  }
`

const Wrapper = styled.div`
  width: 180px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 4px rgb(0, 0, 0, 0.05);
  position: relative;
  left: 0;
  z-index: 11;
  top: 0;
  background-color: ${props => props.theme.mainBg};
  border: 1px solid ${props => props.theme.color}20;
  animation: ${animation} 0.3s forwards;
`

export default ChannelAdminModal
