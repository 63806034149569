import ChannelList from "./ChannelList";
import {
    useRefreshChannels,
} from "../../../core/services/react-query/channel";
import TableLayout from "../../layouts/main/TableLayout";
import ChatRefresher from "../chat/ChatRefresher";
import ChannelSimRefresher from "./ChannelSimRefresher";
import { useMainContext } from "../../../core/contexts/main";


const ChannelComp = ({
    data
}) => {

    const { data: channels } = data
    const { main: { lang } } = useMainContext()

    return (
        <>
            <TableLayout
                headers={headers}
                cs={CS}
                data={data}
            >
                <ChannelList
                    channels={channels}
                    cs={CS}
                />
                <div className={`absolute top-[40px] ${lang === 'en' ? 'right-[40px]' : 'left-[40px]'} flex items-center gap-2`}>
                    <ChannelSimRefresher
                        countUpdateType={'Channels'}
                    />
                    <ChatRefresher
                        query={useRefreshChannels}
                        type={'channel'}
                        countUpdateType={'Channels'}
                        total={channels?.data?.length || 0}
                    />
                </div>

            </TableLayout>
        </>
    )
}

const CS = '10% 20% 20% 10% 10% 10% 10% 10%'
const headers = [
    'title', 'address', 'subscribers', 'type', 'modified', 'isAdmin'
]

export default ChannelComp
