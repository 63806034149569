import {useMutation, useQuery} from "react-query";
import {filterFetch} from "../../fetch-api/get";
import {postApiWithToken} from "../../fetch-api/post";
import {deleteApi} from "../../fetch-api/delete";
import {putApi} from "../../fetch-api/put";
import {useQueryContext} from "../../../contexts/query";


const CATEGORY_QUERY_KEYS = {
    GET_CATEGORIES: 'get_categories',
    GET_TYPE_CATEGORIES: 'get_type_categories',
    CREATE_CATEGORY: 'create_category',
    UPDATE_CATEGORIES: 'update_categories',
    DELETE_CATEGORIES: 'delete_categories',
}


const useCategoryQuery = () => {

    return useQuery(
        CATEGORY_QUERY_KEYS.GET_CATEGORIES, () => filterFetch({}, '/category'),
        {
            select: res => res?.data?.data
        }
    )
}

const useGetCategoryType = (type) => {

    return useQuery(
        CATEGORY_QUERY_KEYS.GET_TYPE_CATEGORIES, () => filterFetch({}, `/category/${type}`),
        {
            select: res => res?.data?.data
        }
    )
}


const useCreateCategoryMutation = (onSuccess) => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        CATEGORY_QUERY_KEYS.CREATE_CATEGORY, (data) => postApiWithToken(data, '/category'),
        {
            onSuccess: _ => {
                queryClient.invalidateQueries(CATEGORY_QUERY_KEYS.GET_CATEGORIES)
                setToast({
                    show: true,
                    message: 'op-success'
                })
                onSuccess()
            }
        }
    )

}


const useUpdateCategoryMutation = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        CATEGORY_QUERY_KEYS.UPDATE_CATEGORIES, (data) => putApi(data, '/category/update'),
        {
            onSuccess: _ => {
                queryClient.invalidateQueries(CATEGORY_QUERY_KEYS.GET_CATEGORIES)
                setToast({
                    show: true,
                    message: 'op-success'
                })
            }
        }
    )
}


const useDeleteCategoryMutation = () => {

    const { queryClient, setToast } = useQueryContext()

    return useMutation(
        CATEGORY_QUERY_KEYS.DELETE_CATEGORIES, (id) => deleteApi(`/category/${id}`),
        {
            onSuccess: _ => {
                queryClient.invalidateQueries(CATEGORY_QUERY_KEYS.GET_CATEGORIES)
                setToast({
                    show: true,
                    message: 'op-success'
                })
            }
        }
    )
}


export {
    useCategoryQuery,
    useGetCategoryType,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation
}
